import React from 'react';

export default function SideBar({ sideButtons, classes }) {
  const renderComponent = sideButtons.map((element, index) => (
    <p
      className={[classes.clickButton, classes.sideButton].join(' ')}
      key={'side_bar_buttons_' + index}>
      {element}
    </p>
  ));
  return renderComponent;
}
