import React from 'react'
import CreativeMappingFilter from './Filters'
import CreativeMappingTable from './Table'

function CreativeMapping() {
    return (
        <div>
           <CreativeMappingFilter />
           <CreativeMappingTable />
        </div>
    )
}

export default CreativeMapping
