import { apiEndpoints } from './constants';
export const getAdvertisersData = async () => {
  const data = await fetch(apiEndpoints.GET_ADVERTISER_DATA);
  return data.json();
};

export const getBusinessIOData = async (advertiserId) => {
  const data = await fetch(
    `${apiEndpoints.GET_BUSINESS_IO_DATA}/${advertiserId || -1}`
  );
  return data.json();
};

export const getDeepDiveData = async (business_io) => {
  /*
    //TODO: Handle No Business IO case
    //TODO: Hot Reload Fails in case of direct goto to a specific link
    //TODO: Keep All the formatting functions into shared file to avoid repeatability
    //TODO: Replace bio and deepdive with object strings everywhere
    //TODO: All the filters in one directory
  */
  const data = await fetch(`${apiEndpoints.GET_DEEP_DATA}/${business_io || 0}`);
  return data.json();
};
