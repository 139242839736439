import React from 'react';
import NavTab from '../../shared/ui-components/navTabs/NavTab';
import { configHarmonizeTabs } from '../../shared/frontendConstants/constants';
import classes from './HarmonizeSkeleton.module.scss';
import { useHistory } from 'react-router-dom';

import CreativeMapping from './CreativeMapping';
import StrategyMapping from './StrategyMapping';

export default function HarmonizeSkeleton() {
  const history = useHistory();
  const selector = history.location.pathname.toLowerCase();
  let Component = StrategyMapping

  if (selector.includes('strategymapping')) Component = StrategyMapping
  if (selector.includes('creativemapping')) Component = CreativeMapping

  return (
    <div className={classes.HarmonizeSkeleton}>
      <div className={classes.navBarTabs}>
        <NavTab navTabs={configHarmonizeTabs} />
      </div>
      <Component />
    </div>
  );
}
