import React from 'react';
import classes from './Config.module.scss';
import NavBars from '../shared/ui-components/SideBar/NavBars';
import { configNavBarButtons } from '../shared/frontendConstants/constants';
import ConfigSkeleton from './MetaDataSection/MetaDataSkeleton';
import DataStreamSkeleton from './DataStreamSection/DataStream';
import HarmonizeSkeleton from './Harmonize/HarmonizeSkeleton';
import {
  activeViewTab,
  updateActiveViewSubTab,
} from '../shared/slices/navBarSlice';

export default function Skeleton(props) {
  return (
    <div className={classes.ViewSkeleton}>
      <div className={classes.VerticalNavigations}>
        <div className={classes.VerticalNavBar}>
          <NavBars
            activeViewTab={activeViewTab}
            updateActiveViewSubTab={updateActiveViewSubTab}
            navBarButtons={configNavBarButtons}
          />
        </div>
      </div>

      {/* Render Metadata Page to Skeleton inner section */}
      {props.history.location.pathname.includes('metadata') && (
        <div className={classes.RestContent}>
          <ConfigSkeleton />
        </div>
      )}

      {/* Render Datastream Page to Skeleton inner section */}
      {props.history.location.pathname.includes('datastream') && (
        <div className={classes.RestContent}>
          <DataStreamSkeleton />
        </div>
      )}

      {/* Render harmonize Page to Skeleton inner section */}
      {props.history.location.pathname.includes('harmonize') && (
        <div className={classes.RestContent}>
          <HarmonizeSkeleton />
        </div>
      )}
    </div>
  );
}
