import React from 'react';
import classes from './ProgressBar.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { IconGeneric } from '../../../shared/ui-components/icons/Icons';
import { useDispatch } from 'react-redux';

export default function Stages(props) {
  const { stages, updateLastActiveHeaders, isValid } = props;
  const dispatch = useDispatch();

  return (
    <div className={classes.RowElement}>
      {stages.iconElements.map((element, index) => {
        return (
          <div key={'_stages__' + index} className={classes.IconContainer}>
            <Tooltip
              title={element.label || 'Message not configured.'}
              placement='bottom-start'>
              <span className={classes.InRowElements}>
                <IconGeneric
                  iconName={element.icon}
                  iconProps={{
                    className:
                      stages.lastActive >= index
                        ? classes.ActiveIcon
                        : classes.NonActiveIcon,
                    onClick: () => {
                      ((isValid || index === 0) &&
                        dispatch(updateLastActiveHeaders(index))) ||
                        alert(
                          'Your form is invalid. You must first fill in the valid data before procceeding to the next stage.'
                        );
                    },
                  }}
                />
                {index === stages.iconElements.length - 1 ? null : (
                  <div
                    className={
                      stages.lastActive - 1 >= index
                        ? classes.HorizontalProgressLineActive
                        : classes.HorizontalProgressLineInActive
                    }></div>
                )}
              </span>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}
