import React from 'react';
import NavTab from '../../shared/ui-components/navTabs/NavTab';
import { configMetaDataTabs } from '../../shared/frontendConstants/constants';
import classes from './MetaDataSkeleton.module.scss';
import { useHistory } from 'react-router-dom';

import Accounts from './components/Accounts/BioView';
import SubAccounts from './components/SubAccounts/SubAccounts';
import Ro from './components/RO/RO';
import Invoices from './components/Invoices/Invoices';

export default function MetaDataSkeleton() {
  const history = useHistory();
  const selector = history.location.pathname.toLowerCase();
  let Component = Accounts;

  if (selector.includes('subaccounts')) {
    Component = SubAccounts;
  } else if (selector.includes('ro')) {
    Component = Ro;
  } else if (selector.includes('invoice')) {
    Component = Invoices;
  }

  return (
    <div className={classes.MetaDataSkeleton}>
      <div className={classes.navBarTabs}>
        <NavTab navTabs={configMetaDataTabs} />
      </div>
      <Component />
    </div>
  );
}
