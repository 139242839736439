import MetaData from '../ui/schemaComponents/SchemaComponents';
const currencySymbols = {
  INR: '₹',
  USD: '$',
  EUR: '€',
};

export const columns = [
  { field: 'id', headerName: 'ID', flex: 0.25, hide: true },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.25,
    renderHeader: () => <div></div>,
    renderCell: (params) => {
      const active = params.row.status === 'Active';
      return <MetaData active={active} params={params} />;
    },
  },
  {
    field: 'name',
    headerName: 'Business IO',
    flex: 1,
    // minWidth: 100,
    showHeader: false,
  },
  {
    field: 'campaign_id',
    headerName: 'Campaign ID',
    // minWidth: 100,
    flex: 1,
  },
  {
    field: 'account',
    headerName: 'Account Name',
    // minWidth: 100,
    flex: 1,
  },
  {
    field: 'flight_dates',
    headerName: 'Flight Dates',
    // minWidth: 100,
    flex: 1,
    valueGetter: (params) =>
      `${new Date(params.row.start_date).toLocaleDateString('en-us', {
        day: 'numeric',
        month: 'short',
      })} - ${new Date(params.row.end_date).toLocaleDateString('en-us', {
        day: 'numeric',
        month: 'short',
      })}`,
  },
  {
    field: 'budget',
    headerName: 'Budget',
    // minWidth: 100,
    flex: 1,
    valueFormatter: (params) =>
      `${
        currencySymbols[params.row.budgetCurrency]
      } ${new Intl.NumberFormat().format(params.row.budget)}`,
  },
  {
    field: 'revenue_source',
    headerName: 'Revenue Source',
    // minWidth: 100,
    flex: 1,
  },
  {
    field: 'sub_io_count',
    headerName: 'Sub IOs',
    // minWidth: 100,
    flex: 1,
  },
];
