import React from 'react';
import NavTabLink from './NavLinks/NavLinks';
//import { useLocation } from 'react-router-dom';

export default function TopNavBars({ classes, buttonsArray }) {
  //const currLocation = useLocation().pathname;
  return (
    <div className={classes.NavBarLabels}>
      {Object.keys(buttonsArray).map((ele) => {
        const element = buttonsArray[ele];
        return (
          <NavTabLink
            key={element.id}
            ToLink={element.uri || '/'}
            isActive={(_, location) => {
              const currLocation = location.pathname;
              if (element.multiNavLink) {
                if (element.activeRoutes.includes(currLocation)) {
                  return true;
                }
                return false;
              } else {
                return currLocation === element.uri;
              }
            }}
            Label={element.label}
            classes={classes}
            activeClassName={classes.ActiveNavBarClass}
            ActiveStyle={{
              color: 'rgb(88, 147, 245)',
              borderBottom: '1px solid rgb(88, 147, 245)',
            }}
          />
        );
      })}
    </div>
  );
}
