import React from 'react';
import classes from './InputTextField.module.scss';

export function InputTextField(props) {
  const [dummyValue, setDummyValue] = React.useState('');

  const {
    containerClassName,
    labelClassName,
    label,
    inputClassName,
    inputValue,
    onInputChange,
    inputProps,
    reduxIdentifier,
  } = props;

  const updateInputField = React.useCallback(
    (event) => {
      if (onInputChange) {
        onInputChange(event.target.value, reduxIdentifier);
      } else setDummyValue(event.target.value);
    },
    [onInputChange, reduxIdentifier]
  );
  return (
    <div className={containerClassName ?? classes.Row}>
      <label className={labelClassName ?? classes.Row_label}>
        {label ?? 'Form Label: '}
      </label>
      <input
        {...inputProps}
        className={inputClassName ?? classes.Row_input}
        value={inputValue ?? dummyValue}
        onChange={updateInputField}
      />
    </div>
  );
}
