import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import {
//    getExistingSubAccounts,
//   getDVAdvertisers,
//   getManagerData,
// } from '../apis/api';
import { errorLogger } from '../../../../../../shared/utils/errorLogger/errorLogger';
import { deepmetrics_uri } from '../../../../../../../constants';

// Load All SubAccounts
export const loadSubAccounts = createAsyncThunk(
  'subAccounts/loadSubAccounts',
  async ( _, { getState } ) => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/sub-account/`, 
      method    : "GET",
      headers   : { "Authorization" : `Bearer ${session_token}` }
    }
    const response = await axios(request_configuration)
    return response.data
  }
)

// Delete Sub-Account
export const deleteSubAccount = createAsyncThunk(
  'subAccounts/deleteSubAccount',
  async subAccountId => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/sub-account/${subAccountId}`,
      method    : "DELETE",
      headers   : { "AUthorization" : `Bearer ${session_token}` }
    }
    const response = await axios(request_configuration);
    return response.data;
  }
)

// Load All SubAccounts under a certain AccountId
export const loadSubAccountsSpecific = createAsyncThunk(
  'subAccounts/loadSubAccountsSpecific',
  async (account_id, { getState }) => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/account/sub_account/${account_id}`, 
      method    : "GET",
      headers   : { "Authorization" : `Bearer ${session_token}` }
    }
    const subAccounts = await axios(request_configuration)
    const allSubAccounts = subAccounts.data

    return allSubAccounts;
  }
);  

// Create New SubAccont
export const saveSubAccount = createAsyncThunk(
  'subAccounts/saveSubAccount',
  async (_, { getState }) => {
    const subAccountState = getState().subAccountsSlice;
    const session_token = localStorage.getItem('SESSION_TOKEN')
    console.log(subAccountState)
    const request_configuration = {
      url       : `${deepmetrics_uri}/sub-account/`,
      method    : "POST",
      headers   : { "Authorization" : `Bearer ${session_token}` },
      data      : {
        sub_account_name: subAccountState.subAccountDetails.sub_account_name,
        sub_account_status: subAccountState.subAccountDetails.sub_account_status,
        sub_account_manager: subAccountState.subAccountDetails.sub_account_manager,
        dv3_advertisers: subAccountState.subAccountDetails.dv3_advertisers.join(", "),
        dashboard_name: subAccountState.subAccountDetails.dashboards.length > 0 ? subAccountState.subAccountDetails.dashboards[0].name : "",
        dashboard_link: subAccountState.subAccountDetails.dashboards.length > 0 ? subAccountState.subAccountDetails.dashboards[0].link : "",
        dashboard_type: subAccountState.subAccountDetails.dashboards.length > 0 ? subAccountState.subAccountDetails.dashboards[0].type : "",
        account_id: subAccountState.subAccountDetails.account_id,
      },
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Edit existing SubAccount
export const editSubAccount = createAsyncThunk(
  'subAccounts/editSubAccount',
  async (subAccountId, { getState }) => {
    const subAccountState = getState().subAccountsSlice;
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/sub-account/${subAccountId}`,
      method    : "PUT",
      headers   : { "Authorization" : `Bearer ${session_token}` },
      data      : {
        sub_account_name: subAccountState.subAccountDetails.sub_account_name,
        sub_account_status: subAccountState.subAccountDetails.sub_account_status,
        sub_account_manager: subAccountState.subAccountDetails.sub_account_manager,
        dv3_advertisers: subAccountState.subAccountDetails.dv3_advertisers.join(", "),
        dashboard_name: subAccountState.subAccountDetails.dashboards.length > 0 ? subAccountState.subAccountDetails.dashboards[0].name : "",
        dashboard_link: subAccountState.subAccountDetails.dashboards.length > 0 ? subAccountState.subAccountDetails.dashboards[0].link : "",
        dashboard_type: subAccountState.subAccountDetails.dashboards.length > 0 ? subAccountState.subAccountDetails.dashboards[0].type : "",
        account_id: subAccountState.subAccountDetails.account_id,
      }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

export const getSubAccount = createAsyncThunk(
  'subAccounts/getSubAccount',
  async subAccountId => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/sub-account/${subAccountId}`,
      method    : "GET",
      headers   : { "Authorization" : `Bearer ${session_token}` },
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

const initialState = {
  subAccountId: '',
  subAccountDetails: {
    sub_account_name: '',
    sub_account_status: "Active",
    sub_account_manager: '',
    dv3_advertisers: [],
    dashboards: [],
    account_id: ''
  },
  existingSubAccounts: [],
  paused: false,
  createNew: false,
};

export const subAccountsSlice = createSlice({
  name: 'metadata_subaccounts',
  initialState,
  reducers: {
    setSubAccountId:      (state, action) => { state.subAccountId = action.payload },
    setExisting:          (state, action) => { state.existingSubAccounts = action.payload },
    resetExisting:        (state) => { state.existingSubAccounts = [] },
    setCreateNew:         (state) => { state.createNew = true },
    unSetCreateNew:       (state) => { state.createNew = false },
    resetAccountDetails:  (state) => { state.subAccountDetails = initialState.subAccountDetails },

    setSubAccountName:    (state, action) => { state.subAccountDetails.sub_account_name = action.payload },
    setSubAccountStatus:  (state, action) => { state.subAccountDetails.sub_account_status = action.payload },
    setSubAccountManager: (state, action) => { state.subAccountDetails.sub_account_manager = action.payload },
    setAccountId:         (state, action) => { state.subAccountDetails.account_id = action.payload },

    setDVAdvertisers:     (state, action) => { state.subAccountDetails.dv3_advertisers = action.payload },
    addDVAdvertisers:     (state, action) => { state.subAccountDetails.dv3_advertisers = [ ...state.subAccountDetails.dv3_advertisers, action.payload ] },
    deleteDVAdvertisers:  (state, action) => { state.subAccountDetails.dv3_advertisers = [ ...state.subAccountDetails.dv3_advertisers ].filter(advertiser => advertiser !== action.payload) },

    setDashboards:        (state, action) => { state.subAccountDetails.dashboards = action.payload },
    addDashboards:        (state, action) => { state.subAccountDetails.dashboards = [ ...state.subAccountDetails.dashboards, action.payload ] },
    deleteDashboard:      (state, action) => { state.subAccountDetails.dashboards = [ ...state.subAccountDetails.dashboards ].filter(dashboard => dashboard.id !== action.payload) }

  },
  extraReducers: (builder) => {

    // Loading All SubAccounts
    builder
      .addCase(loadSubAccounts.pending, (state) => { state.paused = true })
      .addCase(loadSubAccounts.fulfilled, (state, action) => {
        state.paused = false;
        state.existingSubAccounts = action.payload
      })
      .addCase(loadSubAccounts.rejected, (state, action) => {
        state.paused = false;
        errorLogger(action, 'Error Occured in loadSubAccounts:');
      });

    // Loading Specific SubAccounts
    builder
      .addCase(loadSubAccountsSpecific.pending, (state) => { state.paused = true })
      .addCase(loadSubAccountsSpecific.fulfilled, (state, action) => {
        state.paused = false;
        state.existingSubAccounts = action.payload
      })
      .addCase(loadSubAccountsSpecific.rejected, (state, action) => {
        state.paused = false;
        errorLogger(action, 'Error Occured in loadSubAccountsSpecific:');
      });
    
    // Create New SubAccount
    builder 
      .addCase(saveSubAccount.pending, (state) => { state.paused = true })
      .addCase(saveSubAccount.fulfilled, (state, action) => {
        state.paused = false;
        alert(`SubAccount Saved Successfully !!!`)
      })
      .addCase(saveSubAccount.rejected, (state, action) => {
        state.paused = false;
        errorLogger(action, 'Error Occured in saveSubAccount:');
      });

    // Edit Existing SubAccount
    builder
      .addCase(editSubAccount.pending, (state) => { state.paused = true })
      .addCase(editSubAccount.fulfilled, (state, action) => {
        alert(`SubAccount Edited Successfully !!!`)
        state.paused = false;
      })
      .addCase(editSubAccount.rejected, (state, action) => {
        state.paused = false;
        errorLogger(action, 'Error Occured in editSubAccount:');
      });

    // Delete Sub-Account
    builder
      .addCase(deleteSubAccount.pending, (state) => { state.paused = true })
      .addCase(deleteSubAccount.fulfilled, (state, action) => {
        state.paused = false;
        alert("Sub-Account Deleted Successfully")
      })
      .addCase(deleteSubAccount.rejected, (state, action) => {
        state.paused = false;
        errorLogger(action, "Error Observed while Deleting Account")
      })

    // Get Sub Account 
    builder
      .addCase(getSubAccount.pending, (state) => { state.paused = true })
      .addCase(getSubAccount.fulfilled, (state, action) => {
        const data = action.payload;
        state.subAccountDetails.sub_account_name = data.sub_account_name;
        state.subAccountDetails.sub_account_manager = data.sub_account_manager;
        state.subAccountDetails.sub_account_status = data.sub_account_status;
        state.subAccountDetails.dv3_advertisers = data.dv3_advertisers.split(",").map(advertiser => +advertiser.trim());
        state.subAccountDetails.account_id = data.account_id;
        state.subAccountId = data.id;
        state.subAccountDetails.dashboards = [{
          id: Math.floor(Math.random()*(999-100+1)+100),
          name: data.dashboard_name,
          link: data.dashboard_link,
          type: data.dashboard_type
        }]
        state.paused = false;
      })
      .addCase(getSubAccount.rejected, (state, action) => {
        state.paused = false;
        errorLogger(action, 'Error Occured in getSubAccount:');
      })

  },
});

export const { 
  setSubAccountId,
  setExisting,
  resetExisting,
  setCreateNew,
  unSetCreateNew,
  resetAccountDetails,
  setSubAccountName,
  setSubAccountStatus,
  setSubAccountManager,
  setAccountId,
  setDVAdvertisers,
  addDVAdvertisers,
  deleteDVAdvertisers,
  setDashboards,
  addDashboards,
  deleteDashboard
} = subAccountsSlice.actions;

export const subAccounts = (state) => state.subAccountsSlice;
export default subAccountsSlice.reducer;
