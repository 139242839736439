import { nanoid } from 'nanoid';
import { componentTypes } from '../../shared/ui-components/rowGenerator/supportedComponents';
//TODO: to be substituted by reducer function

export const advertiserWidgetConfig = {
  id: nanoid(),
  rowElements: {
    advertisers: {
      id: nanoid(),
      componentType: componentTypes.SELECT_DROPDOWN,
      props: {
        directProps: {
          placeholder: 'Advertiser',
          options: [
            { value: -1, label: 'Not Configured' },
            { value: 550043497, label: 'Oyo' },
          ],
          value: null,
        },
      },
    },
    accounts: {
      id: nanoid(),
      componentType: componentTypes.SELECT_DROPDOWN,
      props: {
        directProps: {
          placeholder: 'Account',
          options: [
            { value: -1, label: 'Not Configured' },
            { value: 550043497, label: 'Oyo' },
            { value: 554721541, label: 'Groww' },
            { value: 583285890, label: 'Testbook' },
            { value: 670934361, label: 'Classplus' },
            { value: 4192732, label: 'Dell' },
            { value: 699739193, label: 'Halodoc' },
          ],
          value: null,
        },
      },
    },
    status: {
      id: nanoid(),
      componentType: componentTypes.SWITCH_COMPONENT,
      left: { label: 'All', value: 'All' },
      right: { label: 'Active', value: 'Active' },
      value: true, //isChecked
      isDisabled: false,
    },

    dates: {
      id: nanoid(),
      componentType: componentTypes.DATE_RANGE,
      dates: {
        key: 'selection',
        startDate: new Date(`${new Date().getFullYear()}-01-01`),
        endDate: new Date(`${new Date().getFullYear()}-12-31`),
      },
    },
  },
};
