export const existingSettings = {
  datastream_config: {
    existingNetworks: [],
    network_group: '',
    datastream_email: '',
    client_source: 'GoogleSheets',
    client_target: 'BigQuery',
    workbook_sheet_name: '',
    write_disposition: 'WRITE_TRUNCATE',
    merge_mode: 'override',
    sheet_id: '',
    sheet_name: '',
    file_id: '',
    attachment_sheet_name: '',
    label: '',
    search_string: '',
    is_attachment: false,
    link_sheet_name: '',
    link: '',
    query: '',
    app_id: '',
    access_token: '',
    branch_key: '',
    branch_secret: '',
    bucket: '',
    gcs_file_id: '',
    gcs_file_sheet_name: '',
    datastream_is_enabled: true,
    schedule: '0 8 * * *',
    delete_condition: '',
    datastream_attribute_1: '',
  },
};
