import React from 'react';
import { NavLink } from 'react-router-dom';

const NavTab = (props) => {
  return (
    <NavLink
      to={props.ToLink}
      isActive={props.isActive}
      className={props.classes.NavBarElements}
      activeStyle={props.ActiveStyle || {}}
      activeClassName={props.ActiveNavBarClass}>
      {props.Label}
    </NavLink>
  );
};

export default NavTab;
