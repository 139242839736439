import React from 'react';
import classes from './SubRo.module.scss';
import { SlideDown } from 'react-slidedown';
import SubRoRepresentation from '../SubRoRepresentation/SubRoRepresentation';
import Icon, {
  icons,
} from '../../../../../../shared/ui-components/icons/Icons';

export default function SubRo(props) {
  const {
    isOpen,
    setOpen,
    displayDetails,
    formSpecificEntries,
    subRoUpdateRequestHandler,
    deleteAllowed,
    deleteSubRO,
    draftSubROHandler,
  } = props;
  
  const iconType = isOpen ? icons.CROSS_ICON : icons.DOWN_ICON;

  const deleteSubROClickHandler = subROId => {
    
    // Dialog Box
    const message = 'Are you sure you want to Delete this Sub-RO ?'
    if (!window.confirm(message)) return;
    
    deleteSubRO();
  }

  return (
    <SlideDown
      className={[
        'my-dropdown-slidedown',
        classes.SlideDownContainer_SubRo,
      ].join(' ')}>
      
      <React.Fragment>
        <div className={classes.SlideDownController}>
          <div 
            className={classes.DescriptionContainer}
            onClick={() => { setOpen({ value: !isOpen, key: 'showDetails' }) }}
          >
            {Object.keys(displayDetails).map((ele) => (
              <label key={ele} className={classes.SlideDownController_label}>
                {`${ele}: ${displayDetails[ele]}`}
              </label>
            ))}
          </div>
          <div
            className={classes.SlideDownController_IconWrapper}
            onClick={event => { deleteSubROClickHandler() }}>
              {Icon(icons.DELETE_ICON)}
          </div>
          <div
            className={classes.SlideDownController_IconWrapper}
            onClick={() => { setOpen({ value: !isOpen, key: 'showDetails' }) }}>
            {Icon(iconType)}
          </div>
        </div>
        
        {isOpen && 
          <SubRoRepresentation
            formSpecificEntries={formSpecificEntries}
            subRoUpdateRequestHandler={subRoUpdateRequestHandler}
            deleteAllowed={deleteAllowed}
            deleteSubRO={deleteSubRO}
            draftSubROHandler={(value) => draftSubROHandler(value)}
          />
        }
      </React.Fragment>
    
    </SlideDown>
  );
}
