import { base_uri } from '../../../shared/backend/apiEndpoints';

export const endpoints = {
  GET_ACTIVE_BIO: `${base_uri}/config_bios`,
  ADD_BIO: `${base_uri}/config_add_bios`,
  GET_ACCOUNTS: `${base_uri}/get_existing_configs`,
  GET_JSON_STREAMS: `${base_uri}/get_list`,
  GET_CONFIG_MASTER_LIST: `${base_uri}/cdp_dimensions`,
  GET_NETWORKS: `${base_uri}/existing_networks`,

  //BQ Endpoints
  GET_BQ_DATASTREAMS: `${base_uri}/get_bq_dstreams`,
  CREATE_CDP_DATASTREAM: `${base_uri}/create_cdp_datastream`,
  GET_DATASTREAM_HEADERS: `${base_uri}/get_datastream_headers`,
  CLOUD_FUNCTION_ENDPOINT: `${base_uri}/cloud_handle`,
  FORMULA_VERIFIER_ENDPOINT: `${base_uri}/formulae_operations`,
};

export const backendKeys = {
  accounts: 'bioAccounts',
  ros: 'ros',
};
