import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div>
      The Page you're looking for isn't available. You might want to go back to
      <Link to='/'> Login</Link> page.
    </div>
  );
}
