import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dataStreams, updateDataTab } from '../../slices/dataStream.js';
import classes from '../../Config.module.scss';
export default function MetaDataConfig() {
  const dispatch = useDispatch();
  const data = useSelector(dataStreams);
  const dataStream = data.headers;
  const metaData = data.metadataDetails;
  const availableTabs = data.availableHeaders || [];
  if (dataStream.length === 0) return <p>You need to first load Data :(</p>;
  const selectedDataStream =
    metaData.name === -1
      ? dataStream[0].data
      : dataStream.filter((element) => element.name === metaData.name)[0].data;
  const tableHeaders = Object.keys(selectedDataStream[0]);
  return (
    <div className={classes.MetaDataConfigDB}>
      <div className={classes.TabSelector}>
        <p className={classes.ParaDisplay}>Tab: </p>
        <select
          className={classes.TabSelectorDropDown}
          onChange={(event) => {
            dispatch(updateDataTab(event.target.value));
          }}>
          {availableTabs.map((element, index) => {
            return (
              <option
                className={classes.TabSelectorOptions}
                key={index}
                value={element}>
                {element}
              </option>
            );
          })}
        </select>
      </div>
      <div className={classes.MetaDataTable}>
        <table className={classes.ScrollBar}>
          <thead>
            <tr>
              {tableHeaders.map((element, index) => (
                <th key={index}>{element}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {selectedDataStream.map((element, index) => (
              <tr key={index}>
                {tableHeaders.map((ele, id) => (
                  <td key={id}>{element[ele]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
