import React from 'react'
import classes from './StrategyMapping.module.scss' 
import { connect } from 'react-redux'

import EditingTable from '../Components/EditingTable'
import FilterElement from '../Components/FilterElement'
import CreateFilter from '../Components/CreateFilter'

import { OPERATORS, STRATEGY_MAPPING_COLUMNS } from "./../mappingConstants"  
import { addDataFitler, deleteDataFilter, makePagedDataRequest, navigateNextPage, navigatePrevPage } from '../StrategyMappingReducer/Actions'

function StrategyMapping({
    data,
    setData,
    filters,
    addFilter,
    deleteFilter,
    pageNumber,
    navigateNextPage,
    navigatePrevPage,
    isLoading,
}) {

    React.useEffect(() => {
        setData(filters, pageNumber)
    }, [ setData, filters, pageNumber ])

    const getRowID = row => {
        const dateString = new Date(row.Date).toISOString().slice(10);
        const lineItemID = row.Line_Item_ID;
        return `${dateString}_${lineItemID}`
    }

    return (
        <div>
            <div className={ classes.FilterBar }>
                <CreateFilter 
                    nameOptions = {STRATEGY_MAPPING_COLUMNS}
                    operatorOptions = {OPERATORS}
                    setFilter = {addFilter}
                />
                {
                    filters.map(filter => <FilterElement filter={filter} deleteFilter={deleteFilter}/>)
                }
            </div>
            <div>
                <EditingTable 
                    headers = {data[0] ? Object.keys(data[0]) : []}
                    data = {data}
                    pageNumber = {pageNumber}
                    navigateNextPage = {navigateNextPage}
                    navigatePrevPage = {navigatePrevPage}
                    loading = {isLoading}
                    getRowID = {getRowID}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    data: state.strategyMapping.data,
    filters: state.strategyMapping.filters,
    pageNumber: state.strategyMapping.pageNumber,
    isLoading: state.strategyMapping.loading,
})
const mapDispatchToProps = dispatch => ({
    setData: (filters, pageNumber) => { dispatch(makePagedDataRequest(filters, pageNumber)) },
    addFilter: filter => { dispatch(addDataFitler(filter)) },
    deleteFilter: id => { dispatch(deleteDataFilter(id)) },
    navigateNextPage: () => { dispatch(navigateNextPage()) },
    navigatePrevPage: () => { dispatch(navigatePrevPage()) },
})

export default connect(mapStateToProps, mapDispatchToProps)(StrategyMapping)
