import { updateDataStreamStatus } from '../dataStream';
import { compoundGetDatastreamConfigAndHeaders } from '../editExistingSlice';

export const dataStreamActions = {
  DISABLE_DATA_STREAM: 'DISABLE_DATA_STREAM',
  EDIT_DATA_STREAM: 'EDIT_DATA_STREAM',
  LOG_DATA_STREAM: 'LOG_DATA_STREAM',
  ENABLE_DATA_STREAM: 'ENABLE_DATA_STREAM',
};

export const dataStreamOps = {
  DISABLE_DATA_STREAM: updateDataStreamStatus,
  ENABLE_DATA_STREAM: updateDataStreamStatus,
  EDIT_DATA_STREAM: compoundGetDatastreamConfigAndHeaders,
};
