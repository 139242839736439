import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { existingSettings } from './constants/initStates.js';
import { getMasterList } from './apis/api';
import { createdStream, datastreamHeaders } from './apis/bqApis';
import { verificationObjects } from './constants/verifications';
import { verifierFunction } from './compoundFunctions/formVerifier';
import { icons } from '../../shared/ui-components/icons/Icons';
// import { current } from '@reduxjs/toolkit';

const stages = {
  lastActive: 0,
  iconElements: [
    { label: 'Pre Config', icon: icons.FORM_INPUT },
    { label: 'Configure', icon: icons.SETTINGS_ICON },
  ],
};

const initialState = {
  status: 'idle',
  masterList: [],
  headersList: [],
  stages,
  inEditMode: false,
  datastream_id: '',
  originalMapping: {},
  mappingConfiguration: {
    activeMasterHeader: null, //Active UI Selection
    mappings: {}, //{masterListHeader: {formula:'formula',alias: 'alias'}}
  },
  datastreamConfigSettings: existingSettings.datastream_config,
  modalErrors: {
    showModal: false,
    msg: '',
  },
  errors: { touched: true, errors: {}, isFormValid: true },
  created_email: '',
};

export const getMasterListofDimensions = createAsyncThunk(
  'editExisting/getMasterListofDimensions',
  async () => {
    const response = await getMasterList();
    return response;
  }
);

export const compoundGetDatastreamConfigAndHeaders = createAsyncThunk(
  'editExisting/getDatastreamConfigAndHeaders',
  async (external_payload, {getState, dispatch}) => {
    const datastream_id = getState().dstream.datastream_selections[0];
    dispatch(getExisting(external_payload))
    dispatch(getDatastreamHeaders(datastream_id))
  }
)

export const getDatastreamHeaders = createAsyncThunk(
  'editExisting/getDatastreamHeaders',
  async (datastream_id, { getState, dispatch }) => {
    const selected_datastream = datastream_id ?? getState().dstream.datastream_selections[0];
    const payload = { "datastream_id" : selected_datastream }
    const response = await datastreamHeaders(payload)
    return response.payload;
  }
)

export const getExisting = createAsyncThunk(
  'editExisting/getExisting',
  async (external_payload, { getState, dispatch }) => {
    dispatch(updateInEditMode(true));
    const selected_dstream_id = external_payload.datastream_id ?? getState().dstream.datastream_selections[0];
    const payload = {
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
      operationType: 'update_mapping',
      operationProps: {
        datastream_id: selected_dstream_id,
        methodType: 'GET',
      },
    };
    const response = await createdStream(payload);
    if (response.error) {
      if (external_payload.datastream_id === undefined)
        dispatch(updateInEditMode(false));
      console.log(`%cError happened: `, 'font-weight:bold;color:red;');
      console.log(response);
    }
    return response;
  }
);

export const updateExisting = createAsyncThunk(
  'editExisting/updateExisting',
  async (_, { getState, dispatch }) => {
    const datastream_id = getState().existingDStreams.datastream_id;
    const formulas = getState().existingDStreams.mappingConfiguration.mappings;
    const configurations = getState().existingDStreams.datastreamConfigSettings;
    const payload = { datastream_id, formulas, configurations };
    const response = await createdStream({
      operationProps: payload,
      operationType: 'update_mapping',
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
    });
    dispatch(updateInEditMode(false));
    dispatch(resetInitState());
    return response;
  }
);

export const updateExistingDataStream = createSlice({
  name: 'existingDataStream',
  initialState,
  reducers: {
    // Reducer to update User-Mapping
    updateUserMapping: (state, action) => {
      state.mappingConfiguration.mappings[action.payload.masterListHeader] = {
        alias: action.payload.masterListHeader,
        formula: action.payload.formulaString,
      };
      state.mappingConfiguration.activeMasterHeader =
        action.payload.masterListHeader;
    },

    // Reducer to reset mapping configuration
    resetMappingConfiguration: (state, action) => {
      if (action.payload) {
        state.mappingConfiguration.mappings = action.payload;
      } else state.mappingConfiguration.mappings = {};
    },

    // Reducer to toggle/set inEditMode
    updateInEditMode: (state, action) => {
      state.inEditMode = action.payload;
    },

    // Reset State to initial state
    resetInitState: () => initialState,

    // Update MasterList 
    updateMasterListMapping: (state, action) => {
      const keyToLookFor = 'UNDEFINED';
      const isUndefinedPresent = Object.keys(
        state.mappingConfiguration.mappings
      ).includes(keyToLookFor);

      if (isUndefinedPresent) {
        const newData = state.mappingConfiguration.mappings;
        const newObject = newData[keyToLookFor];
        delete newData[keyToLookFor];
        newData[action.payload.masterKey] = newObject;
        newData[action.payload.masterKey].alias = action.payload.masterKey;
      } else {
        const data = state.mappingConfiguration.mappings;
        //delete data[action.payload.masterKey];
        state.mappingConfiguration.mappings = {
          [action.payload.masterKey]: {
            formula: 'UNDEFINED',
            alias: 'UNDEFINED',
          },
          ...data,
        };
      }
      state.mappingConfiguration.activeMasterHeader = action.payload.masterKey;
    },

    // Set mapping to original datastream mapping
    preserveOriginalMapping: (state, action) => {
      state.originalMapping = action.payload;
    },

    // Set the stage in which the user finds himself
    updateLastActiveHeaders: (state, action) => {
      state.stages.lastActive = action.payload;
    },

    // update active selector
    updateActiveSelector: (state, action) => {
      state.mappingConfiguration.activeMasterHeader = action.payload;
    },

    // update modal error
    updateModalError: (state, action) => {
      state.modalErrors.showModal = action.payload.booleanValue;
      state.modalErrors.msg =
        action.payload.msg || 'Unexpected Error Occured. :( [Reset]';
    },

    // update/add formula
    updateFormula: (state, action) => {
      state.mappingConfiguration.mappings[action.payload.header].formula =
        action.payload.value;
    },

    // update form data when editing datastream
    updateFormData: (state, action) => {
      state.datastreamConfigSettings[action.payload.key] = action.payload.value;
      state.errors.touched = true;
      state.errors.errors = verifierFunction(
        verificationObjects.connectMix,
        state.datastreamConfigSettings
      );

      state.errors.isFormValid = Object.values(state.errors.errors).reduce(
        (acc, curr) => {
          acc = acc && !curr.error;
          return acc;
        },
        true
      );
    },

    // update alias name for formula
    updateAlias: (state, action) => {
      state.mappingConfiguration.mappings[action.payload.header].alias =
        action.payload.value;
    },

    // Resset state to initial state
    resetExistingSliceState: () => initialState,

    // Remove mapping formula from mappings
    removeSpecificKey: (state, action) => {
      const data = state.mappingConfiguration.mappings;
      delete data[action.payload];
      state.mappingConfiguration.mappings = data;
      const keys = Object.keys(data);
      state.mappingConfiguration.activeMasterHeader =
        keys.length === 0 ? null : keys[0];
    },
  
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(getExisting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getExisting.fulfilled, (state, action) => {
        if (action.payload.error) {
          alert(
            `Operation Failed: ${action.payload.msg}, Description: ${action.payload.payload.description}`
          );
          state.status = 'idle';
        } else {
          if (!action.meta.arg?.const_payload?.updateMappingOnly) {
            state.datastream_id = action.payload.payload.datastream_id;
            state.datastreamConfigSettings = action.payload.payload.datastream_config;
            state.created_email = action.payload.payload.created_email;   
            if (state.datastreamConfigSettings.attribute === undefined) {
              state.datastreamConfigSettings.attribute = '';
            }
          }
          state.mappingConfiguration.mappings = action.payload.payload.formula_config;
          state.created_email = action.payload.payload.created_email;
          state.status = 'idle';
        }
      })
      .addCase(getExisting.rejected, (state, action) => {
        console.log(`%cError happened: `, 'font-weight:bold;color:red;');
        console.log('Details: ', action);
        state.status = 'rejected';
      });

    builder
      .addCase(updateExisting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateExisting.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(updateExisting.rejected, (state, action) => {
        console.log(`%cError happened: `, 'font-weight:bold;color:red;');
        console.log(action);
        state.status = 'rejected';
      });

    builder
      .addCase(getMasterListofDimensions.pending, (state) => { state.status = 'loading';})
      .addCase(getMasterListofDimensions.fulfilled, (state, action) => {
        state.masterList = action.payload.map((element, id) => ({
          id: id,
          value: element,
        }));
        state.status = 'idle';
      })
      .addCase(getMasterListofDimensions.rejected, (state, action) => {
        console.log('Error happened: ', action);
        state.status = 'rejected';
      });
      
      builder
      .addCase(getDatastreamHeaders.rejected, (state, action) => { console.log('Fetching Datastream Headers Failed') })
      .addCase(getDatastreamHeaders.fulfilled, (state, action) => { 
        console.log("Datastream Headers")
        console.log(action)
        state.headersList = action.payload.map((element, id) => ({
          id: id,
          value: element
        }))
      })
  },
});
export const {
  updateFormula,
  updateAlias,
  updateActiveSelector,
  removeSpecificKey,
  updateMasterListMapping,
  updateUserMapping,
  updateInEditMode,
  resetInitState,
  updateModalError,
  updateLastActiveHeaders,
  updateViewType,
  updateFormData,
  resetExistingSliceState,
  resetMappingConfiguration,
  preserveOriginalMapping,
} = updateExistingDataStream.actions;
export default updateExistingDataStream.reducer;
export const existingDStreams = (state) => state.existingDStreams;
