export const billingOperations = {
  UPDATE_MODEL_NAME: 'UPDATE_MODEL_NAME',
};
export const defaultTabConfig = {
  slabError: false,
  slabs: [
    {
      slabName: '',
      isValidSlab: true,
      invalidMessage: '',
      billingCategory: { label: 'Branding', value: 'Branding' },
      rangeType: {
        label: 'Greater than Equal to',
        value: 1,
      },
      rangeValues: {
        fromValue: 0,
        toValue: 0,
      },
      currencyType: { value: 'USD' },
      commission: null,
      retainerFee: null,
    },
  ],
};
export const updatedStatefromBilling = (billingModelSlabs, payload) => {
  const { operationType, operationProps } = payload;

  switch (operationType) {
    case billingOperations.UPDATE_MODEL_NAME: {
      const { billingModelName, oldName, index } = operationProps;
      if (!billingModelSlabs[billingModelName] && billingModelName !== '') {
        if (billingModelSlabs['']) {
          billingModelSlabs[billingModelName] = {
            ...billingModelSlabs[''],
          };
          delete billingModelSlabs[''];
        } else {
          const slabData = { ...billingModelSlabs[oldName].slabs[index] };
          billingModelSlabs[oldName].slabs.splice(index, 1);
          if (billingModelSlabs[oldName].slabs.length === 0) {
            delete billingModelSlabs[oldName];
          }
          billingModelSlabs[billingModelName] = {
            ...defaultTabConfig,
            slabs: [slabData],
          };
        }
      } else if (billingModelSlabs[billingModelName]) {
        const slabData = { ...billingModelSlabs[oldName].slabs[index] };
        billingModelSlabs[oldName].slabs.splice(index, 1);
        if (billingModelSlabs[oldName].slabs.length === 0) {
          delete billingModelSlabs[oldName];
        }
        billingModelSlabs[billingModelName].slabs.push(slabData);
      }
      return billingModelSlabs;
    }
    default:
      return billingModelSlabs;
  }
};
