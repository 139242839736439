import axios from 'axios'
import { nanoid } from 'nanoid';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { errorLogger } from '../../../../../../shared/utils/errorLogger/errorLogger';
import {
  validateForm,
  validationTypes,
} from '../../../../../../shared/utils/formValidator/formValidator';
import { deepmetrics_uri } from '../../../../../../../constants';

export const dateFormat = {
  from: new Date(),
  to: new Date(new Date().setDate(new Date().getDate() + 7)),
};

// Sub-RO action states
// -- helps decide wheather 
// -- -- edit Sub-RO
// -- -- create-new Sub-RO
// -- -- display (do-nothing) Sub-RO
const subROActionStates = {
  DISPLAY   : 'DISPLAY',
  EDITED    : 'EDITED',
  CREATED   : 'CREATED'
}

const validationFields = {
  
  sub_ro_campaigns_selected: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'SubRO Campaigns cannot be empty.',
  },
  
  sub_ro_name: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'SubRO Name cannot be blank.',
  },
  
  revenue_deepMetrics_selected: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: -1,
      }
    },
    invalidMessage: 'Revnenue DeepMetric must be selected',
  },

  sub_ro_dates: {
    validationType: validationTypes.DATE_RANGE,
    validationProps: {},
    invalidMessage: 'Sub RO Date Range cannot be beyond RO Date Range.',
  },
  
  sub_ro_budget: {
    validationType: validationTypes.NUMBER_COMPARISIONS,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'Budget must be greater than 0.',
  },
  
  sub_ro_revenue_price: {
    validationType: validationTypes.NUMBER_COMPARISIONS,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'Budget must be greater than 0.',
  },
  
  sub_ro_revenue_model_selected: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'Revenue Model Must not be Empty.',
  },
  
  sub_ro_ios_selected: {
    validationType: validationTypes.CAMPAIGN_SPECIFIC_USE_CASE,
    validationProps: { dependent: 'sub_ro_campaigns_selected' },
    invalidMessage: 'IOs Must not be Empty.',
  },
  
  sub_ro_ids_selected: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: -1,
      },
    },
    invalidMessage: 'SubRO Id Must not be Empty.',
  },
  
  kpi_1_metric_selected: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'KPI 1 Metric must not be Empty.',
  },
  
  deepMetrics_selected: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'DeepM Metric must not be Empty.',
  },
  
  sub_ro_kpi_goal: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'KPI Goal Name cannot be blank.',
  },
  
  attribution_model_selected: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'Attribution Model must not be Empty.',
  },
  
  kpi_window_selected: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'KPI Window must not be Empty.',
  },

};

const initFillerFields = {
  valid: false,
  showDetails: false,
  state: subROActionStates.DISPLAY,
  sub_ro_id: 201021,
  id: nanoid(),
  kpi_id: nanoid(),
  sub_ro_name: '',
  sub_ro_dates: dateFormat,
  sub_ro_budget: 500000,
  sub_ro_revenue_price: 10000,
  sub_ro_revenue_selected: 'DV3',
  sub_ro_revenue_model_selected: 'CPC',
  sub_ro_campaigns_selected: [],
  sub_ro_ios_selected: [],
  sub_ro_client_id: [],  // see what this is
  sub_ro_pixels_selected: [],
  sub_ro_ids_selected: [],  // Client SubRO ID
  kpi_1_metric_selected: '',
  attribution_model_selected: '',
  sub_ro_client_conversions_selected: [],
  sub_ro_kpi_goal: '',
  kpi_window_selected: '',  
  deepMetrics_selected: '',
  revenue_deepMetrics_selected: '',
  errors: {},
};

const initialState = {
  paused: false,
  // fillerFields: [initFillerFields],
  fillerFields: [],
  sub_ro_advertisers: [],
  sub_ro_campaigns: [],
  sub_ro_ios: [],
  sub_ro_ids: [],
  sub_ro_pixels: [],
  kpi_1_metric: [],
  sub_ro_client_conversions: [],
  attribution_model: [],
  kpi_window: [],
  deepMetrics: [],
};

export const entityVariableMapping = {
  advertisers_campaigns: 'sub_ro_campaigns',
  advertisers_ios: 'sub_ro_ios',
  advertisers_pixelIds: 'sub_ro_pixels',
};

export const getAdvertiserIds = createAsyncThunk(
  'subros/getAdvertiserIds',
  async (_, { getState }) => {
    const advertiserString = getState().connectedAccount.subAccountDetails.dv3_advertisers;
    const advertiserIds = advertiserString.split(",").map(advertiserId => advertiserId.trim())
    return advertiserIds;
  }
);

// Get Sub-ROs
export const getSubROs = createAsyncThunk(
  'subros/getSubROs',
  async ro_id => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/ro/sub_ro/${ro_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Delete Sub-RO
export const deleteSubRO = createAsyncThunk(
  'subros/deleteSubRO',
  async subROId => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/sub-ro/${subROId}`,
      method  : "DELETE",
      headers : { "Authorization" : `Bearer ${session_token}` }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Save Sub-RO
export const saveRO = createAsyncThunk(
  'subros/saveRO',
  async ( _, { getState, rejectWithValue } ) => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const chainStateData = getState().connectedAccount;
    
    // Save Sub-RO Data
    const subRoData = getState().subROs.fillerFields;
    if (subRoData.length === 0) return rejectWithValue("Sub-ROs Not Found") 

    // Save RO Data
    const roState = getState().ros.roDetails;
    const ro_request_configuration = {
      url       : `${deepmetrics_uri}/ro/`,
      method    : "POST",
      headers   : { "Authorization" : `Bearer ${session_token}` },
      data      : {
        ro_name: roState.ro_name,
        ro_number: roState.ro_number,
        ro_status: "Active",
        currency: roState.ro_currency,
        start_date: new Date(roState.ro_dates.from).toISOString().slice(0, 10),
        end_date: new Date(roState.ro_dates.to).toISOString().slice(0, 10),
        billing_cycle: roState.ro_billing_cycle,
        sub_account_id: chainStateData.subAccount_id
      }
    }
    const ro_response = await axios(ro_request_configuration)
    const created_ro = ro_response.data;
    
    const promises = subRoData.map(subROData => {
      const request_configuration = {
        url     : `${deepmetrics_uri}/sub-ro/`,
        method  : "POST",
        headers : { "Authorization" : `Bearer ${session_token}` },
        data    : {
          "sub_ro_name": subROData.sub_ro_name,
          "sub_ro_status": "Active",
          "start_date": subROData.sub_ro_dates.from.toISOString().slice(0, 10),
          "end_date": subROData.sub_ro_dates.to.toISOString().slice(0, 10),
          "budget": +subROData.sub_ro_budget,
          "revenue_model": subROData.sub_ro_revenue_model_selected,
          "revenue_source": subROData.sub_ro_revenue_selected,
          "revenue_price": +subROData.sub_ro_revenue_price,
          "deepm_metric": subROData.revenue_deepMetrics_selected,
          "dv3_pixel_id": subROData.sub_ro_pixels_selected.join(", "),
          "dv3_campaign": subROData.sub_ro_campaigns_selected.join(", "),
          "dv3_io": subROData.sub_ro_ios_selected.join(", "),
          "client_sub_ro_id": subROData.sub_ro_ids_selected.join(", "),
          "kpi": [
            {
              "kpi_metric": subROData.kpi_1_metric_selected,
              "kpi_deepm_metric": subROData.deepMetrics_selected,
              "kpi_goal": subROData.sub_ro_kpi_goal,
              "kpi_window": subROData.kpi_window_selected,
              "pixel_id": "",
              "sub_ro_id": 0,
              "id" : 0,
            }
          ],
          "ro_id": created_ro.id,
        }
      }
      return axios(request_configuration)
    })
    const savedSubROs = await Promise.all(promises)
    return savedSubROs.map(savedSubRO => savedSubRO.data)
  }
)

// Edit RO 
// -- create new Sub-ROs if need be
// -- edit existing Sub-ROs if needed
export const editRO = createAsyncThunk(
  'subros/editSubRO', 
  async (_, { getState, rejectWithValue }) => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const chainStateData = getState().connectedAccount;

    // All Sub-ROs data
    const subRoData = getState().subROs.fillerFields;
    if (subRoData.length === 0) return rejectWithValue("Sub-ROs Not Found")

    // Edit RO Data
    const ro_id = chainStateData.ro_id;
    const roState = getState().ros.roDetails;
    const ro_request_configuration = {
      url       : `${deepmetrics_uri}/ro/${ro_id}`,
      method    : "PUT",
      headers   : { "Authorization" : `Bearer ${session_token}` },
      data      : {
        ro_name: roState.ro_name,
        ro_number: roState.ro_number,
        ro_status: "Active",
        currency: roState.ro_currency,
        start_date: new Date(roState.ro_dates.from).toISOString().slice(0, 10),
        end_date: new Date(roState.ro_dates.to).toISOString().slice(0, 10),
        billing_cycle: roState.ro_billing_cycle,
        sub_account_id: chainStateData.subAccount_id
      }
    }
    const ro_response = await axios(ro_request_configuration)
    const created_ro = ro_response.data;
    
    // Create New Sub-ROs
    const create_promises = subRoData
      .filter(subROData => subROData.state === subROActionStates.CREATED)
      .map(subROData => {
        const request_configuration = {
          url     : `${deepmetrics_uri}/sub-ro/`,
          method  : "POST",
          headers : { "Authorization" : `Bearer ${session_token}` },
          data    : {
            "sub_ro_name": subROData.sub_ro_name,
            "sub_ro_status": "Active",
            "start_date": subROData.sub_ro_dates.from.toISOString().slice(0, 10),
            "end_date": subROData.sub_ro_dates.to.toISOString().slice(0, 10),
            "budget": +subROData.sub_ro_budget,
            "revenue_model": subROData.sub_ro_revenue_model_selected,
            "revenue_source": subROData.sub_ro_revenue_selected,
            "revenue_price": +subROData.sub_ro_revenue_price,
            "deepm_metric": subROData.revenue_deepMetrics_selected,
            "dv3_pixel_id": subROData.sub_ro_pixels_selected.join(", "),
            "dv3_campaign": subROData.sub_ro_campaigns_selected.join(", "),
            "dv3_io": subROData.sub_ro_ios_selected.join(", "),
            "client_sub_ro_id": subROData.sub_ro_ids_selected.join(", "),
            "kpi": [
              {
                "kpi_metric": subROData.kpi_1_metric_selected,
                "kpi_deepm_metric": subROData.deepMetrics_selected,
                "kpi_goal": subROData.sub_ro_kpi_goal,
                "kpi_window": subROData.kpi_window_selected,
                "pixel_id": "",
                "sub_ro_id": 0,
                "id" : 0,
              }
            ],
            "ro_id": ro_id,
          }
        }
        return axios(request_configuration)
      })

    // Edit existing ROs
    const edit_promises = subRoData
      .filter(subROData => subROData.state === subROActionStates.EDITED)
      .map(subROData => {
        const subRO_id = subROData.id;
        const request_configuration = {
          url     : `${deepmetrics_uri}/sub-ro/${subRO_id}`,
          method  : "PUT",
          headers : { "Authorization" : `Bearer ${session_token}` },
          data    : {
            "sub_ro_name": subROData.sub_ro_name,
            "sub_ro_status": "Active",
            "start_date": subROData.sub_ro_dates.from.toISOString().slice(0, 10),
            "end_date": subROData.sub_ro_dates.to.toISOString().slice(0, 10),
            "budget": +subROData.sub_ro_budget,
            "revenue_model": subROData.sub_ro_revenue_model_selected,
            "revenue_source": subROData.sub_ro_revenue_selected,
            "revenue_price": +subROData.sub_ro_revenue_price,
            "deepm_metric": subROData.revenue_deepMetrics_selected,
            "dv3_pixel_id": subROData.sub_ro_pixels_selected.join(", "),
            "dv3_campaign": subROData.sub_ro_campaigns_selected.join(", "),
            "dv3_io": subROData.sub_ro_ios_selected.join(", "),
            "client_sub_ro_id": subROData.sub_ro_ids_selected.join(", "),
            "kpi": [
              {
                "kpi_metric": subROData.kpi_1_metric_selected,
                "kpi_deepm_metric": subROData.deepMetrics_selected,
                "kpi_goal": subROData.sub_ro_kpi_goal,
                "kpi_window": subROData.kpi_window_selected,
                "pixel_id": "",
                "sub_ro_id": subRO_id,
                "id" : subROData.kpi_id,
              }
            ],
            "ro_id": ro_id,
          }
        }
        console.log("EDIT")
        console.log(JSON.stringify(request_configuration.data))
        return axios(request_configuration)
      })
      
    const createdSubROs = await Promise.all(create_promises)
    const editedSubROs = await Promise.all(edit_promises)
    return [ ...createdSubROs, ...editedSubROs ].map(subRO => subRO.data)
  }
)

export const SubROSlice = createSlice({
  name: 'metadata/SubROSlice',
  initialState,
  reducers: {
    resetState: () => initialState,
    addSubRO: (state) => { state.fillerFields.push({ ...initFillerFields, id: nanoid(), state: subROActionStates.CREATED }) },
    
    removeSubRO: (state, action) => {
      const { index } = action.payload;
      state.fillerFields.splice(index, 1);
    },

    updateShowDetails: (state, action) => {
      const { index, value } = action.payload;
      state.fillerFields.forEach((_, ii) => {
        if (ii === index) state.fillerFields[ii].showDetails = value;
        else state.fillerFields[ii].showDetails = false;
      });
    },

    eraseExistingAdvertisers: (state) => { state.sub_ro_advertisers = [] },
    eraseExistingCampaigns: (state) => { state.sub_ro_campaigns = [] },
    
    updateArrayState: (state, action) => {
      const { key, value, index, miscProps } = action.payload;
      state.fillerFields[index][key] = value;
      
      if (state.fillerFields[index].state === subROActionStates.DISPLAY) 
          state.fillerFields[index].state = subROActionStates.EDITED;
      
      const { errors, allGood } = validateForm({
        values: state.fillerFields[index],
        fields: validationFields,
        miscProps: miscProps,
      });
      state.fillerFields[index].errors = errors;
      state.fillerFields[index].valid = allGood;
    },
  },
  
  extraReducers: (builder) => {
    // Get Adertiser_IDs
    builder
      .addCase(getAdvertiserIds.pending, (state) => { state.paused = true })
      .addCase(getAdvertiserIds.fulfilled, (state, action) => {
        state.paused = false;
        state.sub_ro_advertisers = action.payload;
      })
      .addCase(getAdvertiserIds.rejected, (state, action) => {
        errorLogger(action, 'Caused error in getAdvertiserIds');
        state.paused = false;
      });

    // Save RO
    builder
      .addCase(saveRO.pending, (state) => { state.paused = true })
      .addCase(saveRO.fulfilled, (state, action) => { 
        alert("RO Saved Successfully. All Sub ROs Saved successfully")
        state.paused = false; 
      })
      .addCase(saveRO.rejected, (state, action) => {
        if (action.payload === "Sub-ROs Not Found") alert("Please add Sub-ROs before saving")
        errorLogger(action, "Error observed while saving Sub-ROs")
        state.paused = false;
      })

    // Delete SubRO
    builder
      .addCase(deleteSubRO.pending, (state) => { state.paused = true })
      .addCase(deleteSubRO.fulfilled, (state, action) => {
        alert("Sub-RO Deleted Successfully")
        state.paused = false;
      })
      .addCase(deleteSubRO.rejected, (state, action) => {
        errorLogger(action, "Error observed while deleting Sub-RO")
        state.paused = false;
      })

    // Edit Sub-RO
    builder
      .addCase(editRO.pending, (state) => { state.paused = true })
      .addCase(editRO.fulfilled, (state, action) => {
        alert("RO Edited Successfully. All Sub ROs Edited successfully")
        state.paused = false;
      })
      .addCase(editRO.rejected, (state, action) => {
        if (action.payload === "Sub-ROs Not Found") alert("Please add Sub-ROs before saving")
        errorLogger(action, "Error Observed while editing Sub-ROs")
        state.paused = false;
      })

    // Get Sub-RO-Ids
    builder
      .addCase(getSubROs.pending, (state) => { state.paused = true })
      .addCase(getSubROs.fulfilled, (state, action) => {
        state.paused = false;
        const subROs = action.payload
        state.fillerFields = subROs.map(subro => ({
          valid: true,
          showDetails: false,
          state: subROActionStates.DISPLAY,
          sub_ro_id: subro.id,
          id: subro.id,
          sub_ro_name: subro.sub_ro_name,
          sub_ro_dates: { from: new Date(subro.start_date), to: new Date(subro.end_date) },
          sub_ro_budget: +subro.budget, 
          sub_ro_revenue_price: +subro.revenue_price,
          sub_ro_revenue_selected: subro.revenue_source,
          sub_ro_revenue_model_selected: subro.revenue_model,
          sub_ro_campaigns_selected: subro.dv3_campaign.split(",").filter(campaign => campaign.trim()),
          sub_ro_ios_selected: subro.dv3_io.split(",").map(io => io.trim()),
          sub_ro_client_id: [],  //see what this is
          sub_ro_pixels_selected: subro.dv3_pixel_id.split(",").map(pixel => pixel.trim()),
          sub_ro_ids_selected: subro.client_sub_ro_id.split(",").map(client_sub_ro_id => client_sub_ro_id.trim()),  //Client SubRO ID
          kpi_1_metric_selected: subro.kpi.length > 0 ? subro.kpi[0].kpi_metric : '',
          sub_ro_kpi_goal:  subro.kpi.length > 0 ? subro.kpi[0].kpi_goal : '',
          kpi_window_selected:  subro.kpi.length > 0 ? subro.kpi[0].kpi_window : '',  
          deepMetrics_selected:  subro.kpi.length > 0 ? subro.kpi[0].kpi_deepm_metric : '',
          kpi_id: subro.kpi.length > 0 ? subro.kpi[0].id : nanoid(),
          sub_ro_client_conversions_selected: [],
          attribution_model_selected: 'First Touch',
          revenue_deepMetrics_selected: subro.deepm_metric,
          errors: {},
        }))
      })
      .addCase(getSubROs.rejected, (state, action) => {
        errorLogger(action, "Error observed while fetching Sub-ROs")
        state.paused = false;
        state.fillerFields = [];
      })

  },
});
export const {
  eraseExistingAdvertisers,
  updateArrayState,
  addSubRO,
  removeSubRO,
  updateShowDetails,
} = SubROSlice.actions;
export const subRoSlice = (state) => state.subROs;
export default SubROSlice.reducer;
