import React from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-slidedown/lib/slidedown.css'; // React Slidedown
import './App.scss';
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Layout from './features/shared/hoc/Layout/Layout';
import LoginPage from './features/login-page/login';
import { FRONTEND_ROUTES } from './features/shared/backend/frontendRoutes';
import { ComponentRoutes } from './AppRoutes';
import { useSelector } from 'react-redux';
import { userData } from './features/login-page/slices/userSlice';
// import PrivateRoute from './features/shared/hoc/PrivateRoute/PrivateRoute';

function App(props) {

  // const user = useSelector(userData);
  const location = useLocation();

  // const isUserSignedIn = user.userSignedIn;
  const isUserSignedIn = true;

  let routes = (
    <Switch>
      <Route exact path='/view' component={ComponentRoutes.asyncView} />
      <Route exact path='/' component={LoginPage} />
      <Redirect to={{ to: '/', prevPath: location.pathname }} />
    </Switch>
  );

  if (isUserSignedIn) {
    routes = (
      <Switch>
        <Route
          exact
          path={FRONTEND_ROUTES.CONFIG_PAGE}
          component={ComponentRoutes.asyncConfig}
        />
        <Route
          exact
          path={FRONTEND_ROUTES.CONFIG_PAGE_REST}
          component={ComponentRoutes.asyncConfig}
        />
        <Route
          exact
          path={FRONTEND_ROUTES.ADVERTISERS_PAGE}
          component={ComponentRoutes.asyncView}
        />
        <Route
          exact
          path={FRONTEND_ROUTES.VIEWS_PAGE}
          component={ComponentRoutes.asyncAdvertiserPage}
        />

        <Route
          exact
          path='/schedule'
          component={ComponentRoutes.asyncConstruction}
        />
        <Route exact path='/' component={LoginPage} />
        <Route path='/' component={ComponentRoutes.notFound} />
        <Redirect to='/' />
      </Switch>
    );
  }

  return (
    <div>
      <Layout>{routes}</Layout>
    </div>
  );
}

export default withRouter(App);
