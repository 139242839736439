import React from 'react';
import classes from '../../../Accounts/components/Accounts/createAccounts/Create.module.scss';
// import Select from 'react-select';
import UseExisting from './subComponents/UseExisting/UseExisting';


export default function Create(props) {
  
  // const createNewOptions = [
  //   { label: 'Same As Account', value: 0 },
  //   { label: 'Let Me Create', value: 1 },
  // ];
  // const [createOption, setCreateOption] = React.useState(0);
  
  return (
    <React.Fragment>
      <div className={[classes.FormComponent, classes.ScrollBar].join(' ')}>

        {/* Same-As-Account vs Let-Me-Create Option */}
        {/* <div className={classes.FormComponent_LabelInput}>
          <label className={classes.FormComponent_LabelInput__Label}>
            SubAccount:
          </label>
          <Select
            className={classes.FormComponent_LabelInput__ReactSelectLabel}
            options={createNewOptions}
            value={createNewOptions[createOption]}
            onChange={(selection) => setCreateOption(selection.value)}
          />
        </div> */}

        {/* Differnt Components to be rendered based on Selection */}
        {/* Not Going ahead with same-as-account vs let-me-create option */}
        {/* {createOption ? (
          <React.Fragment>
            <div className={classes.FormComponent_LabelInput}>
              <label className={classes.FormComponent_LabelInput__Label}>
                Sub Account Name:
              </label>
              <input
                className={classes.FormComponent_LabelInput__InputTextField}
              />
            </div>
          </React.Fragment>
        ) : (
          <UseExisting data={data} classes={classes} />
        )} */}
        <UseExisting classes={classes} />
      </div>
    </React.Fragment>
  );
}
