import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { errorLogger } from '../../../../../../shared/utils/errorLogger/errorLogger';
import { dateFormat } from './subROs';
import {
  validationTypes,
  validateForm,
} from '../../../../../../shared/utils/formValidator/formValidator';
import { deepmetrics_uri } from '../../../../../../../constants';
import axios from 'axios';

// Error Types
const errorDefinition = {
  ro_name: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'RO Name cannot be blank.',
  },
};

// Initial State
const initialState = {
  paused: false,
  ro_id: null,
  roDetails: {
    ro_name: '',
    ro_dates: dateFormat,
    ro_number: 0,
    ro_currency: 'INR',
    ro_billing_cycle: 'Fortnightly',
  },
  existingROs: [],
  inEditMode: false,
  formDetails: {},
  errors: {},
  valid: false,
};

// Get List of ROs
export const getROs = createAsyncThunk(
  'ros/loadExistingROs',
  async () => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/ro/`,
      method    : "GET",
      headers   : { "Authorization" : `Bearer ${session_token}` },
    }
    const response = await axios(request_configuration)
    return response.data;
  }
);

// Get specific RO
export const getFilteredROs = createAsyncThunk(
  'ros/loadFilteredROs',
  async sub_account_id => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/sub-account/ro/${sub_account_id}`,
      method    : "GET",
      headers   : { "AUthorization" : `Bearer ${session_token}` },
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

export const draftRo = createAsyncThunk(
  'ros/draftRo',
  async (ro_status, { getState }) => {}
);

// Save RO
export const saveRO = createAsyncThunk(
  'ros/createNewRO',
  async ( _, { getState }) => {
    const roState = getState().ros.roDetails;
    const chainState = getState().connectedAccount;
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/ro/`,
      method    : "POST",
      headers   : { "Authorization" : `Bearer ${session_token}` },
      data      : {
        ro_name: roState.ro_name,
        ro_number: roState.ro_number,
        ro_status: "Active",
        currency: roState.ro_currency,
        start_date: new Date(roState.ro_dates.from).toISOString().slice(0, 10),
        end_date: new Date(roState.ro_dates.to).toISOString().slice(0, 10),
        billing_cycle: roState.ro_billing_cycle,
        sub_account_id: chainState.subAccount_id
      }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Delete RO
export const deleteRO = createAsyncThunk(
  'ros/deleteRO',
  async roId => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/ro/${roId}`,
      method  : "DELETE",
      headers : { "Authorization" : `Bearer ${session_token}` }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Edit Existing RO
export const editRO = createAsyncThunk(
  'ros/editRO',
  async ( ro_id, getState ) => {
    const roState = getState().ros.roDetails;
    const chainState = getState().connectedAccount;
    const session_token = localStorage.getItem('SESSION_TOKEN');
    const request_configuration = {
      url     : `${deepmetrics_uri}/ro/${ro_id}`,
      method  : "PUT",
      headers : { "Authorization" : `Bearer ${session_token}` },
      data    : {
        ro_name: roState.ro_name,
        ro_number: roState.ro_number,
        ro_status: "Active",
        currency: roState.ro_currency,
        start_date: new Date(roState.ro_dates.from).toISOString().slice(0, 10),
        end_date: new Date(roState.ro_dates.to).toISOString().slice(0, 10),
        billing_cycle: roState.ro_billing_cycle,
        sub_account_id: chainState.subAccount_id
      }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Get RO
export const getRO = createAsyncThunk(
  'ros/getRO',
  async ro_id => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/ro/${ro_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

export const ROSlice = createSlice({
  name: 'metadata/roSlice',
  initialState,
  reducers: {
    resetState:         () => initialState,
    setEditMode:        (state, action) => { state.inEditMode = action.payload; },
    eraseExistingROs:   (state) => { state.existingROs = []; },
    eraseFormDetails:   (state) => { state.formDetails = {}; },
    updateRODetails:    (state, action) => {
      const { key, value } = action.payload;
      state.roDetails[key] = value;
      const { errors, allGood } = validateForm({
        values: state.roDetails,
        fields: errorDefinition,
      });
      state.errors = errors;
      state.valid = allGood;
    },
    setROFromObject:    (state, action) => {
      const ro = action.payload;
      state.roDetails = {
        ro_name: ro.ro_name,
        ro_dates: {
          from: ro.start_date,
          to: ro.end_date
        },
        ro_number: ro.ro_number,
        ro_currency: ro.currency,
        ro_billing_cycle: ro.billing_cycle,
      }
    }
  },
  extraReducers: (builder) => {

    // Get RO's
    builder
      .addCase(getROs.pending, (state) => { state.paused = true })
      .addCase(getROs.fulfilled, (state, action) => {
        state.existingROs = action.payload
        state.paused = false;
      })
      .addCase(getROs.rejected, (state, action) => {
        errorLogger(action, 'Error observed in getROs');
        state.paused = false;
      });

    // Get Filtered RO's
    builder
      .addCase(getFilteredROs.pending, (state) => { state.paused = true })
      .addCase(getFilteredROs.fulfilled, (state, action) => {
        state.existingROs = action.payload;
        state.paused = false;
      })
      .addCase(getFilteredROs.rejected, (state, action) => {
        errorLogger(action, 'Error observed in getFilteredROs')
        state.paused = false;
      })

    // Draft RO
    builder
      .addCase(draftRo.pending, (state) => { state.paused = true })
      .addCase(draftRo.fulfilled, (state, action) => { state.paused = false })
      .addCase(draftRo.rejected, (state, action) => {
        errorLogger(action, 'Error observed in DraftRO');
        state.paused = false;
      });

    // Save RO
    builder
      .addCase(saveRO.pending, (state) => { state.paused = true })
      .addCase(saveRO.fulfilled, (state, action) => { 
        state.paused = false; 
        alert(`RO Successfully Saved !!!`);
      })
      .addCase(saveRO.rejected, (state, action) => {
        errorLogger(action, 'Error observed in Saving RO')
        state.paused = false;
      })

    // Delete RO
    builder
      .addCase(deleteRO.pending, state => { state.paused = true })
      .addCase(deleteRO.fulfilled, state => {
        state.paused = false;
        alert("RO deleted Successfully")
      })
      .addCase(deleteRO.rejected, (state, action) => {
        state.paused = false;
        errorLogger(action, "Error observed while deleting RO")
      })

    // Edit RO
    builder
      .addCase(editRO.pending, (state) => { state.paused = true })
      .addCase(editRO.fulfilled, (state, action) => { 
        state.paused = false;
        alert(`RO Edited Successfully !!!`)
       })
       .addCase(editRO.rejected, (state, action) => {
         errorLogger(action, 'Error observed in Edit RO')
         state.paused = false;
       })

    // Get RO
    builder
      .addCase(getRO.pending, (state) => { state.paused = true })
      .addCase(getRO.fulfilled, (state, action) => { 
        state.paused = false;
        const ro = action.payload;
        state.valid = true;
        state.roDetails = {
          ro_name: ro.ro_name,
          ro_dates: { from: new Date(ro.start_date), to: new Date(ro.end_date) },
          ro_number: ro.ro_number,
          ro_currency: ro.currency,
          ro_billing_cycle: ro.billing_cycle,
        }
      })
      .addCase(getRO.rejected, (state, action) => {
        state.paused = false;
        errorLogger(action, 'Error observed in Get RO')
      })

  },
});
export const { resetState, setEditMode, eraseExistingROs, updateRODetails } = ROSlice.actions;
export const roSliceData = (state) => state.ros;
export default ROSlice.reducer;
