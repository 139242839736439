import React from 'react';
import classes from './view.module.scss';
import Spinner from '../shared/ui-components/spinner/spinner';
import { useSelector, useDispatch } from 'react-redux';
import AdvertiserView from './advertisers/AdvertiserView/Advertiser';

import { advertiserFilters, loadAdvertisers } from './slices/advertiserSlice';
import NotFound from '../notFound/NotFound';
//import NavBars from './sharedResources/uiComponents/NavBars';
import NavBars from '../shared/ui-components/SideBar/NavBars';
import {
  activeViewTab,
  updateActiveViewSubTab,
} from '../shared/slices/navBarSlice';
import { viewNavBarButtons } from '../shared/frontendConstants/constants';

export default function ViewSkeleton(props) {
  let RenderComponent = <NotFound />;
  const advRowElements = useSelector(advertiserFilters);
  const isLoading = advRowElements.status === 'loading';
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (props.match.params.viewOps === 'advertisers') {
      dispatch(loadAdvertisers());
    }
  }, [dispatch, props.match.params]);
  if (props.match.params.viewOps === 'advertisers')
    RenderComponent = <AdvertiserView />;
  return (
    <div className={classes.ViewSkeleton}>
      {isLoading && <Spinner />}
      <div>
        <div className={classes.VerticalNavBar}>
          <NavBars
            classes={classes}
            activeViewTab={activeViewTab}
            updateActiveViewSubTab={updateActiveViewSubTab}
            navBarButtons={viewNavBarButtons}
          />
        </div>
      </div>
      <div className={classes.SkeletonView}>{RenderComponent}</div>
    </div>
  );
}
