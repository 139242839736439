import React from 'react'
import classes from './SetFilterWindow.module.scss'

import InputDropDown from '../InputDropDown'

function SetFilterWindow({
    setFilter,
    filterColumnName = "",
    filterOperator = "",
    filterValue = "",
    nameOptions = [],
    operatorOptions = [],
    valueOptions = [],
}) {

    const [ columnName, setColumnName ] = React.useState(filterColumnName)
    const [ operator, setOperator ] = React.useState(filterOperator)
    const [ value, setValue ] = React.useState(filterValue)

    const setFilterAfterCheck = () => {
        if (nameOptions.length > 0 && !nameOptions.includes(columnName)) {
            alert(`${columnName} is Invalid Column Name Argument`);
            return;
        } 
        if (operatorOptions.length > 0 && !operatorOptions.includes(operator)) {
            alert(`${operator} is Invalid Operator Argument`); 
            return;
        }

        setFilter({
            id: Math.floor(Math.random()*(999-100+1)+100),
            name: columnName,
            operator: operator,
            value: value
        })
    }

    const clearFilter = () => { 
        setColumnName(""); 
        setOperator(""); 
        setValue(""); 
    }

    return (
        <div className={ classes.SetFilterWindowContainer }>
            <div className={classes.FilterInputContainer}>
                <InputDropDown 
                    placeholder = {`Add Column Name`}
                    input = {columnName}
                    setInput = {setColumnName}
                    dropdownOptions = {nameOptions}
                />
            </div>
            <div className = {classes.FilterInputContainer}>
                <InputDropDown 
                    placeholder = {`Add Operator`}
                    input = {operator}
                    setInput = {setOperator}
                    dropdownOptions = {operatorOptions}
                />
            </div>
            <div className = {classes.FilterInputContainer}>
                <InputDropDown 
                    placeholder = {`Add Value`}
                    input = {value}
                    setInput = {setValue}
                    dropdownOptions = {valueOptions}
                />
            </div>
            <input 
                className = { classes.FilterButton }
                type = "button"
                value = "Create Filter"
                onClick = { event => { event.preventDefault(); setFilterAfterCheck(); clearFilter(); } }
            />
        </div>
    )
}

export default SetFilterWindow
