import React from 'react';
import classes from './ReactDropZone.module.scss';
import { icons, IconGeneric } from '../icons/Icons';
import XLSX from 'xlsx';

export default function CustomDropZone({ onSuccessFileUpload }) {
  const [highlighted, setHighlighted] = React.useState(false);
  const [error, setError] = React.useState({ error: false, msg: null });

  const processData = ({ data, fileLoc }) => {
    const dataStringLines = data.split(/\r\n|\n/).filter((element) => {
      return (
        element.split(',').reduce((acc, curr) => {
          if (curr === '') acc += 1;
          else if (curr.toLowerCase().includes('total')) acc += 4;

          return acc;
        }, 0) < 3
      );
    });
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    //Csv Callback
    const name = 'csv';
    const result = [{ data: list, name }];
    onSuccessFileUpload &&
      onSuccessFileUpload({
        fileMetaData: {
          fileType: name,
          fileLoc: fileLoc,
          name,
        },
        data: result,
      });
  };

  const handleCSVs = ({ file, fileLoc }) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws);
      processData({ data, fileLoc });
    };
    reader.readAsBinaryString(file);
  };

  const loadExcel = ({ file, fileLoc }) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = XLSX.read(data, {
        type: 'binary',
      });
      const returnData = [];
      let firstName = '';
      workbook.SheetNames.forEach(function (sheetName, index) {
        // Here is your object
        const XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        returnData.push({
          data: XL_row_object,
          name: sheetName,
        });
        if (index === 0) {
          firstName = sheetName;
        }
      });
      //Excel CallBack
      onSuccessFileUpload &&
        onSuccessFileUpload({
          fileMetaData: {
            fileType: 'xlsx',
            fileLoc: fileLoc,
            name: firstName,
          },
          data: returnData,
        });
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };

    reader.readAsBinaryString(file);
  };

  const fileHandler = ({ e, isDrop }) => {
    const fileType = isDrop ? e.name : e.target.value;
    const targetFiles = isDrop ? e : e.target.files[0];
    if (fileType.endsWith('.csv'))
      handleCSVs({ file: targetFiles, fileLoc: fileType });
    else if (fileType.endsWith('.xlsx') || fileType.endsWith('.xls')) {
      loadExcel({ file: targetFiles, fileLoc: fileType });
    } else {
      setError({
        error: true,
        msg: 'File Type not Supported. You can only use .csv or .xlsx files.',
      });
    }
  };
  const onInputChangeHandler = (e) => {
    e.preventDefault();
    fileHandler({ e });
  };

  return (
    <React.Fragment>
      <div
        className={
          highlighted ? classes.ReactDropZoneActive : classes.ReactDropZone
        }
        onDragEnter={() => {
          setHighlighted(true);
        }}
        onDragLeave={() => {
          setHighlighted(false);
        }}
        onDragOver={(e) => {
          setHighlighted(true);
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          setHighlighted(false);
          //console.log({ e: e.dataTransfer.files[0] });
          fileHandler({ e: e.dataTransfer.files[0], isDrop: true });
        }}>
        <IconGeneric
          iconName={icons.UPLOAD_ICON}
          iconProps={{ className: classes.dragDropIcon }}
        />
        <p className={classes.dragDrop}>
          DRAG & DROP -or -{' '}
          <span>
            <label
              className={[classes.InputButton, classes.DVButtonGreen].join(
                ' '
              )}>
              <input
                id='fileSelect'
                type='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={onInputChangeHandler}
              />
              Browse
            </label>
          </span>
        </p>
        {error.error ? <p className={classes.ErrorClass}>{error.msg}</p> : null}
      </div>
    </React.Fragment>
  );
}
