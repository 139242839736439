import { componentTypes } from '../../../../../../../../shared/ui-components/ComponentGenerator/ComponentGenerator';
import { nanoid } from 'nanoid';
import classes from '../Create.module.scss';

export const contractComponents = [
  {
    elementType: componentTypes.INPUT_TEXTFIELD,
    key: nanoid(),
    props: {
      containerClassName: classes.FormComponent_LabelInput,
      labelClassName: classes.FormComponent_LabelInput__Label,
      inputClassName: classes.FormComponent_LabelInput__InputTextField,
      label: 'Contract No',
      reduxIdentifier: 'contractName',
    },
  },
  {
    elementType: componentTypes.INPUT_DATERANGE,
    key: nanoid(),
    props: {
      containerClassName: classes.FormComponent_LabelInput,
      labelClassName: classes.FormComponent_LabelInput__Label,
      label2ClassName: classes.FormComponent_LabelInput__Label2,
      inputClassName: classes.FormComponent_LabelInput__InputTextField,
      label: 'Effective Dates',
      labelTo: ' to ',
      reduxIdentifier: 'effectiveDates',
    },
  },

  {
    elementType: componentTypes.INPUT_DATE,
    key: nanoid(),
    props: {
      containerClassName: classes.FormComponent_LabelInput,
      labelClassName: classes.FormComponent_LabelInput__Label,
      inputClassName: classes.FormComponent_LabelInput__InputTextField,
      label: 'Evaluation End Date',
      reduxIdentifier: 'evaluationDate',
    },
  },
];
