import React from 'react';
// import classes from '../../Config.module.scss';
import DataStreamForm from '../DataStreamForm/DataStreamForm';
import { connectMixData, updateFormData } from '../../slices/connectMix';
import { existingDStreams } from '../../slices/editExistingSlice';
import { dataStreams, updateDataStreamName } from '../../slices/dataStream';

import { useSelector } from 'react-redux';

export default function PreConfig() {
  const data = useSelector(connectMixData);
  const existingData = useSelector(existingDStreams);
  const dstreamData = useSelector(dataStreams);
  const streamName = dstreamData.createdataStream.name;
  const errors_dstream = dstreamData.errors;

  return (
    <DataStreamForm
      data={data}
      existingData={existingData}
      streamName={streamName}
      created={dstreamData.createdataStream.created}
      errors_dstream={errors_dstream}
      updateDataStreamName={updateDataStreamName}
      updateFormData={updateFormData}
    />
  );
}
