export const optionsMap = {
  'Google Sheets': 'GoogleSheets',
  'Google Drive': 'GoogleDrive',
  'Gmail Push': 'Gmail-Push',
  'Gmail Pull': 'Gmail-Pull',
  'Open Link': 'OpenLink',
  'BigQuery': 'BigQuery',
  'Branch': 'Branch',
  // 'Google Cloud Storage': 'Google Cloud Storage',
};

export const availableOptions = [
  { label: 'Google Sheets', value: optionsMap['Google Sheets'] },
  // { label: 'Google Drive', value: optionsMap['Google Drive'] },
  { label: 'Gmail Push', value: optionsMap['Gmail Push'] },
  // { label: 'Gmail Pull Sheets', value: optionsMap['Gmail Pull'] },
  { label: 'Public Links', value: optionsMap['Open Link'] },
  // { label: 'BigQuery', value: optionsMap['BigQuery'] },
  // { label: 'Branch', value: optionsMap['Branch'] },
  // { value: 'Google Cloud Storage', label: optionsMap['Google Cloud Storage'] },
];
