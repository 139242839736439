import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './navbar.module.scss';
import Icon from '../../../shared/ui-components/icons/Icons';

const preDefinedActiveStyle = {
  color: '#0F9D58',
  backgroundColor: 'rgba(200,230,201,.56)',
  fontWeight: 500,
  borderTopRightRadius: '1rem',
  borderBottomRightRadius: '1rem',
  textDecoration: 'none',
};
export default function NavigationBar({ buttonsArray }) {
  return (
    <>
      {Object.keys(buttonsArray).map((ele) => {
        const element = buttonsArray[ele];
        const icon = element.icon ? Icon(element.icon) : null;
        return (
          <NavLink
            key={element.id}
            to={element.uri || '/'}
            isActive={(_, location) => {
              const currLocation = location.pathname;
              if (element.multiNavLink) {
                if (element.activeRoutes.includes(currLocation)) {
                  return true;
                }
                return false;
              } else {
                return currLocation === element.uri;
              }
            }}
            className={classes.NavBarElements}
            activeStyle={element.activeStyle || preDefinedActiveStyle}>
            {icon} <p className={classes.TagElement}>{element.label}</p>
          </NavLink>
        );
      })}
    </>
  );
}
