import { nanoid } from 'nanoid';
import { icons } from '../ui-components/icons/Icons';
export const navBarButtons = {
  config: {
    id: nanoid(),
    label: 'Config',
    uri: '/config/metadata/accounts',
    action_id: 'level0__config',
    action_state: {},
    multiNavLink: true,
    activeRoutes: [
      '/config/metadata/bio',
      '/config/metadata/accounts',
      '/config/metadata/subaccounts',
      '/config/metadata/ro',
      '/config/metadata/invoice',
      '/config/datastream',
      '/config/dailyrevenue',
    ],
  },
  
  view: {
    id: nanoid(),
    label: 'View',
    uri: '/view/advertisers',
    action_id: 'level0__view',
    action_state: {},
    multiNavLink: true,
    activeRoutes: ['/view/advertisers', '/view/billing', '/view/network'],
  },
  
  schedule: {
    id: nanoid(),
    label: 'Schedule',
    uri: '/schedule',
    action_id: 'level0__schedule',
    action_state: {},
  },

};

export const viewNavBarButtons = {
  
  view_campaigns: {
    id: nanoid(),
    label: 'Advertisers',
    uri: '/view/advertisers',
    action_id: 'level1__view__campaigns',
    action_state: {},
    icon: icons.PEOPLE_ICON,
  },
  
  view_billing: {
    id: nanoid(),
    label: 'Billing',
    uri: '/view/billing',
    action_id: 'level1__view__billing',
    action_state: {},
    icon: icons.BILLING_ICON_MUI,
  },
  
  view_network: {
    id: nanoid(),
    label: 'Network',
    uri: '/view/network',
    action_id: 'level1__view__network',
    action_state: {},
    icon: icons.NETWORK_ICON,
  },

};

export const configNavBarButtons = {
  
  view_campaigns: {
    id: nanoid(),
    label: 'Metadata',
    uri: '/config/metadata/accounts',
    action_id: 'level1__config__metadata',
    action_state: {},
    icon: icons.PEOPLE_ICON,
    multiNavLink: true,
    activeRoutes: [
      '/config/metadata/bio',
      '/config/metadata/accounts',
      '/config/metadata/subaccounts',
      '/config/metadata/ro',
      '/config/metadata/invoice',
    ],
  },
  
  view_billing: {
    id: nanoid(),
    label: 'Datastream',
    uri: '/config/datastream',
    action_id: 'level1__config__datastream',
    action_state: {},
    icon: icons.BILLING_ICON_MUI,
  },
  
  view_network: {
    id: nanoid(),
    label: 'Daily Revenue',
    uri: '/config/dailyrevenue',
    action_id: 'level1__config__dailyrevenue',
    action_state: {},
    icon: icons.NETWORK_ICON,
  },
  
  view_harmonize: {
    id: nanoid(),
    label: 'Harmonize',
    uri: '/config/harmonize',
    action_id: 'level1__config__harmonize',
    action_state: {},
    icon: icons.RESONATE_ICON,
    multiNavLink: true,
    activeRoutes: [
      '/config/harmonize/strategy_mapping',
      '/config/harmonize/creative_mapping'
    ]
  },
};

export const activenavBarButtons_Bio = {
  bio: {
    id: nanoid(),
    label: 'Business IO',
    uri: null,
    action_id: 'level2__bio',
    action_state: {},
  },
  deepdive: {
    id: nanoid(),
    label: 'Deep Dive',
    uri: null,
    action_id: 'level2__deepdive',
    action_state: {},
  },
};

export const MetaDataRoutes = {
  accounts: '/config/metadata/accounts',
  subaccounts: '/config/metadata/subaccounts',
  ros: '/config/metadata/ro',
  subros: '/config/metadata/invoice',
};

export const configMetaDataTabs = {
  accounts: {
    id: nanoid(),
    label: 'Accounts',
    uri: MetaDataRoutes.accounts,
    isMultipleActive: false,
    action_id: 'level2__config_metaata__businessIO',
    action_state: {},
    className: 'NavTabPassive',
    activeClassName: 'NavTabActive',
  },
  subaccounts: {
    id: nanoid(),
    label: 'Sub Accounts',
    uri: MetaDataRoutes.subaccounts,
    isMultipleActive: false,
    action_id: 'level2__config_metaata__businessIO',
    action_state: {},
    className: 'NavTabPassive',
    activeClassName: 'NavTabActive',
  },
  ro: {
    id: nanoid(),
    label: 'RO',
    uri: MetaDataRoutes.ros,
    isMultipleActive: false,
    action_id: 'level2__config_metaata__businessIO',
    action_state: {},
    className: 'NavTabPassive',
    activeClassName: 'NavTabActive',
  },
  invoice: {
    id: nanoid(),
    label: 'Invoice',
    uri: MetaDataRoutes.subros,
    isMultipleActive: false,
    action_id: 'level2__config_metaata__businessIO',
    action_state: {},
    className: 'NavTabPassive',
    activeClassName: 'NavTabActive',
  },
};

export const HarmonizeRoutes = {
  creativemapping: '/config/harmonize/creativemapping',
  strategymapping: '/config/harmonize/strategymapping'
}

export const configHarmonizeTabs = {
  strategymapping: {
    id: nanoid(),
    label: 'Strategy Mapping',
    uri: HarmonizeRoutes.strategymapping,
    isMultipleActive: false,
    action_id: 'level2__config_harmonize__strategyMapping',
    action_state: {},
    className: 'NavTabPassive',
    activeClassName: 'NavTabActive',
  },
  creativemapping: {
    id: nanoid(),
    label: 'Creative Mapping',
    uri: HarmonizeRoutes.creativemapping,
    isMultipleActive: false,
    action_id: 'level2__config_harmonize__creativeMapping',
    action_state: {},
    className: 'NavTabPassive',
    activeClassName: 'NavTabActive',
  },
};

export const CurrencyUnits = {
  INR: '₹',
  USD: '$',
  EUR: '€',
};

const revenueSources = {
  CPC:              { label: 'CPC',                 value: 'CPC' },
  CPA_TOTAL:        { label: 'CPA-Total',           value: 'CPA-Total' },
  CPA_PC:           { label: 'CPA-PC',              value: 'CPA-PC' },
  CPCV:             { label: 'CPCV',                value: 'CPCV' },
  CPCV_HYBRID:      { label: 'CPCV Hybrid (CV+YT)', value: 'CPCV Hybrid (CV+YT)' },
  CPLUS_MAXCPM:     { label: 'Cost Plus - max CPM', value: 'Cost Plus - max CPM' },
  CPLUS_MAXCPC:     { label: 'Cost Plus - max CPC', value: 'Cost Plus - max CPC' },
  CPLUS:            { label: 'Cost Plus',           value: 'Cost Plus' },
  vCPM:             { label: 'vCPM',                value: 'vCPM' },
  FIXED_COMMISSION: { label: 'Fixed Commission',    value: 'Fixed Commission' },
};

export const RevenueCombinations = {
  'DV3': [
    revenueSources.CPC,
    revenueSources.CPA_TOTAL,
    revenueSources.CPA_PC,
    revenueSources.CPCV,
    revenueSources.CPCV_HYBRID,
    revenueSources.CPLUS_MAXCPM,
    revenueSources.CPLUS_MAXCPC,
    revenueSources.CPLUS,
    revenueSources.vCPM,
  ],

  'DV3 Metric Based': [
    revenueSources.CPC,
    revenueSources.CPA_TOTAL,
    revenueSources.CPA_PC,
    revenueSources.CPCV,
    revenueSources.CPCV_HYBRID,
    revenueSources.vCPM,
    revenueSources.FIXED_COMMISSION,
  ],

  'CM': [
    revenueSources.CPC,
    revenueSources.CPA_TOTAL,
    revenueSources.CPA_PC,
    revenueSources.CPCV,
    revenueSources.CPCV_HYBRID,
    revenueSources.CPLUS_MAXCPM,
    revenueSources.CPLUS_MAXCPC,
    revenueSources.CPLUS,
    revenueSources.vCPM,
    revenueSources.FIXED_COMMISSION,
  ],

  'Client': [
    revenueSources.CPC,
    revenueSources.CPA_TOTAL,
    revenueSources.CPA_PC,
    revenueSources.CPCV,
    revenueSources.CPCV_HYBRID,
    revenueSources.vCPM,
  ],
};
