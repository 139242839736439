// import { current } from '@reduxjs/toolkit';
const verificationMulti = (verifierObject, value, anding, props) => {
  let verification = anding;
  verifierObject.forEach((element) => {
    if (anding) {
      verification =
        verification &&
        verificationSingle(element.type, element.verifier_prop, value, props);
    } else {
      verification =
        verification ||
        verificationSingle(element.type, element.verifier_prop, value, props);
    }
  });

  return verification;
};

export const verifierFunction = (verifierObject, valueObject) => {
  const errors = {};
  Object.keys(verifierObject).forEach((verifier) => {
    const verification = verifierObject[verifier].verifiers;
    const value = valueObject[verifier];
    // console.log('Element = ', verifier, current(valueObject), value);

    const verified = verificationMulti(
      verification,
      value,
      verifierObject[verifier].type === 'and',
      { verifier: verifierObject, value: valueObject }
    );

    errors[verifier] = {
      error: !verified,
      msg: verifierObject[verifier].errormsg,
    };
  });
  return errors;
};

const verificationSingle = (type, verification, value, props) => {
  let verified = false;
  switch (type) {
    case 'regex': {
      verified = new RegExp(verification).test(value);
      break;
    }
    case 'includes': {
      verified = verification.includes(value);
      break;
    }
    case 'non_empty': {
      // console.log('Value = ', type, verification, value, props);
      verified = (value?.length ?? 1) > 0;
      break;
    }
    case 'not_equal_to_props': {
      const newValue = props.value[verification.props_key];
      verified = newValue !== verification.value;
      break;
    }
    case 'non_empty_no_spaces': {
      verified = value.length > 0 && /^\S*$/.test(value);
      break;
    }

    default: {
      console.log(`${type} case is not supported`);
    }
  }
  return verified;
};
