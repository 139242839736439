export const StrategyMappingTableActionTypes = {

    SET_PAGED_DATA:             "SET_PAGED_DATA",
    PAGED_DATA_REQUEST:         "PAGED_DATA_REQUEST",
    PAGED_DATA_FAILURE:         "PAGED_DATA_FAILURE",

    SET_DATA_SIZE:              "SET_DATA_SIZE",
    
    ADD_DATA_FILTER:            "ADD_DATA_FILTER",
    INVALID_FILTER:             "INVALID_FILTER",
    DELETE_DATA_FILTER:         "DELETE_DATA_FITLER",

    NAVIGATE_NEXT_PAGE:         "NAVIGATE_NEXT_PAGE",
    NAVIGATE_PREV_PAGE:         "NAVIGATE_PREV_PAGE",

}