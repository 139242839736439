export const cronExpressionBuilder = (cronType, cronValue) => {
  switch (cronType) {
    case 'Once':
      return cronType;
    case 'Hourly':
      return '0 * * * *';
    case 'Daily': {
      if (typeof cronValue !== 'object') {
        cronValue = [{ label: '6:00 AM', value: '6:00 AM' }];
      }
      const selections = cronValue.map((element) => element.label);
      const activeHours = [];
      selections.forEach((element) => {
        const isPM = element.indexOf('AM') === -1;
        let hour = +element.split(':')[0];
        if (isPM) {
          hour += 12;
        }
        activeHours.push(hour);
      });
      const joins = activeHours.join(',');
      const result = joins === '' ? '1' : joins;
      return `0 ${result} * * *`;
    }
    default:
      return '';
  }
};
