import React from 'react'
import classes from './PageManager.module.scss'

function PageManager({
    pageNumber,
    pageSize,
    navigateNextPage,
    navigatePrevPage
}) {

    const firstRow = (pageNumber - 1) * 100 + 1
    const lastRow = firstRow - 1 + pageSize

    return (
        <div className={classes.PageManagerContainer}>
            <input 
                className = { classes.NavigateButton }
                type = "button"
                value = "<"
                onClick = { event => { event.preventDefault(); navigatePrevPage(); } }
            />
            <div>{firstRow}-{lastRow}</div>
            <input 
                className = { classes.NavigateButton }
                type = "button"
                value = ">"
                onClick = { event => { event.preventDefault(); navigateNextPage(); } }
            />
        </div>
    )
}

export default PageManager
