import React from 'react';
import { useDispatch } from 'react-redux';
import { getMasterListofDimensions } from '../slices/connectMix';
import Spinner from '../../shared/ui-components/spinner/spinner';
import MixZone from '../config_shared/MappingWindow/CompleteMixZone';
import classes from '../Config.module.scss';

export default function ConfigureDataStream(props) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMasterListofDimensions());
    console.log('fetching api');
  }, [dispatch]);
  const {
    data,
    connectData,
    onFormulaStringChange,
    onOptionClickHandler,
    onAliasChange,
    updateActive,
    unMapElement,
    onLeftOptionClickHandler,
    dropdownElements,
    onDropDownChange,
    onVerifyClick,
    verifyProps,
    onVerifyInputChange,
    verifybuttonDisabled,
    resetPreviousKeys,
    onVerifyFormulaRequest,
    outputResults,
    isFormVerifierLoading,
    formulaData,
    subscriptionEmail,
  } = props;

  const activeMasterHeader =
    connectData.mappingConfiguration.activeMasterHeader;
  const loading = data.status === 'loading' || connectData.status === 'loading';
  const activeData = data.metadataDetails.name;

  if (activeData === undefined) {
    return <div>Configuration not done correctly</div>;
  } else {
    const headers = Object.keys(
      data.headers.filter((element) => element.name === activeData)?.[0]
        ?.data?.[0]
    );
    const headerObjects = headers.map((element, id) => ({
      id: id,
      value: element,
    }));

    return (
      (activeData !== -1 && headers.length !== 0 && (
        <div
          className={[
            classes.ConfigureDataStreamDisplay,
            classes.ScrollBar,
          ].join(' ')}>
          {loading && <Spinner />}
          <MixZone
            connectData={connectData}
            onOptionClickHandler={onOptionClickHandler}
            headerObjects={headerObjects}
            onFormulaStringChange={onFormulaStringChange}
            onAliasChange={onAliasChange}
            updateActive={updateActive}
            unMapElement={unMapElement}
            onLeftOptionClickHandler={(value) =>
              onLeftOptionClickHandler(value, activeMasterHeader)
            }
            onDropDownChangeCallBack={onDropDownChange}
            dropdownElements={dropdownElements}
            onVerifyClick={onVerifyClick}
            verifyProps={verifyProps}
            onVerifyInputChange={onVerifyInputChange}
            verifybuttonDisabled={verifybuttonDisabled}
            resetPreviousKeys={resetPreviousKeys}
            onVerifyFormulaRequest={onVerifyFormulaRequest}
            outputResults={outputResults}
            masterListExternal={data.masterList}
            isFormVerifierLoading={isFormVerifierLoading}
            showOutput={
              formulaData.verificationRequested && !isFormVerifierLoading
            }
            isOutputValid={formulaData.backendSuccess}
            subscriptionEmail={subscriptionEmail}
          />
        </div>
      )) || (
        <p>
          Data Configuration not properly done. Please Follow the stages to
          configure it properly.
        </p>
      )
    );
  }
}
