import React from 'react';
import { accountSchema } from './functionalUnit/constants/accountsSchema';
import { useSelector, useDispatch } from 'react-redux';
import {
  metaDataAccounts,
  setEditMode,
} from './functionalUnit/slices/AccountSlice';

import {
  icons,
  IconGeneric,
} from '../../../../../../shared/ui-components/icons/Icons';
import DateRangePicker from '../../../../../../shared/ui-components/DateRangePicker/DateRangePicker';
// import Filter from '../../../../../../shared/ui-components/FilterBox/Filter';
import DataTable from '../../../../../slices/ui/DataTable/DataTable';
import Create from './createAccounts/Create';
import { addDays } from 'date-fns';
import classes from './Accounts.module.scss';

export default function Accounts(props) {
  
  const { dateValue, dateRangeHandler } = props;
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [dateRange, setDateRange] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  const accountData = useSelector(metaDataAccounts);
  const dispatch = useDispatch()
  const inEditMode = accountData.editMode;
  
  return inEditMode ? (
    <Create
      toggleEditMode={() => { dispatch(setEditMode(false)) }}
      inEditMode={inEditMode}
    />
  ) : (
    <React.Fragment>
      
      {/* Nav Bar */}
      <div className={classes.NewConfiguration}>
        
        {/* Create New Button */}
        <button
          className={classes.DVButtonGreen}
          onClick={ () => {dispatch(setEditMode(true))} }>
          Create New
        </button>
        <div className={classes.DateRangePicker}>
          <IconGeneric
            iconName={showCalendar ? icons.CROSS_ICON : icons.CALENDAR_ICON}
            iconProps={{
              onClick: () => setShowCalendar((prev) => !prev),
              className: showCalendar
                ? classes.CalendarButton_Checked
                : classes.CalendarButton,
            }}
          />
          {showCalendar && (
            <DateRangePicker
              className={classes.DateRangePickerCalendar}
              onDateChangeHandler={(item) =>
                (dateRangeHandler &&
                  dateRangeHandler({
                    value: [item.selection],
                    hideHandler: setShowCalendar,
                  })) ||
                setDateRange([item.selection])
              }
              dateRange={dateValue || dateRange}
            />
          )}
        </div>
      </div>

      <div className={classes.Datatable}>
        <DataTable
          schema={accountSchema}
          checkboxSelection={false}
          data={accountData.existing.existingAccounts.data}
        />
      </div>
    </React.Fragment>
  );
}
