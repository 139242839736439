import React from 'react';
import classes from './CodeEditor.module.scss';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import './override.scss';
import 'codemirror/mode/python/python';
import { Controlled as ControlledEditor } from 'react-codemirror2';
import { icons, IconGeneric } from '../../../shared/ui-components/icons/Icons';

export default function CodeEditor(props) {
  const {
    language,
    header,
    exitTrigger,
    formulaString,
    onFormulaStringChange,
    headers,
    onAliasChange,
    aliasName,
    triggerVerify,
  } = props;
  const [code, setCode] = React.useState('');
  const [alias, setAlias] = React.useState('');
  const [searchParam, setSearchParam] = React.useState('');

  const exitTriggerFunction = () => {
    if (exitTrigger) exitTrigger(false);
    else console.log('Close Button not configured');
  };

  React.useEffect(() => {
    const onEscape = (e) => {
      if (e.key === 'Escape') exitTriggerFunction();
    };

    window.addEventListener('keydown', onEscape);
    return () => window.removeEventListener('keydown', onEscape);
    // eslint-disable-next-line
  }, [exitTrigger]);

  return (
    <div className={classes.FormulaEditorToolBar}>
      <div className={classes.FormulaEditorLayout}>
        <div className={classes.HeaderName}>
          Formula for {header || 'Business IO'}
        </div>
        <div className={classes.CloseBox}>
          <IconGeneric
            iconName={icons.CROSS_ICON}
            iconProps={{
              className: classes.CloseIcon,
              onClick: exitTriggerFunction,
            }}
          />
        </div>
        <div className={classes.ToolbarRow}>
          <p className={classes.ToolbarRow__paraComponent}>Alias Name</p>
          <input
            className={classes.ToolbarRow__inputBox}
            value={aliasName || alias}
            onChange={(event) => {
              if (onAliasChange) {
                onAliasChange(event.target.value);
              } else setAlias(event.target.value);
            }}
          />
        </div>

        <div className={classes.ControlledEditor}>
          <ControlledEditor
            onBeforeChange={(_, __, value) => {
              if (onFormulaStringChange) onFormulaStringChange(value);
              else setCode(value);
            }}
            value={formulaString || code}
            className='code-mirror-wrapper'
            options={{
              lineWrapping: true,
              lint: true,
              mode: language,
              theme: 'material',
              lineNumbers: true,
              fontSize: '1rem',
            }}
          />
        </div>
        <div className={classes.AvailableOptions}>
          <h5 className={classes.AvailableOptions__Header}>
            Available Options
          </h5>
          <div className={classes.ConnectMix__ConfigData___SearchBox}>
            <input
              className={classes['ConnectMix__ConfigData___SearchBox--input']}
              placeholder={'Search Headers...'}
              value={searchParam}
              onChange={(event) => setSearchParam(event.target.value)}
            />
            <IconGeneric
              iconName={icons.SEARCH_ICON}
              iconProps={{
                className: classes['ConnectMix__ConfigData___SearchBox--icon'],
              }}
            />
          </div>
          <div
            className={[classes.MasterDimensionList, classes.ScrollBar].join(
              ' '
            )}>
            {headers
              .filter((ele) => {
                const lookup = searchParam.replace(/ |,/g, '');
                return (
                  lookup === '' ||
                  ele.value.toLowerCase().includes(searchParam.toLowerCase())
                );
              })
              .map((element) => (
                <p
                  key={element.id}
                  className={classes.ActiveHeaders}
                  onClick={() => {
                    const updatedString = `${formulaString || code}+data["${
                      element.value
                    }"]`;
                    if (onFormulaStringChange)
                      onFormulaStringChange(updatedString);
                    else setCode(updatedString);
                  }}>
                  {element.value}
                </p>
              ))}
          </div>
        </div>
        <div className={classes.BottomToolbar}>
          <button
            onClick={() => {
              // resetPreviousKeys();
              triggerVerify();
            }}
            className={classes.DVButtonGreen}>
            Verify
          </button>
          <div className={classes.InLineButtons}>
            <button
              className={classes.dataromaButton}
              onClick={() => {
                if (onFormulaStringChange)
                  onFormulaStringChange(formulaString.split('+')[0]);
                else setCode(formulaString.split('+')[0]);
                exitTriggerFunction();
              }}>
              Cancel
            </button>
            <button
              onClick={exitTriggerFunction}
              className={classes.DVButtonGreen}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
