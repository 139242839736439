import React from 'react';
import SubRO from '../SubRo/SubRo';
import Spinner from '../../../../../../shared/ui-components/spinner/spinner';
import { useSelector, useDispatch } from 'react-redux';
// import { roSliceData } from '../../functionalUnit/slices/ROS';
import {
  subRoSlice,
  updateShowDetails,
  updateArrayState,
  // getEntitiesFromAdvertiser,
  // getSubROIds,
  // getFormEntities,
  // getDeepM_Metrics,
  deleteSubRO,
  // draftSubRo,
} from '../../functionalUnit/slices/subROs';
import { formatDate } from '../../../../../../shared/frontendConstants/converters';

import classes from './SubroSegment.module.scss';

export default function SubroSegment(props) {
  
  const { ro_dates } = props;
  const dispatch = useDispatch();
  const subROs = useSelector(subRoSlice);

  const {
    paused,
    fillerFields,
  } = subROs;

  // React.useEffect(() => {
  //   // if (sub_ro_ids.length === 0) dispatch(getSubROIds());
  //   // if (kpi_1_metric.length === 0) dispatch(getFormEntities('kpi_1_metric'));
  //   // if (attribution_model.length === 0) dispatch(getFormEntities('attribution_model'));
  //   // if (kpi_window.length === 0) dispatch(getFormEntities('kpi_window'));
  //   // if (deepMetrics.length === 0) dispatch(getDeepM_Metrics());
  // }, [
  //   dispatch,
  //   sub_ro_campaigns,
  //   sub_ro_pixels,
  //   kpi_1_metric,
  //   attribution_model,
  //   kpi_window,
  //   deepMetrics,
  //   sub_ro_ids,
  //   sub_ro_ios
  // ]);

  const subRoUpdateRequestHandler = ({ index, value, key }) => {
    dispatch(
      updateArrayState({
        index,
        value,
        key,
        miscProps: { extremes: { ...ro_dates } },
      })
    );
  };

  const draftSubROHandler = ({ index, sub_ro_status }) => {
    // dispatch(draftSubRo({ index, sub_ro_status }));
  };

  return paused ? <Spinner /> : 
    <div className={[classes.SUBROSegment].join(' ')}>
      {fillerFields.length > 0 && <label className={classes.SubRoHeader}>Sub RO</label>}
      {fillerFields.map((element, index) => {
        
        const displayDetails = {
          'Name': element.sub_ro_name,
          'Start Date': formatDate(element.sub_ro_dates.from),
          'End Date': formatDate(element.sub_ro_dates.to),
          'Budget': element.sub_ro_budget,
          'Revenue Model': element.sub_ro_revenue_model_selected || '',
        };

        return (
          <SubRO
            key={element.id}
            isOpen={element.showDetails}
            setOpen={({ value, key }) => dispatch(updateShowDetails({ value, index, key })) }
            displayDetails={displayDetails}
            formSpecificEntries={element}
            subRoUpdateRequestHandler={({ key, value }) => subRoUpdateRequestHandler({ key, value, index }) }
            deleteAllowed={fillerFields.length > 1}
            deleteSubRO={() => dispatch(deleteSubRO(element.id))}
            draftSubROHandler={(sub_ro_status) => draftSubROHandler({ index, sub_ro_status }) }
          />
        );
      })}
    </div>
}
