import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../../shared/ui-components/spinner/spinner';
import classes from './activeView.module.scss';

import {
  updateActiveAdvertisers,
  updateViewType,
  updateNavigationLinks,
} from '../../../../slices/activeAdvertiserSlice';
import TopNavBars from '../topNavBar/topNavBarComponent';
import SideBar from '../sideBarComponents/sideBarComponents';
import Table from '../bioView/tableComponent/Table';
import {
  loadBusinessIOs,
  activeStatus,
  buttonsArray,
  activeViewType,
  sideBarButtons,
  deepDiveBusinessIO,
  activeAdv,
  activeIndex,
} from '../../../../slices/activeAdvertiserSlice';
import BusinessIOView from '../bioView/businessIOHeader';
import DeepView from '../../../deepDiveView/DeepView';
export default function AdvertiserView(props) {
  const status = useSelector(activeStatus);
  const sideButtons = useSelector(sideBarButtons);
  const buttons = useSelector(buttonsArray);
  const active = useSelector(activeViewType);
  const dispatch = useDispatch();
  const activeAdvertiser = useSelector(activeAdv);
  const activeBusinessIO = useSelector(activeIndex);
  let filterComponent = <BusinessIOView classes={classes} />;
  React.useEffect(() => {
    dispatch(updateActiveAdvertisers(props.match.params.advertiserId));
    dispatch(updateNavigationLinks(props.match.params.advertiserId));

    if (props.match.params.viewType === 'bio') {
      if (props.match.params.advertiserId !== activeAdvertiser) {
        dispatch(loadBusinessIOs(props.match.params.advertiserId));
      }
    } else if (props.match.params.viewType === 'deepdive') {
      dispatch(deepDiveBusinessIO(activeBusinessIO));
    }
  }, [dispatch, props.match.params, activeAdvertiser, activeBusinessIO]);
  if (props.match.params.viewType === 'deepdive') {
    filterComponent = <DeepView />;
  }
  console.log('Props = ', props.match.params);
  return (
    <>
      {status === 'loading' && <Spinner />}
      <TopNavBars
        buttons={buttons}
        active={active}
        dispatch={dispatch}
        updateViewType={updateViewType}
      />

      {
        <div className={classes.AnalyzeLayout}>
          <div className={classes.SideBar}>
            <SideBar classes={classes} sideButtons={sideButtons} />
          </div>
          <div className={classes.FilterSection}>{filterComponent}</div>
          <div className={classes.TableSection}>
            <Table />
          </div>
        </div>
      }
    </>
  );
}
