import React from 'react';
import classes from './MappingWindow.module.scss';
import { icons } from '../../../shared/ui-components/icons/Icons';
import ConfigHeaders from '../ConfigHeaders/ConfigHeaders';
import MixZone from './MappingWindow';

export default function CompleteMixZone(props) {
  const {
    connectData,
    onOptionClickHandler,
    headerObjects,
    onFormulaStringChange,
    onAliasChange,
    updateActive,
    unMapElement,
    onLeftOptionClickHandler,
    masterListExternal,
    dropdownElements,
    onDropDownChangeCallBack,
    onVerifyClick,
    verifyProps,
    onVerifyInputChange,
    verifybuttonDisabled,
    onVerifyFormulaRequest,
    isFormVerifierLoading,
    showOutput,
    outputResults,
    isOutputValid,
    resetPreviousKeys,
    subscriptionEmail,
  } = props;

  return (
    <div className={classes.CompleteMixZone}>

      <ConfigHeaders
        configDataHeader={'External Data'}
        iconName={icons.FINGER_PRINT_ICON}
        headers={headerObjects}
        onOptionClickHandler={
          onLeftOptionClickHandler ||
          (() => console.log('Function not configured to respond to clicks'))
        }
        />

      <MixZone
        headers={headerObjects}
        data={connectData}
        onFormulaStringChange={onFormulaStringChange}
        onAliasChange={onAliasChange}
        updateActive={updateActive}
        unMapElement={unMapElement}
        dropdownElements={dropdownElements}
        onDropDownChange={onDropDownChangeCallBack}
        onVerifyClick={onVerifyClick}
        verifyProps={verifyProps}
        onVerifyInputChange={onVerifyInputChange}
        buttonDisabled={verifybuttonDisabled}
        onVerifyFormulaRequest={onVerifyFormulaRequest}
        isFormVerifierLoading={isFormVerifierLoading}
        showOutput={showOutput}
        outputResults={outputResults}
        isOutputValid={isOutputValid}
        resetPreviousKeys={resetPreviousKeys}
        subscriptionEmail={subscriptionEmail}
      />

      <ConfigHeaders
        configDataHeader={'Master List'}
        iconName={icons.BI_NETWORK_ICON}
        iconProps={{ color: '#1C61D7' }}
        headers={masterListExternal || connectData.masterList}
        onOptionClickHandler={
          onOptionClickHandler || (() => console.log('Function not configured'))
        }
      />
  
    </div>
  );
}
