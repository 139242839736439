import { endpoints } from './constants';

export const getConfigBIOs = async () => {
  const headers = { Authorization: 'bearer hidden' };
  const data = await fetch(endpoints.GET_ACTIVE_BIO, { headers });
  return data.json();
};

export const getJsonStreams = async (payload) => {
  const data = await fetch(endpoints.GET_JSON_STREAMS, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return data.json();
};

export const getExistingAccounts = async () => {
  const data = await fetch(endpoints.GET_ACCOUNTS);
  return data.json();
};

export const duplicateBIOs = async (duplicatePayload) => {
  const data = await fetch(endpoints.ADD_BIO, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(duplicatePayload),
  });
  return data.json();
};

export const getMasterList = async () => {
  const data = await fetch(endpoints.GET_CONFIG_MASTER_LIST);
  return data.json();
};

export const getExistingNetwork = async () => {
  const data = await fetch(endpoints.GET_NETWORKS);
  return data.json();
};
