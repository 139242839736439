import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function OneButtonModal({
  showModal,
  showMessage,
  onCloseModal,
  modalHeaderMessage,
}) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={showModal || show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{modalHeaderMessage || 'Error'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showMessage || 'This operation is currently not supported.'}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              if (onCloseModal)
                onCloseModal({
                  booleanValue: false,
                  msg: showMessage,
                  continue: false,
                });
              else handleClose();
            }}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              if (onCloseModal)
                onCloseModal({
                  booleanValue: false,
                  msg: showMessage,
                  continue: true,
                });
              else handleClose();
            }}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
