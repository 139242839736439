import React from 'react';
// import InputBoxGroup from '../../shared/ui-components/InputBoxGroup/InputBoxGroup';
import InputBoxText from '../../../../shared/ui-components/InputBoxGroup/InputText/InputText';
import InputBool from '../../../../shared/ui-components/InputBoxGroup/InputBool/InputBool';
import classes from '../DataStream.module.scss';
import { useDispatch } from 'react-redux';

import { optionsMap } from '../constants/InputConstants';

export default function InputSpecificForms(props) {
  const { inputType, errors, data, updateFormData } = props;
  const dispatch = useDispatch();
  const formData = data.datastreamConfigSettings;
  switch (inputType) {
    case optionsMap['Google Sheets']:
      return (
        <>
          <InputBoxText
            inputHeaderName={'Sheet ID'}
            isRequired
            showHint
            value={formData.sheet_id}
            showError
            errorProps={{
              errors: errors,
              key: 'sheet_id',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'sheet_id', value: value }))
            }
          />
          <InputBoxText
            inputHeaderName={'Tab Name'}
            isRequired
            showHint
            value={formData.sheet_name}
            showError
            errorProps={{
              errors: errors,
              key: 'sheet_name',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'sheet_name', value: value }))
            }
          />
        </>
      );

    case optionsMap['Google Drive']:
      return (
        <>
          <InputBoxText
            inputHeaderName={'Sheet ID'}
            isRequired
            showHint
            value={formData.file_id}
            showError
            errorProps={{
              errors: errors,
              key: 'file_id',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'file_id', value: value }))
            }
          />
        </>
      );
    case optionsMap['Gmail Push']:
      return (
        <>
          <InputBoxText
            inputHeaderName={'Attachment Tab Name'}
            isRequired
            showHint
            value={formData.attachment_sheet_name}
            showError
            errorProps={{
              errors: errors,
              key: 'attachment_sheet_name',
            }}
            hintMessage={'If your file is CSV then you may write "csv".'}
            onChangeHandler={(value) =>
              dispatch(
                updateFormData({ key: 'attachment_sheet_name', value: value })
              )
            }
          />
        </>
      );
    case optionsMap['Gmail Pull']:
      return (
        <>
          <InputBoxText
            inputHeaderName={'Label'}
            isRequired
            showHint
            value={formData.label}
            showError
            errorProps={{
              errors: errors,
              key: 'label',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'label', value: value }))
            }
          />
          <div className={classes.Row}>
            <InputBoxText
              inputHeaderName={'Search String'}
              isRequired
              showHint
              value={formData.search_string}
              showError
              errorProps={{
                errors: errors,
                key: 'search_string',
              }}
              onChangeHandler={(value) =>
                dispatch(updateFormData({ key: 'search_string', value: value }))
              }
            />
            <InputBool
              value={formData.is_attachment}
              onChangeHandler={(value) =>
                dispatch(updateFormData({ key: 'is_attachment', value: value }))
              }
            />
          </div>
        </>
      );
    case optionsMap['Open Link']:
      return (
        <>
          <div className={classes.Row}>
            <InputBoxText
              inputHeaderName={'Link'}
              isRequired
              showHint
              value={formData.link}
              showError
              errorProps={{
                errors: errors,
                key: 'link',
              }}
              onChangeHandler={(value) =>
                dispatch(updateFormData({ key: 'link', value: value }))
              }
            />
          </div>
          <InputBoxText
            inputHeaderName={'Link Sheet Name'}
            isRequired
            showHint
            value={formData.link_sheet_name}
            showError
            errorProps={{
              errors: errors,
              key: 'link_sheet_name',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'link_sheet_name', value: value }))
            }
          />
        </>
      );
    case optionsMap['BigQuery']:
      return (
        <>
          <InputBoxText
            inputHeaderName={'Query'}
            isRequired
            showHint
            value={formData.query}
            showError
            errorProps={{
              errors: errors,
              key: 'query',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'query', value: value }))
            }
          />
        </>
      );
    case optionsMap['Branch']:
      return (
        <>
          <InputBoxText
            inputHeaderName={'App ID:'}
            isRequired
            showHint
            value={formData.app_id}
            showError
            errorProps={{
              errors: errors,
              key: 'app_id',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'app_id', value: value }))
            }
          />
          <InputBoxText
            inputHeaderName={'Access Token:'}
            isRequired
            showHint
            value={formData.access_token}
            showError
            errorProps={{
              errors: errors,
              key: 'access_token',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'access_token', value: value }))
            }
          />
          <InputBoxText
            inputHeaderName={'Branch Key:'}
            isRequired
            showHint
            value={formData.branch_key}
            showError
            errorProps={{
              errors: errors,
              key: 'branch_key',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'branch_key', value: value }))
            }
          />
          <InputBoxText
            inputHeaderName={'Branch Secret: '}
            isRequired
            showHint
            value={formData.branch_secret}
            showError
            errorProps={{
              errors: errors,
              key: 'branch_secret',
            }}
            onChangeHandler={(value) =>
              dispatch(updateFormData({ key: 'branch_secret', value: value }))
            }
          />
        </>
      );
    // case optionsMap['Google Cloud Storage']:
    //   return (
    //     <>
    //       <InputBoxText
    //         inputHeaderName={'Bucket Name: '}
    //         isRequired
    //         showHint
    //         value={formData.bucket}
    //         showError
    //         errorProps={{
    //           errors: errors,
    //           key: 'bucket',
    //         }}
    //         onChangeHandler={(value) =>
    //           dispatch(updateFormData({ key: 'bucket', value: value }))
    //         }
    //       />
    //       <InputBoxText
    //         inputHeaderName={'GCS File ID: '}
    //         isRequired
    //         showHint
    //         value={formData.gcs_file_id}
    //         showError
    //         errorProps={{
    //           errors: errors,
    //           key: 'gcs_file_id',
    //         }}
    //         onChangeHandler={(value) =>
    //           dispatch(updateFormData({ key: 'gcs_file_id', value: value }))
    //         }
    //       />
    //       <InputBoxText
    //         inputHeaderName={'GCS File Sheet Name: '}
    //         isRequired
    //         showHint
    //         value={formData.gcs_file_sheet_name}
    //         showError
    //         errorProps={{
    //           errors: errors,
    //           key: 'gcs_file_sheet_name',
    //         }}
    //         onChangeHandler={(value) =>
    //           dispatch(
    //             updateFormData({ key: 'gcs_file_sheet_name', value: value })
    //           )
    //         }
    //       />
    //       <InputBoxText
    //         inputHeaderName={'Branch Secret: '}
    //         isRequired
    //         showHint
    //         value={formData.branch_secret}
    //         showError
    //         errorProps={{
    //           errors: errors,
    //           key: 'branch_secret',
    //         }}
    //         onChangeHandler={(value) =>
    //           dispatch(updateFormData({ key: 'branch_secret', value: value }))
    //         }
    //       />
    //     </>
    //   );
    default:
      return <p>DataStream Configuration Not Supported.</p>;
  }
}
