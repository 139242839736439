import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBusinessIOData, getDeepDiveData } from '../apis/apiFunctions';
import { activenavBarButtons_Bio } from '../../shared/frontendConstants/constants';

const initialState = {
  status: 'idle',
  id: null,
  viewType: 'bio',
  viewsAvailable: activenavBarButtons_Bio,
  businessIOData: [],
  uri_address: '/view/advertisers/',
  isDetailedViewOpen: false,
  activeBIO_Index: null,
  sideBarButtons: ['Performance', 'Brand Lift Study', 'Post Eval Deck'],
  bioIndividualData: [],
  deepDiveFilters: {
    dimensions: [],
    filters: [
      { label: 'includes', value: 'includes' },
      { label: 'contains', value: 'contains' },
      { label: 'greater than', value: 'greater_than' },
      { label: 'less than', value: 'less_than' },
    ],
    dynamicData: [
      {
        dimension: null,
        filter: null,
        value: null,
        value_options: [],
      },
    ],
  },
};

export const loadBusinessIOs = createAsyncThunk(
  'activeAdvertiser/loadBusinessIOs',
  async (id) => {
    const response = await getBusinessIOData(id);
    return response;
  }
);

export const deepDiveBusinessIO = createAsyncThunk(
  'activeAdvertiser/deepDiveBusinessIO',
  async (id) => {
    const response = await getDeepDiveData(id);
    return response;
  }
);

export const activeAdvertiser = createSlice({
  name: 'activeAdvertiser',
  initialState,
  reducers: {
    updateActiveAdvertisers: (state, action) => {
      state.id = action.payload;
    },
    updateNavigationLinks: (state) => {
      const keys = Object.keys(state.viewsAvailable);

      keys.forEach((element) => {
        state.viewsAvailable[
          element
        ].uri = `${state.uri_address}${state.id}/${element}`;
      });
    },
    updateActiveBusinessIOIndex: (state, action) => {
      if (action.payload === state.activeBIO_Index && state.isDetailedViewOpen)
        state.isDetailedViewOpen = false;
      else {
        state.activeBIO_Index = action.payload;
        state.isDetailedViewOpen = true;
      }
    },
    updateViewType: (state, action) => {
      if (action.payload === 'bio' || action.payload === 'deepdive')
        state.viewType = action.payload;
    },

    updateSpecificFilterBox: (state, action) => {
      state.deepDiveFilters.dynamicData[action.payload.index][
        action.payload.keyValue
      ] = action.payload.data;
    },

    updateKeyValueSelections: (state, action) => {
      const newData = state.bioIndividualData.map((element) => ({
        label: element[action.payload.fromkey],
        value: element[action.payload.fromkey],
      }));

      state.deepDiveFilters.dynamicData[action.payload.index][
        action.payload.toKey
      ] = newData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadBusinessIOs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadBusinessIOs.fulfilled, (state, action) => {
        const data = action.payload.map((element) => ({
          ...element,
          id: element.business_io_id,
        }));
        state.businessIOData = data;
        state.status = 'idle';
      })
      .addCase(loadBusinessIOs.rejected, (state, action) => {
        console.log('Request rejected: ', action);
        state.status = 'idle';
      });
    builder
      .addCase(deepDiveBusinessIO.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deepDiveBusinessIO.fulfilled, (state, action) => {
        const data = action.payload.map((element) => ({
          ...element,
          id: element.li_id,
        }));
        state.bioIndividualData = data;

        if (data.length > 0) {
          state.deepDiveFilters.dimensions = Object.keys(data[0]).map(
            (element) => ({
              value: element,
              label: element,
            })
          );
        }

        state.status = 'idle';
      })
      .addCase(deepDiveBusinessIO.rejected, (state, action) => {
        console.log('Request rejected ', action);
        state.status = 'idle';
      });
  },
});

export const activeAdv = (state) => state.activeAdvertiser.id;
export const buttonsArray = (state) => state.activeAdvertiser.viewsAvailable;
export const activeViewType = (state) => state.activeAdvertiser.viewType;
export const activeStatus = (state) => state.activeAdvertiser.status;
export const businessIOData = (state) => state.activeAdvertiser.businessIOData;
export const deepDiveData = (state) => state.activeAdvertiser.bioIndividualData;
export const deepDiveFilters = (state) =>
  state.activeAdvertiser.deepDiveFilters;

export const isBusinessIOClicked = (state) =>
  state.activeAdvertiser.isDetailedViewOpen;
export const activeIndex = (state) => state.activeAdvertiser.activeBIO_Index;
export const sideBarButtons = (state) => state.activeAdvertiser.sideBarButtons;

export const {
  updateActiveAdvertisers,
  updateViewType,
  updateNavigationLinks,
  updateActiveBusinessIOIndex,
  updateSpecificFilterBox,
  updateKeyValueSelections,
} = activeAdvertiser.actions;

export default activeAdvertiser.reducer;
