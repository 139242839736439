import React from 'react';
import classes from './Frequency.module.scss';
// import InputBoxText from '../../../shared/ui-components/InputBoxGroup/InputText/InputText';
import { updateFormData } from '../../../slices/connectMix';
import LabelSelect from '../../../../shared/ui-components/LabelSelect/LabelSelect';
import LabelSelectMulti from '../../../../shared/ui-components/LabelSelect/LabelSelectMulti';
import { cronExpressionBuilder } from '../../../slices/compoundFunctions/cronExpressionBuilder';

import { useDispatch } from 'react-redux';

const availableFrequencyOptions = [
  { value: 0, label: 'Once' },
  { value: 1, label: 'Hourly' },
  { value: 2, label: 'Daily' },
  // { value: 4, label: 'Custom' },
];

// const cronTabExpressionDefaultValues = {
//   Once: 'handle_once',
//   Hourly: '0 * * * *',
//   Daily: '0 6 * * *',
//   Weekly: '0 0 * * 0',
// };
const dailySelectOptions = [
  { value: 0, label: '12:00 AM' },
  { value: 1, label: '1:00 AM' },
  { value: 2, label: '2:00 AM' },
  { value: 3, label: '3:00 AM' },
  { value: 4, label: '4:00 AM' },
  { value: 5, label: '5:00 AM' },
  { value: 6, label: '6:00 AM' },
  { value: 7, label: '7:00 AM' },
  { value: 8, label: '8:00 AM' },
  { value: 9, label: '9:00 AM' },
  { value: 10, label: '10:00 AM' },
  { value: 11, label: '11:00 AM' },
  { value: 12, label: '12:00 PM' },
  { value: 13, label: '1:00 PM' },
  { value: 14, label: '2:00 PM' },
  { value: 15, label: '3:00 PM' },
  { value: 16, label: '4:00 PM' },
  { value: 17, label: '5:00 PM' },
  { value: 18, label: '6:00 PM' },
  { value: 19, label: '7:00 PM' },
  { value: 20, label: '8:00 PM' },
  { value: 21, label: '9:00 PM' },
  { value: 22, label: '10:00 PM' },
  { value: 23, label: '11:00 PM' },
];
export default function Frequency() {
  const [frequencyType, setFrequencyType] = React.useState([
    availableFrequencyOptions[0],
  ]);
  // const [dailyHours, setDailyHours] = React.useState([]);
  // const [cronTabExpression, setCronTabExpression] = React.useState('');

  const dispatch = useDispatch();
  // const data = useSelector(connectMixData);

  // const formData = data.datastreamConfigSettings;
  // const errors_connectMix = data.errors;

  const detailComponent = {
    Once: null,
    Hourly: null,
    Daily: (
      <LabelSelectMulti
        label={'Hour'}
        selected={[dailySelectOptions[6]]}
        options={dailySelectOptions}
        onChangeHandler={(value) => {
          const cronData = cronExpressionBuilder('Daily', value || []);
          dispatch(updateFormData({ key: 'schedule', value: cronData }));
          // setDailyHours(value);
        }}
      />
    ),
    // Custom: (
    //   <InputBoxText
    //     inputHeaderName={'Frequency'}
    //     showHelpHyperLink
    //     hyperlink={'https://crontab.guru/'}
    //     value={formData.schedule}
    //     showError
    //     errorProps={{ errors: errors_connectMix, key: 'schedule' }}
    //     onChangeHandler={(value) =>
    //       dispatch(updateFormData({ key: 'schedule', value: value }))
    //     }
    //   />
    // ),
  };
  return (
    <div className={classes.FrequencyScheduler}>
      <LabelSelect
        label={'Schedule Frequency'}
        options={availableFrequencyOptions}
        value={frequencyType}
        onChangeHandler={(value) => {
          const cronData = cronExpressionBuilder(value.label, value.value);
          dispatch(updateFormData({ key: 'schedule', value: cronData }));
          setFrequencyType(value);
        }}
      />
      {detailComponent[frequencyType.label]}
    </div>
  );
}
