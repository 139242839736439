import React from 'react';
import NavBarButtons from '../../../shared/ui-components/nav-bar/verticalNavBar';
import { viewNavBarButtons } from '../../../shared/frontendConstants/constants';
import { useSelector, useDispatch } from 'react-redux';
import {
  activeViewTab,
  updateActiveViewSubTab,
} from '../../../shared/slices/navBarSlice';
export default function NavBars(props) {
  const viewActive = useSelector(activeViewTab);
  const dispatch = useDispatch();

  return (
    <>
      <NavBarButtons
        buttonsArray={viewNavBarButtons}
        activeButton={viewActive}
        buttonClickHandler={(buttonKey) =>
          dispatch(updateActiveViewSubTab(buttonKey))
        }
      />
    </>
  );
}
