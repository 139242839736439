import axios from 'axios'
import React from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux';
import SubLinks from './subComponents/SubLinks/SubLinks';
import { connectedAccount } from '../../../../../Accounts/functionalUnit/slices/ConnectedAccountSlice';
import { 
  subAccounts, 
  unSetCreateNew, 
  resetAccountDetails,
  setSubAccountManager, 
  setDVAdvertisers,
  setSubAccountName, 
  setDashboards,
  saveSubAccount,
  editSubAccount,
} from '../../../../functionalUnit/slices/subAccounts';
import { base_uri } from '../../../../../../../../shared/backend/apiEndpoints';
import { MetaDataRoutes } from '../../../../../../../../shared/frontendConstants/constants';

const UseExisting = (props) => {
  
  const { classes } = props;
  
  const dispatch = useDispatch()
  const history = useHistory()
  const chainStateStore = useSelector(connectedAccount)
  const subAccountStore = useSelector(subAccounts);
  
  const subAccount_name = subAccountStore.subAccountDetails.sub_account_name;
  const subAccount_manager = subAccountStore.subAccountDetails.sub_account_manager;
  const subAccount_advertisers = subAccountStore.subAccountDetails.dv3_advertisers;
  const subAccount_dasboards = subAccountStore.subAccountDetails.dashboards;

  // Managers and Advertisers Dropdown values
  const [ advertisers, setAdvertisers ] = React.useState([])
  const [ managers, setManagers ] = React.useState([])
  
  // Dashbaord Value Management
  const [ subLinks, setSubLinks ] = React.useState(subAccount_dasboards)
  const setEmptyDashboard = () => ({
    id: Math.floor((Math.random() * 100) + 1),
    name: '',
    link: '',
    type: 'Internal'
  })

  React.useEffect(() => {
    
    // Async Fetch and Set Managers
    const fetchManagers = async () => {
      const request_configuration = {
        url:    `${base_uri}/list/managers`,
        method: 'GET',
      };
      const response = await axios(request_configuration);
      const managers = response.data;
      setManagers(managers);
    }
    
    // Async Fetch and Set Advertisers
    const fetchAdvertisers = async () => {
      const request_configuration = {
        url:    `${base_uri}/list/advertisers`,
        method: 'GET',
      };
      const response = await axios(request_configuration);
      const advertisers = response.data;
      setAdvertisers(advertisers);
    }

    fetchManagers();
    fetchAdvertisers();

  }, [])

  // Form Handlers
  const handleManagerSelect = (managerValue, actionMeta) => {
    const manager = managerValue.value;
    dispatch(setSubAccountManager(manager))
  }
  const handleAdvertiserSelect = (advertiserValue, actionMeta) => {
    switch(actionMeta.action) {
      case 'remove-value':
      case 'pop-value':     break;
      case 'clear':         advertiserValue = []; break;
      default: break;
    }
    const newAdvertisers = advertiserValue.map(advertiser => advertiser.value)
    dispatch(setDVAdvertisers(newAdvertisers))
  }
  const handleSubAccountNameChange = event => {
    event.preventDefault();
    const subAccountName = event.target.value;
    dispatch(setSubAccountName(subAccountName))
  }

  // Button Click Handlers
  const onGoBack = event => {
    event.preventDefault();
    dispatch(unSetCreateNew());
    dispatch(resetAccountDetails());
  }
  const onSaveSubAccount = event => {
    event.preventDefault(); 
    dispatch(setDashboards(subLinks));
    dispatch(saveSubAccount());
  }
  const onEditSubAccount = event => {
    event.preventDefault();
    dispatch(setDashboards(subLinks));
    dispatch(editSubAccount(subAccountStore.subAccountId))
    history.push({
      pathname: MetaDataRoutes.ros,
      state: { justPreserve: true },
    });
  }

  return (
    <React.Fragment>
      
      {/* Select Account  */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Select Account:
        </label>
        <Select
          className = {classes.FormComponent_LabelInput__ReactSelectLabel}
          defaultValue = {{ label: chainStateStore.accountDetails.account_name, id: chainStateStore.accountDetails.id }}
          options = {[ { label: chainStateStore.accountDetails.account_name, id: chainStateStore.accountDetails.id } ]}
        />
      </div>
      
      {/* SubAccount Name  */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Sub-Account:
        </label>
        <input
          value={subAccount_name}
          className={classes.FormComponent_LabelInput__InputTextField}
          onChange={handleSubAccountNameChange}
        />
      </div>

      {/* Select Manager */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Manager:
        </label>
        <Select
          value={{ label: subAccount_manager, value: subAccount_manager }}
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={ managers.map(manager => ({ label: manager, value: manager })) }
          onChange={handleManagerSelect}
        />
      </div>

      {/* MultiSelect Advertisers */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Advertiser:
        </label>
        <Select
          isMulti
          onChange={handleAdvertiserSelect}
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={advertisers.map(advertiser => ({ label: `${advertiser.Advertiser} (${advertiser.Advertiser_ID})`, value: advertiser.Advertiser_ID })) }
          value={subAccount_advertisers.map(advertiser => ({ label: advertiser, value: advertiser }))}
        />
      </div>
      
      {/* Dashboard Addition / Removal */}
      <SubLinks 
        classes = {classes} 
        subLinks = {subLinks}
        setSubLinks = {setSubLinks} 
        setInitialSubLink = {setEmptyDashboard}
      />

      <div className={classes.ButtonRow_CreateSpecific}>
        <button 
          className={classes.clickButton} 
          onClick={onGoBack}>
          Go Back
        </button>

        {subAccountStore.subAccountId === '' ? 
          <button
            className={classes.clickButton }
            onClick={onSaveSubAccount}>
              Save Sub-Account
          </button> 
        : 
          <button
            className={classes.clickButton}
            onClick={onEditSubAccount}>
              Save Sub-Account
          </button> 
        }
      </div>

    </React.Fragment>
  );
};

export default UseExisting;
