import {
  BsFillPeopleFill,
  BsPencilSquare,
  BsSearch,
  BsCheck,
  BsCalendar,
} from 'react-icons/bs';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { FaNetworkWired, FaFilter, FaEye } from 'react-icons/fa';
import { FiFilter, FiDownload, FiSettings } from 'react-icons/fi';
import {
  IoWarningOutline,
  IoRemoveCircle,
  IoRemoveOutline,
} from 'react-icons/io5';
import {
  BiSortDown,
  BiSortUp,
  BiCloudUpload,
  BiNetworkChart,
  BiDownload,
} from 'react-icons/bi';
import { TiDocumentText } from 'react-icons/ti';
import {
  MdSort,
  MdPageview,
  MdFingerprint,
  MdFormatListNumbered,
  MdNotInterested,
} from 'react-icons/md';
import { ImCross, ImListNumbered, ImAttachment } from 'react-icons/im';
import {
  AiOutlineCopy,
  AiOutlineSearch,
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlinePlus,
  AiFillDelete,
} from 'react-icons/ai';
import { GiHelp, GiResonance } from 'react-icons/gi';
import { GrDatabase, GrUnlink, GrConfigure } from 'react-icons/gr';
import { VscSymbolString, VscBook } from 'react-icons/vsc';

import React from 'react';
import classes from './Icons.module.scss';

export const icons = {
  PEOPLE_ICON: 'PEOPLE_ICON',
  PEOPLE_ICON_MUI: 'PEOPLE_ICON_MUI',
  BILLING_ICON_MUI: 'BILLING_ICON_MUI',
  NETWORK_ICON: 'NETWORK_ICON',
  FILTER_ICON: 'FILTER_ICON',
  FILL_FILTER: 'FILL_FILTER',
  EXPORT_ICON: 'EXPORT_ICON',
  WARNING_ICON: 'WARNING_ICON',
  BI_SORT_UP: 'BI_SORT_UP',
  BI_SORT_DOWN: 'BI_SORT_DOWN',
  MD_SORT: 'MD_SORT',
  EDIT_ICON: 'EDIT_ICON',
  CROSS_ICON: 'CROSS_ICON',
  SETTINGS_ICON: 'SETTINGS_ICON',
  COPY_ICON: 'COPY_ICON',
  HELP_ICON: 'HELP_ICON',
  UPLOAD_ICON: 'UPLOAD_ICON',
  VIEW_ICON: 'VIEW_ICON',
  DATABASE_ICON_TYPE_1: 'DATABASE_ICON_TYPE_1',
  FINGER_PRINT_ICON: 'FINGER_PRINT_ICON',
  BI_NETWORK_ICON: 'BI_NETWORK_ICON',
  SEARCH_ICON: 'SEARCH_ICON',
  METRIC_ICON: 'METRIC_ICON',
  DIMENSION_ICON: 'DIMENSION_ICON',
  BS_SEARCH_ICON: 'BS_SEARCH_ICON',
  DOCUMENT_ICON: 'DOCUMENT_ICON',
  DOWNLOAD_ICON: 'DOWNLOAD_ICON',
  UNLINK_ICON: 'UNLINK_ICON',
  DOWN_ICON: 'DOWN_ICON',
  CONFIGURE: 'CONFIGURE',
  FORM_INPUT: 'FORM_INPUT',
  UP_ICON: 'UP_ICON',
  ATTACH_ICON: 'ATTACH_ICON',
  NOT_ALLOWED: 'NOT_ALLOWED',
  BOOK_ICON: 'BOOK_ICON',
  CHECK_ICON: 'CHECK_ICON',
  RESONATE_ICON: 'RESONATE_ICON',
  CALENDAR_ICON: 'CALENDAR_ICON',
  ADD_ICON: 'ADD_ICON',
  REMOVE_ICON: 'REMOVE_ICON',
  REMOVE_ICON_OUTLINE: 'REMOVE_ICON_OUTLINE',
  VIEW_ICON_EYE: 'VIEW_ICON_EYE',
  DELETE_ICON: 'DELETE_ICON',
};

const reactIcons = {
  PEOPLE_ICON: BsFillPeopleFill,
  PEOPLE_ICON_MUI: PeopleIcon,
  BILLING_ICON_MUI: ReceiptIcon,
  NETWORK_ICON: FaNetworkWired,
  FILTER_ICON: FaFilter,
  FILL_FILTER: FiFilter,
  EXPORT_ICON: FiDownload,
  WARNING_ICON: IoWarningOutline,
  BI_SORT_UP: BiSortUp,
  BI_SORT_DOWN: BiSortDown,
  MD_SORT: MdSort,
  EDIT_ICON: BsPencilSquare,
  CROSS_ICON: ImCross,
  SETTINGS_ICON: FiSettings,
  COPY_ICON: AiOutlineCopy,
  HELP_ICON: GiHelp,
  UPLOAD_ICON: BiCloudUpload,
  VIEW_ICON: MdPageview,
  DATABASE_ICON_TYPE_1: GrDatabase,
  FINGER_PRINT_ICON: MdFingerprint,
  BI_NETWORK_ICON: BiNetworkChart,
  SEARCH_ICON: AiOutlineSearch,
  METRIC_ICON: ImListNumbered,
  DIMENSION_ICON: VscSymbolString,
  BS_SEARCH_ICON: BsSearch,
  DOCUMENT_ICON: TiDocumentText,
  DOWNLOAD_ICON: BiDownload,
  UNLINK_ICON: GrUnlink,
  DOWN_ICON: AiFillCaretDown,
  UP_ICON: AiFillCaretUp,
  CONFIGURE: GrConfigure,
  FORM_INPUT: MdFormatListNumbered,
  ATTACH_ICON: ImAttachment,
  NOT_ALLOWED: MdNotInterested,
  BOOK_ICON: VscBook,
  CHECK_ICON: BsCheck,
  RESONATE_ICON: GiResonance,
  CALENDAR_ICON: BsCalendar,
  ADD_ICON: AiOutlinePlus,
  REMOVE_ICON: IoRemoveCircle,
  REMOVE_ICON_OUTLINE: IoRemoveOutline,
  VIEW_ICON_EYE: FaEye,
  DELETE_ICON: AiFillDelete,
};

const Icon = (iconName, iconProps = {}) => {
  const Component = reactIcons[iconName];
  return <Component {...iconProps} />;
};

export const IconComponent = (props) => {
  const { iconprops } = props;
  const { iconName, iconProps, isHoverRequired, message, ...rest } = iconprops;

  const Component = reactIcons[iconName];
  const [hover, setHover] = React.useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return (
    <div
      className={classes.IconSpace}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}>
      <Component {...rest} />
      {isHoverRequired && hover && message && (
        <p className={classes.IconMessage}>{message}</p>
      )}
    </div>
  );
};

export const IconGeneric = (props) => {
  const Component = reactIcons[props.iconName];
  return <Component {...props.iconProps} />;
};

export const IconButton = (props) => {
  let propsClassName = props.classNames || [];
  if (typeof props.classNames === 'string') {
    propsClassName = [props.classNames];
  }
  const classNameString = [classes.Row, ...propsClassName].join(' ');
  return (
    <div className={classNameString}>
      <IconGeneric iconName={props.iconName} />
      <button className={classes.dataromaButton}>
        {props.Text || 'Button'}
      </button>
    </div>
  );
};

export const IconButtonPost = (props) => {
  let propsClassName = props.classNames || [];
  if (typeof props.classNames === 'string') {
    propsClassName = [props.classNames];
  }
  const classNameString = [classes.Row, ...propsClassName].join(' ');
  return (
    <div className={classNameString}>
      <button className={classes.dataromaButton}>
        {props.Text || 'Button'}
      </button>
      <IconGeneric iconName={props.iconName} />
    </div>
  );
};

export default Icon;
