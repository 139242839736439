import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAdvertisersData } from '../apis/apiFunctions';
import { advertiserWidgetConfig } from '../constants/advertiserWidget';
import { advertiserTableConfig } from '../constants/advertiserTableSchema';
const initialState = {
  advertiserWidget: advertiserWidgetConfig,
  advertiserTable: {
    data: [],
    schema: advertiserTableConfig,
  },
  status: 'idle',
};
export const loadAdvertisers = createAsyncThunk(
  'advertiserSlice/loadAdvertisers',
  async () => {
    const response = await getAdvertisersData();
    return response;
  }
);
export const advertiserSlice = createSlice({
  name: 'advertiserSlice',
  initialState,
  reducers: {
    updateDropDownState: (state, action) => {
      state.advertiserWidget.rowElements[
        action.payload.key
      ].props.directProps.value = action.payload.value;
    },
    updateToggleState: (state, action) => {
      state.advertiserWidget.rowElements[action.payload.key].value =
        !state.advertiserWidget.rowElements[action.payload.key].value;
    },
    updateDateState: (state, action) => {
      state.advertiserWidget.rowElements[action.payload.key].dates =
        action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAdvertisers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadAdvertisers.fulfilled, (state, action) => {
        state.advertiserWidget.rowElements.advertisers.props.directProps.options =
          action.payload;
        state.advertiserWidget.rowElements.accounts.props.directProps.options =
          action.payload.map((element) => ({
            label: element.account,
            value: element.account_id,
          }));

        state.advertiserTable.data = action.payload.map((element) => ({
          ...element,
          id: element.value,
        }));

        state.status = 'idle';
      })
      .addCase(loadAdvertisers.rejected, (state, action) => {
        console.log('Error occured: ', action);
        state.status = 'idle';
      });
  },
});

export const { updateDropDownState, updateToggleState, updateDateState } =
  advertiserSlice.actions;
export const advertiserFilters = (state) => state.advertiserFilters;

export default advertiserSlice.reducer;
