import React from 'react'
import classes from './FilterElement.module.scss'

function FilterElement({
    filter,
    deleteFilter
}) {
    
    return (
        <div className={classes.FilterElementContainer}>
            <div className={classes.FilterElementContent}>
                <div>
                    <b>{filter.name}</b> {filter.operator} <b>{filter.value}</b>
                </div>
            </div>
            <input 
                className = { classes.DeleteFilterButton }
                type = "button"
                value = "X"
                onClick = { event => { event.preventDefault(); deleteFilter(filter.id) } }
            />
        </div>
    )
}

export default FilterElement
