import React from 'react';
import classes from './EditExisting.module.scss';
import MixZone from '../../../config_shared/MappingWindow/CompleteMixZone';
import DataStreamForm from '../../../config_shared/DataStreamForm/DataStreamForm';
import ProgressBar from '../../../config_shared/ProgressBar/ProgressBar';

import { useSelector, useDispatch } from 'react-redux';
import {
  getMasterListofDimensions,
  connectMixData,
} from '../../../slices/connectMix';
import {
  updateFormula,
  updateAlias,
  updateActiveSelector,
  removeSpecificKey,
  updateMasterListMapping,
  updateUserMapping,
  existingDStreams,
  updateExisting,
  updateModalError,
  updateLastActiveHeaders,
  updateFormData,
  updateInEditMode,
  resetInitState,
  getExisting,
  preserveOriginalMapping,
  resetMappingConfiguration,
} from '../../../slices/editExistingSlice';
import { dataStreams } from '../../../slices/dataStream';
import Spinner from '../../../../shared/ui-components/spinner/spinner';
import {
  setRequiredData,
  formulaes,
  updateTextField,
  verifyFormulaes,
  resetBackendVerification,
} from '../../../slices/formulaVerifierSlice.js';
import { detectFormulaPairs } from '../../compoundFunctions/formulaVerifier/extractFormulaElements';

export default function EditExisting() {
  const dispatch = useDispatch();
  const data = useSelector(connectMixData);
  const editData = useSelector(existingDStreams);
  const subscriptionEmail = editData.created_email ?? 'n';
  const existingDataStreams = useSelector(dataStreams);
  const formulaData = useSelector(formulaes);
  const formulaToBeSubmitted = formulaData.allGood;

  const dropdownElements = existingDataStreams.datastream_id_list;

  const onDropDownChange = ({ datastream_id, preserveProps }) => {
    if (preserveProps) {
      const { preserveOriginal, originalPayload } = preserveProps;
      if (preserveOriginal) preserveMapping(originalPayload);
    }
    if (datastream_id === 'Original') {
      dispatch(resetMappingConfiguration(editData.originalMapping));
    } else {
      dispatch(getExisting({ datastream_id, updateMappingOnly: true }));
    }
  };

  const preserveMapping = (payload) => {
    dispatch(preserveOriginalMapping(payload));
  };

  const isLoading = editData.status === 'loading';
  const isFormVerifierLoading = formulaData.status === 'loading';

  //Progress Bar
  const stages = editData.stages;
  const buttonDisabled = !editData?.errors?.isFormValid;
  const modalErrors = editData.modalErrors;
  const datastream_id = editData.datastream_id;

  const updateModalMessages = (payload) => {
    dispatch(updateModalError(payload));
  };

  const activeMasterHeader = editData.mappingConfiguration.activeMasterHeader;

  const onFormulaStringChange = React.useCallback(
    (value) => {
      dispatch(
        updateFormula({
          header: editData.mappingConfiguration.activeMasterHeader,
          value: value,
        })
      );
    },
    [dispatch, editData.mappingConfiguration.activeMasterHeader]
  );

  const onAliasChange = React.useCallback(
    (value) => {
      dispatch(
        updateAlias({
          header: editData.mappingConfiguration.activeMasterHeader,
          value: value,
        })
      );
    },
    [dispatch, editData.mappingConfiguration.activeMasterHeader]
  );

  const updateActive = React.useCallback(
    (value) => {
      dispatch(updateActiveSelector(value));
    },
    [dispatch]
  );

  const unMapElement = React.useCallback(
    (value) => {
      dispatch(removeSpecificKey(value));
    },
    [dispatch]
  );

  const onOptionClickHandler = React.useCallback(
    (value) => {
      dispatch(updateMasterListMapping({ masterKey: value }));
    },
    [dispatch]
  );

  const onLeftOptionClickHandler = React.useCallback(
    (value) => {
      dispatch(
        updateUserMapping({
          masterListHeader:
            activeMasterHeader === null ? 'UNDEFINED' : activeMasterHeader,
          formulaString: `data["${value}"]`,
          alias: value,
        })
      );
    },
    [dispatch, activeMasterHeader]
  );

  const onFormulaVerificationRequest = (formulaString) => {
    const results = detectFormulaPairs(formulaString);
    dispatch(setRequiredData(results));
  };

  const onFormulaUserInput = (payload) => {
    dispatch(updateTextField(payload));
  };

  const onVerifyFormulaRequest = (requiredFormula) => {
    const payload = {
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
      operationType: 'validatePandasFormula',
      operationProps: {
        data: formulaData.data,
        formulaStrings: {
          [requiredFormula]:
            editData.mappingConfiguration.mappings[requiredFormula],
        },
      },
    };
    dispatch(verifyFormulaes(payload));
  };

  const resetPreviousFormulaKeys = () => {
    dispatch(resetBackendVerification());
  };

  const onCancelTrigger = () => {
    dispatch(updateInEditMode(false));
    dispatch(resetInitState());
  };

  //Custom DropZone

  const uiStages = [
    <DataStreamForm
      data={editData}
      existingData={editData}
      streamName={editData.datastream_id}
      created={true}
      errors_dstream={editData.errors}
      updateDataStreamName={() => console.log('Update not allowed')}
      updateFormData={updateFormData}
    />,
    <MixZone
      connectData={editData}
      masterListExternal={data.masterList}
      onOptionClickHandler={onOptionClickHandler}
      headerObjects={editData.headersList}
      onFormulaStringChange={onFormulaStringChange}
      onAliasChange={onAliasChange}
      updateActive={updateActive}
      unMapElement={unMapElement}
      onLeftOptionClickHandler={onLeftOptionClickHandler}
      dropdownElements={dropdownElements}
      onDropDownChangeCallBack={onDropDownChange}
      onVerifyClick={onFormulaVerificationRequest}
      verifyProps={formulaData}
      onVerifyInputChange={onFormulaUserInput}
      verifybuttonDisabled={formulaToBeSubmitted || true}
      onVerifyFormulaRequest={onVerifyFormulaRequest}
      isFormVerifierLoading={isFormVerifierLoading}
      showOutput={formulaData.verificationRequested && !isFormVerifierLoading}
      outputResults={formulaData.backendOutput}
      isOutputValid={formulaData.backendSuccess}
      resetPreviousKeys={resetPreviousFormulaKeys}
      subscriptionEmail={subscriptionEmail}
    />,
  ];

  React.useEffect(() => {
    dispatch(getMasterListofDimensions());
  }, [dispatch]);
  return (
    <div className={classes.EditExisting}>
      {isLoading && <Spinner />}
      <div
        className={[classes.EditExisting_ContentBox, classes.ScrollBar].join(
          ' '
        )}>
        <div
          className={
            editData.stages.lastActive === 0
              ? [classes.EditExisting_Body__Container, classes.ScrollBar].join(
                  ' '
                )
              : classes.EditExisting_FlexBox
          }>
          {uiStages[editData.stages.lastActive]}
        </div>
      </div>
      <div className={classes.ProgressBar}>
        <ProgressBar
          onCancelTrigger={onCancelTrigger}
          stages={stages}
          buttonDisabled={buttonDisabled}
          updateModalError={updateModalError}
          updateMapping={updateExisting}
          modalErrors={modalErrors}
          created={true}
          stagesUpdateText={'Update'}
          datastream_id={datastream_id}
          mappings={editData.mappingConfiguration.mappings}
          updateModalMessages={updateModalMessages}
          isValidForSubmission={editData.errors.isFormValid}
          createDataStream={() => console.log('function not required')}
          resetDataFetch={() => console.log('resetDataFetch: function not required') }
          progressBarCustomSuccessMessage={
            'Please note, the updated mapping instructions will be applicable to all the historical data as well, so use formulas accordingly and carefully. If you have done it already, click "Okay" otherwise click "Cancel"'
          }
          modalHeaderMessage={'Confirm Updation'}
          updateLastActiveHeaders={updateLastActiveHeaders}
          updateViewType={() => console.log('updateViewType: function not required') }
        />
      </div>
    </div>
  );
}

/*
<div
        className={
          editData.stages.lastActive === 0
            ? [classes.EditExisting_Body__Container, classes.ScrollBar].join(
                ' '
              )
            : classes.EditExisting_FlexBox
        }>
        {uiStages[editData.stages.lastActive]}
      </div>
**/
