import BusinessIOButton from '../advertisers/components/BusinessIOButton';
function getFlightDates(params) {
  return `${
    new Date(params.getValue(params.id, 'start_date')).toDateString() || ''
  } - ${new Date(params.getValue(params.id, 'end_date')).toDateString() || ''}`;
}

const getKPI1MetricValue = (params, kpiString) => {
  return `${params.getValue(params.id, kpiString) || ''} ${
    params.getValue(params.id, 'kpi_1_goalType') || ''
  }`;
};

export const businessIOConfig = [
  //https://material-ui.com/components/data-grid/columns/
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    editable: false,
    type: 'number',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    flex: 0.1,
    renderCell: (params) => {
      const component =
        params.value === 'Active' ? (
          <strong>
            <div
              style={{
                height: '1rem',
                width: '1rem',
                backgroundColor: 'green',
                borderRadius: '50%',
              }}></div>
          </strong>
        ) : (
          <></>
        );
      return component;
    },
  },
  {
    field: 'business_io',
    headerName: 'Business IO',
    hide: false,
    editable: false,
    type: 'string',
    flex: 1.15,
    renderCell: (params) => (
      <BusinessIOButton bioName={params.value} bioId={params.id} />
    ),
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    hide: true,
    editable: false,
    type: 'string',
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    hide: true,
    editable: false,
    type: 'string',
    flex: 1,
  },
  {
    field: 'flight_dates',
    headerName: 'Flight Dates',
    valueGetter: getFlightDates,
    sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    hide: false,
    editable: false,
    type: 'string',
    // minWidth: 150,
    flex: 1.5,
  },
  {
    field: 'budget',
    headerName: 'Budget',
    hide: false,
    editable: false,
    type: 'number',
    //minWidth: 150,
    flex: 0.85,
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value).toLocaleString();
      return `₹ ${valueFormatted}`;
    },
    //valueParser: (value) => Number(value) / 100,
  },
  {
    field: 'spend',
    headerName: 'Spend',
    hide: false,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value).toLocaleString();
      return `₹ ${valueFormatted}`;
    },
    //valueParser: (value) => Number(value) / 100,
  },
  {
    field: 'nrr',
    headerName: 'NRR',
    hide: false,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value * 100).toLocaleString();
      return `${valueFormatted} %`;
    },
  },
  {
    field: 'kpi_1_goalType',
    headerName: 'KPI 1 Goal Type',
    hide: true,
    editable: false,
    type: 'string',
  },
  {
    field: 'kpi_1_goal',
    headerName: 'KPI_1 Goal',
    hide: true,
    editable: false,
    type: 'number',
  },
  {
    field: 'kpi_1',
    headerName: 'KPI 1',
    hide: true,
    editable: false,
    type: 'number',
  },
  {
    field: 'kpi1_metric',
    headerName: 'KPI 1 Goal',
    hide: false,
    editable: false,
    type: 'string',
    flex: 1,
    valueGetter: (params) => getKPI1MetricValue(params, 'kpi_1_goal'),
    sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
  },
  {
    field: 'kpi1',
    headerName: 'KPI1',
    hide: false,
    editable: false,
    type: 'string',
    flex: 0.85,
    valueGetter: (params) => getKPI1MetricValue(params, 'kpi_1'),
    sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
  },
  {
    field: 'kpi_score',
    headerName: 'KPI Score',
    hide: false,
    editable: false,
    type: 'number',
    flex: 1,
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value * 100).toLocaleString();
      return `${valueFormatted} %`;
    },
  },
  {
    field: 'margin',
    headerName: 'Margin',
    hide: false,
    editable: false,
    type: 'number',
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value * 100).toLocaleString();
      return `${valueFormatted} %`;
    },
  },
];

export const orderDisplay = [
  'status',
  'business_io',
  'start_date',
  'end_date',
  'flight_dates',
  'budget',
  'spend',
  'nrr',
  'kpi_1_goalType',
  'kpi_1_goal',
  'kpi_1',
  'kpi1_metric',
  'kpi1',
  'kpi_score',
  'margin',
];

/*
'string' (default)
'number'
'date'
'dateTime'
'boolean'
'singleSelect'
*/
