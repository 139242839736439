import asyncNotFound from './features/notFound/NotFound'
import asyncView from './features/view/skeleton'
import asyncAdvertiserPage from './features/view/advertisers/businessIOview/advertiserView'
import asyncConstruction from './features/construction/construction'
import asyncConfig from './features/config/Skeleton'

export const ComponentRoutes = {
  notFound: asyncNotFound,
  asyncView: asyncView,
  asyncAdvertiserPage: asyncAdvertiserPage,
  asyncConstruction: asyncConstruction,
  asyncConfig: asyncConfig,
};
