import axios from 'axios'
import { base_uri } from '../../../shared/backend/apiEndpoints';
import { StrategyMappingTableActionTypes } from "./Types";

const GET_DATA_ENDPOINT = `${base_uri}/strategy_mapping_table`
// const PUT_DATA_ENDPOINT = `${base_uri}/save_strategy_mapping`

export const makePagedDataRequest = (filters, pageNumber) => dispatch => {
    dispatch(setPagedDataLoading(true))
    const options = {
        method: "POST",
        url: GET_DATA_ENDPOINT,
        data: { filters: filters, page: pageNumber }
    }
    axios(options)
        .then(response => {
            const data = response.data;
            dispatch(setPagedData(data))
        })
        .catch(error => { 
            dispatch(setPagedDataError(error))
            alert(`Could not Fetch Strategy Mapping Data.\nError: ${error}`) 
        })
};

export const setPagedData = data => ({ 
    type: StrategyMappingTableActionTypes.SET_PAGED_DATA, 
    payload: data 
});

export const setPagedDataLoading = isLoading => ({
    type: StrategyMappingTableActionTypes.PAGED_DATA_REQUEST,
    payload: isLoading
})

export const setPagedDataError = error => ({
    type: StrategyMappingTableActionTypes.PAGED_DATA_FAILURE,
    payload: error,
})

export const setPagedDataSize = size => ({
    type: StrategyMappingTableActionTypes.SET_DATA_SIZE,
    payload: size,
})

export const addDataFitler = filter => {
    if (!filter.id) filter.id = Math.floor(Math.random()*(999-100+1)+100);
    if (!filter.name || !filter.operator) 
        return { type: StrategyMappingTableActionTypes.INVALID_FILTER }
    
    return {
        type: StrategyMappingTableActionTypes.ADD_DATA_FILTER,
        payload: filter,
    }
}

export const deleteDataFilter = id => ({
    type: StrategyMappingTableActionTypes.DELETE_DATA_FILTER,
    payload: id,
})

export const navigateNextPage = () => ({ type: StrategyMappingTableActionTypes.NAVIGATE_NEXT_PAGE })
export const navigatePrevPage = () => ({ type: StrategyMappingTableActionTypes.NAVIGATE_PREV_PAGE })