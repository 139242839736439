import * as React from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridFooter,
} from '@material-ui/data-grid';
// import {
//   XGrid,
//   GridToolbarContainer,
//   GridToolbarExport,
//   GridFooter,
// } from '@material-ui/x-grid';
import classes from './styles.module.scss';
import './muiStyles.scss';


function CustomToolbar() {
  return (
    <GridToolbarContainer className={classes.ToolBarComponent}>
      <GridToolbarExport className={classes.ToolBarExport} />
    </GridToolbarContainer>
  );
}

/*
    HELPFUL DOCS:
        1. Events: https://material-ui.com/components/data-grid/events/
*/
export default function DataGridDemo(props) {
  const {
    updateSelections,
    cellBlur,
    cellClick,
    cellDoubleClick,
    cellEditCommit,
    cellEditStart,
    cellEditStop,
    cellEnter,
    cellFocus,
    cellFocusOut,
    cellKeyDown,
    cellLeave,
    cellMouseDown,
    cellMouseUp,
    cellOut,
    cellOver,
    columnHeaderClick,
    columnHeaderDoubleClick,
    columnHeaderKeyDown,
    columnOrderChange,
    columnResize,
    columnResizeStart,
    columnResizeStop,
    columnsChange,
    columnVisibilityChange,
    columnWidthChange,
    componentError,
    debouncedResize,
    editCellPropsChange,
    editRowsModelChange,
    filterModelChange,
    pageChange,
    pageSizeChange,
    resize,
    rowClick,
    rowDoubleClick,
    rowEnter,
    rowLeave,
    rowOut,
    rowOver,
    rowsScroll,
    selectionChange,
    sortModelChange,
    stateChange,
    unmount,
    viewportRowsChange,
    checkboxSelection,
    CustomToolBar,
    columnBuffer,
  } = props;

  return (
    <div
      style={{ height: '100%', width: '100%' }}
      className={classes.DataGridComponents}>
      <DataGrid
        columnBuffer={columnBuffer || 8}
        rows={props.data || []}
        columns={props.schema || []}
        components={{
          Toolbar: CustomToolBar || CustomToolbar,
          Footer: GridFooter,
        }}
        className={classes.root}
        checkboxSelection={checkboxSelection !== false && true}
        disableSelectionOnClick
        scrollbarSize={5}
        pageSize={props.pageSize || 10}
        rowsPerPageOptions={[props.pageSize || 10]}
        density={props.density || 'compact'}
        onSelectionModelChange={
          updateSelections ||
          (() => console.log('update Selections not configured for this table'))
        }
        onCellBlur={cellBlur || (() => null)}
        onCellClick={cellClick || (() => null)}
        onCellDoubleClick={cellDoubleClick || (() => null)}
        onCellEditCommit={cellEditCommit || (() => null)}
        onCellEditStart={cellEditStart || (() => null)}
        onCellEditStop={cellEditStop || (() => null)}
        onCellEnter={cellEnter || (() => null)}
        onCellFocus={cellFocus || (() => null)}
        onCellFocusOut={cellFocusOut || (() => null)}
        onCellKeyDown={cellKeyDown || (() => null)}
        onCellLeave={cellLeave || (() => null)}
        onCellMouseDown={cellMouseDown || (() => null)}
        onCellMouseUp={cellMouseUp || (() => null)}
        onCellOut={cellOut || (() => null)}
        onCellOver={cellOver || (() => null)}
        onColumnHeaderClick={columnHeaderClick || (() => null)}
        onColumnHeaderDoubleClick={columnHeaderDoubleClick || (() => null)}
        onColumnHeaderKeyDown={columnHeaderKeyDown || (() => null)}
        onColumnOrderChange={columnOrderChange || (() => null)}
        onColumnResize={columnResize || (() => null)}
        onColumnResizeStart={columnResizeStart || (() => null)}
        onColumnResizeStop={columnResizeStop || (() => null)}
        onColumnsChange={columnsChange || (() => null)}
        onColumnVisibilityChange={columnVisibilityChange || (() => null)}
        onColumnWidthChange={columnWidthChange || (() => null)}
        onComponentError={componentError || (() => null)}
        onDebouncedResize={debouncedResize || (() => null)}
        onEditCellPropsChange={editCellPropsChange || (() => null)}
        onEditRowsModelChange={editRowsModelChange || (() => null)}
        onFilterModelChange={filterModelChange || (() => null)}
        onPageChange={pageChange || (() => null)}
        onPageSizeChange={pageSizeChange || (() => null)}
        onResize={resize || (() => null)}
        onRowClick={rowClick || (() => null)}
        onRowDoubleClick={rowDoubleClick || (() => null)}
        onRowEnter={rowEnter || (() => null)}
        onRowLeave={rowLeave || (() => null)}
        onRowOut={rowOut || (() => null)}
        onRowOver={rowOver || (() => null)}
        onRowsScroll={rowsScroll || (() => null)}
        onSelectionChange={
          selectionChange ||
          (() => console.log('Selection change not configured'))
        }
        onSortModelChange={sortModelChange || (() => null)}
        onStateChange={stateChange || (() => null)}
        onUnmount={unmount || (() => null)}
        onViewportRowsChange={viewportRowsChange || (() => null)}
      />
    </div>
  );
}
