import React from 'react';
import classes from './Create.module.scss';
import Select from 'react-select';
import { useHistory } from 'react-router';

import CollapsileForm from '../../../../../../../shared/ui-components/CollapsibleForm/CollapsileForm';
import ComponentGenerator from '../../../../../../../shared/ui-components/ComponentGenerator/ComponentGenerator';
import { contractComponents } from './constants/uiConstants';
import { CurrencyUnits, MetaDataRoutes } from '../../../../../../../shared/frontendConstants/constants';
import PDCComponent from './components/PDCComponent/PDCComponent';
// import BillingModelSlabs from './components/BillingModelSlabs/BillingModelSlabs';

import { useDispatch, useSelector } from 'react-redux';
import {
  updateFormData,
  metaDataAccounts,
  updateNestedContractData,
  updatePDCRequired,
  createNewAccount,
  editAccount,
  resetAccount,
} from '../../Accounts/functionalUnit/slices/AccountSlice';

export default function Create(props) {
  
  const { toggleEditMode } = props;
  const [contractState, setContract] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const formData = useSelector(metaDataAccounts);
  const createData = formData.createNew;
  const { errors, valid } = formData;
  const pdcData = formData.pdc;

  const onTextFieldChange = (event, key) => {
    const value = event.target.value;
    dispatch(updateFormData({ key, value }));
  };

  const onPDCChange = (value) => { dispatch(updatePDCRequired({ value })) };
  const onDropDownChange = (value, key) => { dispatch(updateFormData({ key, value })) }
  const onFormChangeHandler = (value, key) => { dispatch(updateNestedContractData({ key, value })) };

  const onSaveAccountClick = event => {
    event.preventDefault();
    dispatch(createNewAccount());
    dispatch(resetAccount());
    const subAccountPath = MetaDataRoutes.subaccounts
    history.push(subAccountPath)
  }

  const onEditAccount = event => {
    event.preventDefault();
    const account_id = formData.account_id;
    dispatch(editAccount(account_id));
    dispatch(resetAccount());
    const accountsPath = MetaDataRoutes.accounts
    history.push(accountsPath)
  }

  const onGoBack = event => {
    event.preventDefault();
    toggleEditMode();
    dispatch(resetAccount());
  }

  const ContractComponents = contractComponents.map((element) => (
    <ComponentGenerator
      {...element}
      onInputChange={onFormChangeHandler}
      inputValue={formData.createNew.contractDetails[element.props.reduxIdentifier]}
    />
  ));

  return (
    <div className={[classes.FormComponent, classes.ScrollBar].join(' ')}>
     
      {/* Account Name */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Account Name:
        </label>
        <input
          className={classes.FormComponent_LabelInput__InputTextField}
          value={createData.name}
          onChange={(event) => onTextFieldChange(event, 'name')}
        />
        {errors['name']?.error && (
          <label className={classes.FormComponent_LabelInput__ErrorLabel}>
            {errors['name']?.message}
          </label>
        )}
      </div>
      
      {/* Account Director */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Account Director:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={[
            { label: 'Heena', value: 0 },
            { label: 'Pushpinder', value: 1 },
            { label: 'Snehanshu', value: 2 },
          ]}
          onChange={(value) => onDropDownChange(value.label, 'director')}
          value={{ label: createData.director, value: createData.director }}
        />
      </div>

      {/* Account Type */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Account Type:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={[
            { label: 'Advertiser', value: 'Advertiser' },
            { label: 'Agency', value: 'Agency' },
          ]}
          onChange={(value) => onDropDownChange(value.label, 'type')}
          value={{ label: createData.type, value: createData.type }}
        />
      </div>

      {/* Account Currency  */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Account Currency:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={[
            { label: 'INR', value: 'INR' },
            { label: 'USD', value: 'USD' },
            { label: 'EUR', value: 'EUR' },
          ]}
          onChange={(value) => onDropDownChange(value.label, 'currency')}
          value={{ label: createData.currency, value: createData.currency }}
        />
      </div>

      {/* Engagement Type */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Engagement Type:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={[
            { label: 'Managed', value: 'Managed' },
            { label: 'Cosulting', value: 'Cosulting' },
            { label: 'Support', value: 'Support' },
            { label: 'Semi-Transparent', value: 'Semi-Transparent' },
          ]}
          onChange={(value) => onDropDownChange(value.label, 'engagement')}
          value={{ label: createData.engagement, value: createData.engagement }}
        />
      </div>

      {/* Credit Limit */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Credit Limit:
        </label>
        <div className={classes.FluidContainer_15rem}>
          <div
            className={
              classes.FormComponent_LabelInput__CurrencyRepresentation
            }>
            {CurrencyUnits[createData.currency] ?? '$'}
          </div>
          <input
            className={[
              classes.FormComponent_LabelInput__InputTextFieldUnsized,
              classes.FormComponent_LabelInput__InputTextFieldUnsized_autoFill,
            ].join(' ')}
            type='number'
            value={createData.credit_limit}
            onChange={(event) => onTextFieldChange(event, 'credit_limit')}
          />
          {errors['credit_limit']?.error && (
            <label className={classes.FormComponent_LabelInput__ErrorLabel}>
              {errors['credit_limit']?.message}
            </label>
          )}
        </div>
      </div>

      {/* Billing Cycle */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Billing Cycle:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={[
            { label: 'Fortnightly', value: 0 },
            { label: 'Monthly', value: 1 },
            { label: 'RO', value: 2 },
          ]}
          onChange={(value) => onDropDownChange(value.label, 'billingCycle')}
          value={{
            label: createData.billingCycle,
            value: createData.billingCycle,
          }}
        />
      </div>

      {/* Payment Term */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Payment Term (In Days):
        </label>
        <input
          className={classes.FormComponent_LabelInput__InputTextField}
          type={'number'}
          value={createData.paymentTerm}
          onChange={(event) => onTextFieldChange(event, 'paymentTerm')}
        />
        {errors['paymentTerm']?.error && (
          <label className={classes.FormComponent_LabelInput__ErrorLabel}>
            {errors['paymentTerm']?.message}
          </label>
        )}
      </div>

      <CollapsileForm
        formHeading={'Contract Details'}
        dropdownState={contractState}
        setDropdownState={setContract}
        // formComponents={[{ label: 'Payment Term', textField: true }]}
        RenderComponents={ContractComponents}
      />

      {/* Recieved PDC */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Have you recieved PDC?
        </label>
        <input
          type={'radio'}
          name={'pdc'}
          value={false}
          onChange={(event) => onPDCChange(event.target.value === 'true')}
          checked={!pdcData.isRequired}
        />
        <label className={classes.RadioButtonLabel}>No</label>
        <input
          type={'radio'}
          name={'pdc'}
          value={true}
          onChange={(event) => onPDCChange(event.target.value === 'true')}
          checked={pdcData.isRequired}
        />
        <label className={classes.RadioButtonLabel}>Yes</label>
      </div>
      {pdcData.isRequired && <PDCComponent />}

      {/* {<BillingModelSlabs inEditMode={inEditMode} />} */}

      {/* Button Groups */}
      <div className={classes.ButtonRow_CreateSpecific}>
        <button 
          className={classes.clickButton} 
          onClick={onGoBack}>
          Go Back
        </button>

        {formData.account_id === '' ? 
          <button
            className={ valid ? classes.clickButton : classes.clickButton__disabled}
            onClick={onSaveAccountClick}
            disabled={!valid}>
              Save Account
          </button> 
        : 
          <button
            className={ valid ? classes.clickButton : classes.clickButton__disabled}
            onClick={onEditAccount}
            disabled={!valid}>
              Save Account
          </button> 
        }
      </div>
    
    </div>
  );
}
