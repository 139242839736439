import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import React from 'react';

export default function DateRange(props) {
  const { onDateChangeHandler, dateRange, ...rest } = props;
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  return (
    <div {...rest}>
      <DateRangePicker
        onChange={(item) =>
          onDateChangeHandler(item) || setState([item.selection])
        }
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={dateRange || state}
        direction='horizontal'
      />
    </div>
  );
}
