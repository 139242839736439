import React from 'react'
import classes from "./InputDropDown.module.scss"

function InputDropDown({
    input,
    setInput,
    placeholder = "Enter Input",
    dropdownOptions = [],
}) {

    const [dropDownIsFocused, setDropDownIsFocused] = React.useState(false)
    const inputRef = React.useRef(null)

    return (
        <div className = {classes.DropDownContainer}>
                <input 
                    ref = {inputRef}
                    className = { classes.DropdownInput }
                    placeholder = {placeholder}
                    value = { input }
                    onChange = { event => { event.preventDefault(); setInput(event.target.value) } }
                    onFocus = { () => { setDropDownIsFocused(true) } }
                    onBlur = { () => { setDropDownIsFocused(false) } }
                />
                { dropDownIsFocused && dropdownOptions.length > 0 && 
                    <div className = {classes.FilterInputDroDown}>
                        {dropdownOptions
                            .filter(op => op.toLowerCase().includes(input.toLowerCase()))
                            .map(input => <div 
                                className={classes.DropDownElement}
                                onMouseDown = { event => { event.preventDefault() } }
                                onClick = { event => { event.preventDefault(); setInput(input); inputRef.current.blur() } }
                            >
                                {input}
                            </div>)
                        }
                    </div> 
                }
            </div>
    )
}

export default InputDropDown