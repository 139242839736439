import React from 'react';
import classes from './CollapsibleForm.module.scss';
import { icons, IconGeneric } from '../icons/Icons';
import SlideDown from 'react-slidedown';

export default function CollapsileForm(props) {
  const { dropdownState, setDropdownState, formHeading, RenderComponents } = props;
  return (
    <React.Fragment>
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          {formHeading}
        </label>
        <IconGeneric
          iconName={!dropdownState ? icons.DOWN_ICON : icons.UP_ICON}
          iconProps={{
            onClick: () => setDropdownState((prev) => !prev),
            className: classes.DropDownHandler,
          }}
        />
      </div>
      <SlideDown className={'my-dropdown-slidedown'}>
        {dropdownState ? (
          <div className={classes.SlideDownClass}>{RenderComponents}</div>
        ) : null}
      </SlideDown>
    </React.Fragment>
  );
}
