import React from 'react';

import Accounts from './components/Accounts/Accounts';

import { useSelector, useDispatch } from 'react-redux';
import {
  metaDataAccounts,
  loadExistingAccounts,
} from './components/Accounts/functionalUnit/slices/AccountSlice';
import { addDays } from 'date-fns';

import Spinner from '../../../../shared/ui-components/spinner/spinner';
// import {useHistory} from 'react-router-dom';

export default function BioView() {
  // const history = useHistory();
  const accountsData = useSelector(metaDataAccounts);
  const dispatch = useDispatch();
  const editMode = accountsData.inEditMode;
  const isLoading = accountsData.status === 'loading';

  const [dateRange, setDateRange] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
      resetter: -1,
    },
  ]);

  const dateRangeHandler = ({ value, hideHandler }) => {
    value[0].resetter *= -1;
    if (value[0].resetter === -1) {
      const dateRange = {
        startDate: value[0].startDate.toLocaleDateString(),
        endDate: value[0].endDate.toLocaleDateString(),
      };
      dispatch(loadExistingAccounts({ dateRange }));
      hideHandler(false);
    }
    setDateRange(value);
  };

  React.useEffect(() => {
    if (!editMode && !accountsData.existing.fetched) {
      dispatch(loadExistingAccounts());
    }
  }, [dispatch, accountsData.existing.fetched, editMode]);
  
  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <Accounts dateValue={dateRange} dateRangeHandler={dateRangeHandler} />
    </React.Fragment>
  );

}
