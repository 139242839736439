import React from 'react';
import classes from './MappingWindow.module.scss';
import CodeEditor from '../CodeEditor/CodeEditor';
import VerificationWindow from '../../slices/ui/FormulaVerifier/FormulaVerifier';
import {
  icons,
  IconGeneric,
  IconButton,
} from '../../../shared/ui-components/icons/Icons';

//const showToolbar = true;

export default function MappingWindow(props) {
  const {
    headers,
    data,
    onFormulaStringChange,
    onAliasChange,
    updateActive,
    unMapElement,
    dropdownElements,
    onDropDownChange,
    verifyProps,
    onVerifyClick,
    onVerifyInputChange,
    buttonDisabled,
    onVerifyFormulaRequest,
    isFormVerifierLoading,
    showOutput,
    outputResults,
    isOutputValid,
    resetPreviousKeys,
    subscriptionEmail,
  } = props;
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchParam, setSearchParam] = React.useState('');
  const [showToolbar, setShowToolbar] = React.useState(false);
  const [showVerification, setShowVerification] = React.useState(false);
  const connectorElements = data.mappingConfiguration.mappings;
  const mapKeys = Object.keys(connectorElements);
  const [dropdownValue, setDropdownValue] = React.useState('Select');
  const dropdowns = ['Original', ...dropdownElements];
  const activeModel = data.mappingConfiguration.activeMasterHeader;
  const formulaString =
    data.mappingConfiguration.mappings[
      data.mappingConfiguration.activeMasterHeader
    ]?.formula || '';
  return (
    <div className={classes.ConnectMix__MixZone}>
      {showToolbar ? (
        showVerification ? (
          <VerificationWindow
            closeClickHandler={() => {
              setShowVerification((prev) => !prev);
              resetPreviousKeys();
            }}
            onChangeHandler={onVerifyInputChange}
            isOutputValid={isOutputValid}
            showOutput={showOutput}
            outputResults={outputResults}
            onVerifyFormulaRequest={() =>
              onVerifyFormulaRequest(
                data.mappingConfiguration.activeMasterHeader
              )
            }
            buttonDisabled={buttonDisabled}
            isLoading={isFormVerifierLoading}
            userInputDatapoints={verifyProps.data}>
            Formula Verification
          </VerificationWindow>
        ) : (
          <CodeEditor
            language={'python'}
            header={data.mappingConfiguration.activeMasterHeader}
            aliasName={
              data.mappingConfiguration.mappings[
                data.mappingConfiguration.activeMasterHeader
              ].alias
            }
            triggerVerify={() => {
              setShowVerification(true);
              onVerifyClick?.(formulaString);
            }}
            resetPreviousKeys={resetPreviousKeys}
            formulaString={formulaString}
            headers={headers}
            onFormulaStringChange={
              onFormulaStringChange ||
              (() => console.log('Unconfigured function'))
            }
            onAliasChange={
              onAliasChange || (() => console.log('Unconfigured function'))
            }
            exitTrigger={(value) => setShowToolbar(value)}
          />
        )
      ) : null}
      <div className={classes.ConnectMix__MixZone___mappedHeader}>
        <p>
          Mapped <span>({mapKeys.length})</span>
        </p>
        {showSearch ? (
          <div className={classes.ConnectMix__MixZone___SearchBox}>
            {showSearch ? (
              <input
                className={classes['ConnectMix__MixZone___SearchBox--input']}
                placeholder={'Search...'}
                value={searchParam}
                onChange={(event) => setSearchParam(event.target.value)}
              />
            ) : null}
            <IconGeneric
              iconName={showSearch ? icons.CROSS_ICON : icons.SEARCH_ICON}
              iconProps={{
                className: classes['ConnectMix__MixZone___SearchBox--icon'],
                onClick: () => setShowSearch((prev) => !prev),
              }}
            />
          </div>
        ) : (
          <IconGeneric
            iconName={icons.BS_SEARCH_ICON}
            iconProps={{
              className:
                classes['ConnectMix__MixZone___SearchBox--icon-search'],
              onClick: () => setShowSearch((prev) => !prev),
            }}
          />
        )}
        <IconButton
          iconName={icons.DOCUMENT_ICON}
          Text={'Preview'}
          classNames={classes.Color}
        />
        <IconButton
          iconName={icons.UNLINK_ICON}
          Text={'Unmap All'}
          classNames={classes.Color}
        />
        <IconButton
          iconName={icons.DOWNLOAD_ICON}
          Text={'Download'}
          classNames={classes.Color}
        />
        <div className={classes.Templates}>
          <select
            className={classes.Templates_Select}
            value={dropdownValue}
            onChange={(e) => {
              if (dropdownValue !== e.target.value) {
                const datastream_id = e.target.value;
                const preserveProps = {};
                if (
                  dropdownValue === 'Select' ||
                  dropdownValue === 'Original'
                ) {
                  preserveProps.preserveOriginal = true;
                  preserveProps.originalPayload = { ...connectorElements };
                }
                onDropDownChange?.({
                  datastream_id,
                  preserveProps,
                });
                if (!onDropDownChange) {
                  console.log('Function not configured');
                }
                setDropdownValue(e.target.value);
              }
            }}>
            {dropdowns?.map((element) => (
              <option key={element} value={element}>
                {element}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.DataStreamDetails}>
        <p className={classes.DataStreamDetails_Description}>
          {'DataStream Subscription Email: ' + subscriptionEmail ??
            'shivam@yoptima.com'}
        </p>
      </div>
      {Object.keys(connectorElements).length === 0 && (
        <div className={classes.MapperZone}>
          <p className={classes.MapperZonePara}>
            Select an External or Model Field to Map
          </p>
        </div>
      )}
      <div
        className={[classes.LinkerModelWrapper, classes.ScrollBar].join(' ')}>
        {mapKeys.map((element) => {
          let displayString = connectorElements[element].formula;
          if (displayString.length > 40) {
            displayString = displayString.slice(0, 37) + '...';
          }
          return (
            <div
              key={element}
              onClick={() => {
                if (updateActive) updateActive(element);
                else console.log('Function not configured for: ', element);
              }}
              className={
                activeModel === element
                  ? [
                      classes.LinkerModel,
                      classes.LinkerModel__borderActive,
                    ].join(' ')
                  : classes.LinkerModel
            }>
            <p
              className={
                connectorElements[element].formula === 'UNDEFINED'
                  ? [
                      classes.LinkerModel__ToModeler,
                      classes.LinkerModel__ToModeler__Danger,
                    ].join(' ')
                  : classes.LinkerModel__ToModeler
              }
              onClick={() => {
                if (updateActive) updateActive(element);
                else console.log('Function not configured for: ', element);
                setShowToolbar(true);
              }}>
              {displayString}
            </p>
            <div className={classes.LinkerModel__Unlinker}>
              <IconGeneric
                iconName={icons.UNLINK_ICON}
                iconProps={{
                  onClick: () => {
                    if (unMapElement) unMapElement(element);
                    else console.log('Unconfigured action');
                  },
                }}
              />
            </div>
            <p
              className={
                element === 'UNDEFINED' || element === 'undefined'
                  ? [
                      classes.LinkerModel__FromModeler,
                      classes.LinkerModel__FromModeler__Danger,
                    ].join(' ')
                  : classes.LinkerModel__FromModeler
              }>
              {element}
            </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
