import { optionsMap } from '../../config_shared/DataStreamForm/constants/InputConstants';
export const verificationObjects = {
  connectMix: {
    datastream_email: {
      type: 'and',
      errormsg: 'Must be a valid Email!',
      verifiers: [
        {
          // eslint-disable-next-line no-useless-escape
          verifier_prop: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          type: 'regex',
        },
      ],
    },
    schedule: {
      type: 'and',
      errormsg: "Crontab Expression can't be empty!",
      verifiers: [{ type: 'non_empty' }],
    },
    datastream_attribute_1: {
      type: 'and',
      errormsg: 'Attribute Cannot Be Empty',
      verifiers: [{ type: 'non_empty' }],
    },
    client_source: {
      type: 'and',
      errormsg: "Selected Client Source isn't defined.",
      verifiers: [
        {
          type: 'includes',
          verifier_prop: [
            optionsMap['BigQuery'],
            optionsMap['Branch'],
            optionsMap['Gmail Pull'],
            optionsMap['Gmail Push'],
            optionsMap['Google Drive'],
            optionsMap['Google Sheets'],
            optionsMap['Open Link'],
            // 'Google Cloud Storage',
          ],
        },
      ],
    },
    sheet_id: {
      type: 'or',
      errormsg: 'Sheet Id cannot be empty.',
      verifiers: [
        {
          type: 'non_empty_no_spaces',
        },
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Google Sheets'],
          },
        },
      ],
    },
    sheet_name: {
      type: 'or',
      errormsg: 'Sheet Name cannot be empty',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Google Sheets'],
          },
        },
        {
          type: 'non_empty',
        },
      ],
    },
    file_id: {
      type: 'or',
      errormsg: 'Invalid File ID!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Google Drive'],
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
    attachment_sheet_name: {
      type: 'or',
      errormsg: 'Attachment sheet name cannot be empty.',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Gmail Push'],
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
    label: {
      type: 'or',
      errormsg: 'Label cannot be empty.',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Gmail Pull'],
          },
        },
        {
          type: 'non_empty',
        },
      ],
    },
    search_string: {
      type: 'or',
      errormsg: 'Invalid Search String!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Gmail Pull'],
          },
        },
      ],
    },
    is_attachment: {
      type: 'or',
      errormsg: 'No need of error',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Gmail Pull'],
          },
        },
      ],
    },
    link_sheet_name: {
      type: 'or',
      errormsg: 'Link sheet name cannot be empty',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Open Link'],
          },
        },
        {
          type: 'non_empty',
        },
      ],
    },
    link: {
      type: 'or',
      errormsg: 'Invalid Link field.',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Open Link'],
          },
        },
        {
          verifier_prop:
            // eslint-disable-next-line no-useless-escape
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
          type: 'regex',
        },
      ],
    },
    app_id: {
      type: 'or',
      errormsg: 'Invalid App ID!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Branch'],
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
    access_token: {
      type: 'or',
      errormsg: 'Invalid Access Token!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Branch'],
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
    branch_key: {
      type: 'or',
      errormsg: 'Invalid branch Key!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Branch'],
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
    branch_secret: {
      type: 'or',
      errormsg: 'Invalid Branch Secret!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Branch'],
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
    bucket: {
      type: 'or',
      errormsg: 'Invalid GCS Bucket!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Google Cloud Storage'] ?? '',
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
    gcs_file_id: {
      type: 'or',
      errormsg: 'Invalid GCS File ID!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Google Cloud Storage'] ?? '',
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
    gcs_file_sheet_name: {
      type: 'or',
      errormsg: 'Invalid GCS File Sheet Name!',
      verifiers: [
        {
          type: 'not_equal_to_props',
          verifier_prop: {
            props_key: 'client_source',
            value: optionsMap['Google Cloud Storage'] ?? '',
          },
        },
        {
          type: 'non_empty_no_spaces',
        },
      ],
    },
  },
  dstream: {
    name: {
      type: 'and',
      errormsg: 'Name cannot remain empty!',
      verifiers: [
        {
          type: 'non_empty',
        },
      ],
    },
  },
};
