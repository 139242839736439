import React from 'react'
import { connect } from "react-redux"
import { CreativeMappingTableActions } from '../../../CreativeMappingReducer/Actions'
import classes from './CreateFilterWindow.module.scss'
import { CREATIVE_MAPPING_COLUMNS, OPERATORS } from '../../../mappingConstants' 

const emptyFilter = {
    id: 0,
    name: "",
    operator: "",
    value: ""
}

function CreateFilterWindow({ addFilter, filterObject = emptyFilter }) {

    const [ filter, setFilter ] = React.useState(filterObject)
    const setName = name => { setFilter(filter => { console.log(`Setting Name: ${name}`); return { ...filter, name: name } }) }
    const setOperator = operator => { setFilter(filter => { console.log(`Setting Operator: ${operator}`); return { ...filter, operator: operator } }) }
    const setvalue = value => { setFilter(filter => { return { ...filter, value: value } }) }
    const eraseFilter = () => { setFilter(filter => { return { id: 0, name: "", operator: "", value: "" } }) }

    const [ columnSelectIsFocused, setColumnSelectIsFocused ] = React.useState(false)
    const [ operatorSelectIsFocused, setOperatorSelectIsFocused ] = React.useState(false)

    const saveFilter = filter => {
        addFilter(filter);
        eraseFilter();
    }

    return (
        <div className={classes.FilterWindowContainer}>
            <div className={classes.FilterWindow}>
                <div
                    className = { classes.InputContainer }
                    onFocus = { () => { setColumnSelectIsFocused(true) } }
                >
                    <input 
                        className = {classes.FilterWindowInput}
                        placeholder = "Column Name"
                        value = {filter.name}
                        onChange = { event => { event.preventDefault(); setName(event.target.value); } }
                    />
                    {
                        columnSelectIsFocused &&
                        <div className={classes.DropDown}>
                            {
                                CREATIVE_MAPPING_COLUMNS
                                .filter(column => column.toLowerCase().includes(filter.name.toLowerCase()))
                                .map(column => <input 
                                    key={column}
                                    type="button" 
                                    value={column} 
                                    onClick={ event => { event.preventDefault(); setName(column); setColumnSelectIsFocused(false)} } 
                                />)
                            }
                        </div>
                    }
                </div>
                <div
                    className = { classes.InputContainer }
                    onFocus = { () => { setOperatorSelectIsFocused(true) } }  
                >
                    <input 
                        className={classes.FilterWindowInput}
                        placeholder="Operator"
                        value = {filter.operator}
                        onChange = { event => { event.preventDefault(); setOperator(event.target.value) } }
                    />
                    {
                        operatorSelectIsFocused &&
                        <div className={classes.DropDown}>
                            {
                                OPERATORS
                                .filter(operator => operator.toLowerCase().includes(filter.operator.toLowerCase()))
                                .map(operator => <input 
                                    key={operator}
                                    type="button" 
                                    value={operator} 
                                    onClick = { event => { event.preventDefault(); setOperator(operator); setOperatorSelectIsFocused(false) } } 
                                />)
                            }
                        </div>
                    }
                </div>
                <div className={classes.InputContainer}>
                    <input 
                        className={classes.FilterWindowInput}
                        placeholder="Value"
                        value = {filter.value}
                        onChange = { event => { event.preventDefault(); setvalue(event.target.value); } }
                    />
                </div>
                <div className={classes.InputContainer}>
                    <input 
                        className={classes.FilterWindowInput}
                        type = "button"
                        value = "Create Filter"
                        onClick = { event => { event.preventDefault(); saveFilter(filter); } }
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return {} }
const mapDispatchToProps = dispatch => {
    return {
        addFilter: filter => { dispatch(CreativeMappingTableActions.addFilter(filter)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFilterWindow)
