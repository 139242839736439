import React from 'react';
import classes from './SchemaComponents.module.scss';
import Icon, { icons } from '../../../../shared/ui-components/icons/Icons';

export default function MetaData({
  active,
  params,
  showWarningIcon,
  viewComponentRequired,
  ViewComponent,
  editComponentRequired,
  EditComponent,
  DeleteComponentRequired,
  DeleteComponent,
  row,
}) {
  return (
    <div className={classes.MetaDataRow}>
      
      <div
        className={classes.ActiveStatus}
        style={active ? { backgroundColor: 'green' } : {}}>    
      </div>
      
      {/* Show Warning Icon */}
      {showWarningIcon && (
        <div
          className={classes.WarningIcon}
          style={params.row.badhealth ? { color: 'red' } : {}}>
          {Icon(icons.WARNING_ICON)}
        </div>
      )}
      
      {/* Show View Component */}
      {viewComponentRequired &&
        (ViewComponent ? 
          <ViewComponent classes={classes} row={row} /> : 
          <div className={classes.PencilIcon}>{Icon(icons.VIEW_ICON_EYE )}</div>
        )
      }

      {/* Show Edit Component  */}
      {editComponentRequired &&
        (EditComponent ? 
          <EditComponent classes={classes} row={row} /> : 
          <div className={classes.PencilIcon}>{Icon(icons.EDIT_ICON)}</div>
        )
      }

      {/* Show Delete Component */}
      {DeleteComponentRequired &&
        (DeleteComponent ?
          <DeleteComponent classes={classes} row={row} /> :
          <div className={classes.PencilIcon}>{Icon(icons.DELETE_ICON)}</div>
        )
      }
    
    </div>
  );
}
