import React from 'react';
import classes from './Layout.module.scss';
import Header from './Header/Header';

const fullYear = new Date().getFullYear();
export default function Layout({ children }) {
  return (
    <div className={classes.SkeletonBody}>
      <Header />
      <main>{children}</main>
      <footer className={classes.footer}>
        YOptima Media Solutions Pvt. Ltd. Copyright © {fullYear} All rights
        reserved.
      </footer>
    </div>
  );
}
