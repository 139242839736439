import AdvertiserPageRedirector from '../advertisers/components/AdvertiserPageRedirector';
export const advertiserTableConfig = [
  //https://material-ui.com/components/data-grid/columns/
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    editable: false,
    type: 'number',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    type: 'string',
    flex: 1,
    renderCell: (params) => {
      const component =
        params.value === 'Active' ? (
          <strong>
            <div
              style={{
                height: '1rem',
                width: '1rem',
                backgroundColor: 'green',
                borderRadius: '50%',
              }}></div>
          </strong>
        ) : (
          <></>
        );
      return component;
    },
  },
  {
    field: 'label',
    headerName: 'Advertiser',
    hide: false,
    editable: false,
    type: 'string',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {
      const getActiveId = () => {
        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== '__check__' && !!c);
        const thisRow = {};
        fields.forEach((f) => {
          thisRow[f] = params[f] || params.row[f];
        });

        return thisRow.id; //console.log(JSON.stringify(thisRow, null, 4));
      };

      return (
        <AdvertiserPageRedirector
          getActiveId={getActiveId}
          advertiserName={params.value}
        />
      );
    },
  },
  {
    field: 'Budget',
    headerName: 'Budget',
    hide: false,
    editable: false,
    type: 'number',
    minWidth: 150,
    flex: 1,
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value * 100).toLocaleString();
      return `₹ ${valueFormatted}`;
    },
    //valueParser: (value) => Number(value) / 100,
  },
  {
    field: 'Impressions',
    headerName: 'Impressions',
    hide: false,
    editable: false,
    type: 'number',
    minWidth: 150,
    flex: 1,
    valueFormatter: (params) =>
      params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
  {
    field: 'Clicks',
    headerName: 'Clicks',
    hide: false,
    editable: false,
    type: 'number',
    minWidth: 150,
    flex: 1,
    valueFormatter: (params) =>
      params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
  {
    field: 'Conversions',
    headerName: 'Conversions',
    hide: false,
    editable: false,
    type: 'number',
    minWidth: 150,
  },
  {
    field: 'Cost',
    headerName: 'Cost',
    hide: false,
    editable: false,
    type: 'number',
    minWidth: 150,
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value * 100).toLocaleString();
      return `₹ ${valueFormatted}`;
    },
  },
];

export const orderDisplay = [
  'status',
  'value',
  'label',
  'Budget',
  'Impressions',
  'Clicks',
  'Conversions',
  'Cost',
];

/*
'string' (default)
'number'
'date'
'dateTime'
'boolean'
'singleSelect'
*/
