import React from 'react'
import classes from './MappingTable.module.scss'
import { connect } from "react-redux"
import axios from "axios"

import { base_uri } from '../../../../shared/backend/apiEndpoints'
import { CREATIVE_MAPPING_COLUMNS } from '../../mappingConstants'

function CreativeMappingTable({ filters }) {

    const [ tableData, setTableData ] = React.useState({})
    const [ loading, setLoading ] = React.useState(false)
    const setConcept = ( creative_id, concept ) => {
        setTableData(data => {
            return {
                ...data,
                [creative_id] : { ...data[creative_id], "Creative_Concept_3": concept }
            }
        })
    }

    const edits = React.useRef(new Set([]))
    const addEdit = id => { edits.current = edits.current.add(id) }
    const [ savingEdits, setSavingEdits ] = React.useState(false)
    const [ savedEdits, setSavedEdits ] = React.useState(false)

    if (savedEdits) setTimeout(() => { setSavedEdits(false) }, 5000)
    if (savingEdits) setTimeout(() => { setSavingEdits(false) }, 5000)

    const saveEdits = () => {
        const editedData = [ ...edits.current ].reduce((editedData, id) => {
            editedData[id] = tableData[id]
            return editedData
        }, {})
        const options = {
           method : "POST",
           url : `${base_uri}/save_creative_mapping`,
           data: editedData, 
        }
        axios(options)
            .then(response => {
                setSavedEdits(true)
            })
            .catch(console.log)
        setSavingEdits(true)    
    }

    const [ pageNumber, setPageNumber ] = React.useState(1)
    const nextPage = () => { setPageNumber( pageNumber => pageSize === 100 ? pageNumber + 1 : pageNumber ) }
    const prevPage = () => { setPageNumber( pageNumber => Math.max(pageNumber - 1, 1) ) }
    const [ pageSize, setPageSize ] = React.useState(100)

    React.useEffect(() => {
        setPageNumber(1)
    }, [filters])

    React.useEffect(() => {
        setLoading(true)
        const options = {
            method:"POST",
            url: `${base_uri}/creative_mapping_table`,
            data: { filters: filters, page: pageNumber }
        }
        axios(options)
        .then(response => { 
            setLoading(false);
            setPageSize(response.data.length)
            const data = response.data.reduce((data, dataObj) => {
                const creative_id = dataObj["Creative_ID"];
                data[creative_id] = dataObj;
                return data;
            }, {})
            setTableData(data); 
        })
        .catch(error => { console.log(error) })
    }, [pageNumber, filters])

    return (
        <div className={classes.MappingTable}> 
            {
                loading && 
                <div className={classes.container}>
                    <div className={`${classes.animated} ${classes.LoadingBar}`}></div>
                </div>
            }
            <table className={classes.Table}>
                <thead>
                    <tr>
                        {
                            CREATIVE_MAPPING_COLUMNS.map(column => <td 
                                key = {Math.random()}
                                className={classes.HeaderBox}>{column.replace(/_/ig, " ")}
                            </td>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(tableData).map(creative_id => <tr key={creative_id}>
                            {
                                CREATIVE_MAPPING_COLUMNS.map(key => <td key={key} className={classes.DataBox}>
                                    { 
                                        key !== "Creative_Concept_3" ?
                                        <div key={tableData[creative_id][key]}>{tableData[creative_id][key]}</div> :
                                        <input 
                                            type = "text"
                                            value = {tableData[creative_id][key]}
                                            placeholder = "Unknown ..."
                                            onChange = { event => { event.preventDefault(); setConcept(creative_id, event.target.value); addEdit(creative_id)  } }
                                        />  
                                    }
                                </td>)
                            }
                        </tr>)
                    }
                </tbody>
            </table>
            <div className={classes.Footer}>
                <div className={classes.FooterButtons}>
                    <input 
                        className={classes.FooterButton}
                        type = "button"
                        value = "Save Mappings"
                        onClick = { event => { saveEdits(); } }
                    />
                </div>
                <div className={classes.PageManager}>
                    <input className = {classes.ArrowKeys} type="button" value="<" onClick={ event => { event.preventDefault(); prevPage() } }/>
                    <div>{`${(pageNumber-1)*100 + 1} - ${(pageNumber-1)*100 + pageSize}`} of Many</div>
                    <input className = {classes.ArrowKeys} type="button" value=">" onClick={ event => { event.preventDefault(); nextPage() } }/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        filters: state.creativeMapping.filters
    }
}

export default connect(mapStateToProps)(CreativeMappingTable)
