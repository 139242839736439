import React from 'react';
import Select from 'react-select';
import classes from './RO.module.scss';
import { useDispatch, useSelector } from 'react-redux';

import DateRangeSelector from '../../../../shared/ui-components/ComponentGenerator/components/DateRangeSelector/DateRangeSelectorBeta';
import SUBROs from './components/SubroSegment/SUBROs';

import {
  setEditMode,
  updateRODetails,
  roSliceData,
} from './functionalUnit/slices/ROS';
import { addSubRO, editRO, saveRO } from './functionalUnit/slices/subROs';
import { connectedAccount } from '../Accounts/functionalUnit/slices/ConnectedAccountSlice';

const BILLING_CYCLE_OPTIONS = [
  { label: 'Fortnightly', value: 'Fortnightly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'RO', value: 'RO' },
]
const CURRENCY_OPTIONS = [
  { label: 'USD', value: 'USD' },
  { label: 'INR', value: 'INR' },
  { label: 'EUR', value: 'EUR' },
]

export default function ROForm() {
  const dispatch = useDispatch();
  const connectedSlice = useSelector(connectedAccount);
  
  const roFields = useSelector(roSliceData);
  const { errors, valid } = roFields;
  const { ro_dates, ro_name, ro_number, ro_currency, ro_billing_cycle } = roFields.roDetails;

  const { account_id, subAccount_id } = connectedSlice;
  const account_name = connectedSlice.accountDetails.account_name;
  const subaccount_name = connectedSlice.subAccountDetails.sub_account_name;
  const isCreateNew = connectedSlice.ro_id === null || connectedSlice.ro_id === undefined;

  // On Save RO Click
  // -- Saves RO
  // -- Saves all consequent Sub-ROs
  const handleSaveROClick = event => {
    event.preventDefault();
    dispatch(saveRO());
  }

  // On Edit RO Click
  // -- Edits RO
  // -- Edits all consequrnt Sub-ROs
  const handleEditRoClick = event => {
    event.preventDefault();
    dispatch(editRO());
  }

  // Handle Ro Form Go-Back Click
  const handleRoFormGoBackClick = event => {
    event.preventDefault();
    dispatch(setEditMode(false));
  }

  return (
    <div className={[classes.FormComponent, classes.ScrollBar].join(' ')}>
      
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Account:
        </label>
        <input
          className={[
            classes.FormComponent_LabelInput__InputTextField,
            classes.DisabledInputField,
          ].join(' ')}
          disabled
          value={`${account_name} (${account_id})`}
        />
      </div>
      
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Sub Account:
        </label>
        <input
          className={[
            classes.FormComponent_LabelInput__InputTextField,
            classes.DisabledInputField,
          ].join(' ')}
          disabled
          value={`${subaccount_name} (${subAccount_id})`}
        />
      </div>
      
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          RO Name:
        </label>
        <input
          className={classes.FormComponent_LabelInput__InputTextField}
          value={ro_name}
          onChange={(event) =>
            dispatch(
              updateRODetails({
                value: event.target.value,
                key: 'ro_name',
              })
            )
          }
        />
        {errors['ro_name']?.error && (
          <label className={classes.FormComponent_LabelInput__ErrorLabel}>
            {errors['ro_name']?.message}
          </label>
        )}
      </div>
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          RO Number:
        </label>
        <input
          className={classes.FormComponent_LabelInput__InputTextField}
          value={ro_number}
          onChange={(event) =>
            dispatch(
              updateRODetails({
                value: event.target.value,
                key: 'ro_number',
              })
            )
          }
        />
      </div>
      
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Currency:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={CURRENCY_OPTIONS}
          value={CURRENCY_OPTIONS.filter(option => option.value === ro_currency)[0]}
          onChange={(selectedOption) =>
            dispatch(updateRODetails({ value: selectedOption.value, key: 'ro_currency' }))
          }
        />
      </div>

      <DateRangeSelector
        containerClassName={classes.FormComponent_LabelInput}
        labelClassName={classes.FormComponent_LabelInput__Label}
        label={'Flight Dates'}
        inputClassName={classes.FormComponent_LabelInput__InputTextField}
        labelTo={' To '}
        label2ClassName={classes.FormComponent_LabelInput__Label2}
        inputValue={ro_dates}
        reduxIdentifier={'ro_dates'}
        onInputChange={(value, changeKey) =>
          dispatch(
            updateRODetails({
              value: value,
              key: changeKey,
            })
          )
        }
      />

      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Billing Cycle:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={BILLING_CYCLE_OPTIONS}
          value={BILLING_CYCLE_OPTIONS.filter(options => options.value === ro_billing_cycle)[0]}
          onChange={(selectedOption) =>
            dispatch(updateRODetails({ value: selectedOption.value, key: 'ro_billing_cycle' }))
          }
        />
      </div>

      <div className={classes.ActionButtonRow}>
        {/* Add SubRO */}
        <button
          className={classes.clickButton}
          onClick={() => dispatch(addSubRO())}>
          Add Sub-RO
        </button>
      </div>

      <SUBROs ro_dates={ro_dates} />

      <div className={classes.ActionButtonRow}>
        {/* <button
          className={classes.clickButton}
          onClick={() => dispatch(draftRo('Draft_Test'))}>
          Save and Draft
        </button> */}
        <button
          className={ valid ? classes.clickButton : classes.clickButton__disabled }
          onClick={isCreateNew ? handleSaveROClick : handleEditRoClick}
          disabled={!valid}>
           Save RO
        </button>
        <button
          className={classes.clickButton}
          onClick={handleRoFormGoBackClick}>
          Go Back
        </button>
      </div>
    </div>
  );
}
