import React from 'react';
import classes from './PDC.module.scss';
import { formatYmd } from '../../../functionalUnit/constants/constants';
import {
  metaDataAccounts,
  updatePDCData,
  addNewPDCData,
  removePDC,
} from '../../../functionalUnit/slices/AccountSlice';
import { useSelector, useDispatch } from 'react-redux';

export default function PDCComponent() {
  
  const dispatch = useDispatch();
  let { pdc, errors } = useSelector(metaDataAccounts);
  pdc = pdc.pdcs;
  // console.log('Error = ', errors['pdc']);
  // const [pdcUseState, setPDC] = React.useState([initState]);

  const dispatchActionPDC = ({
    index,
    newValue,
    updateIssueDate,
    updateAmount,
    updateExpiryDate,
    updatePDCNumber,
  }) => {
    const newPDC = { ...pdc[index] };
    const { newDate, newAmount, pdcNumber } = newValue;
    if (updateIssueDate) newPDC.issueDate = formatYmd(newDate);
    if (updateExpiryDate) newPDC.expiryDate = formatYmd(newDate);
    if (updateAmount) newPDC.amount = newAmount;
    if (updatePDCNumber) newPDC.pdcNumber = pdcNumber;

    dispatch(updatePDCData({ index, value: newPDC }));
  };

  const setIssueDateType = (index, value) => () => {
    const newPDC = { ...pdc[index] };
    newPDC.issueDateType = value;
    dispatch(updatePDCData({ index, value: newPDC }));
  };

  const setExpiryDateType = (index, value) => () => {
    const newPDC = { ...pdc[index] };
    newPDC.expiryDateType = value;
    dispatch(updatePDCData({ index, value: newPDC }));
  };

  // const setIssueDate = (index, value) =>
  //   setPDC((current) => {
  //     const contentToBeUpdated = { ...current[index] };
  //     contentToBeUpdated.issueDate = value;
  //     current[index] = { ...contentToBeUpdated };
  //     return [...current];
  //   });

  // const setExpiryDate = (index, value) =>
  //   setPDC((current) => {
  //     const contentToBeUpdated = { ...current[index] };
  //     contentToBeUpdated.expiryDate = value;
  //     current[index] = { ...contentToBeUpdated };
  //     return [...current];
  //   });

  // const handleIssueDateChange = (index, event) => () =>
  //   setIssueDate(index, formatYmd(new Date(event.target.value)));
  // const handleExpiryDateChange = (index, event) =>
  //   setExpiryDate(index, formatYmd(new Date(event.target.value)));

  return (
    <div className={classes.PDCBody}>
      {pdc.map((element, index) => (
        <div key={index} className={classes.PDCElements}>
          
          {/* PDC Amount */}
          <input
            placeholder='PDC Number'
            type='text'
            value={element.pdcNumber || ''}
            onChange={(event) =>
              dispatchActionPDC({
                index,
                newValue: { pdcNumber: +event.target.value },
                updateIssueDate: false,
                updateExpiryDate: false,
                updateAmount: false,
                updatePDCNumber: true,
              })
            }
            className={classes.PDCInputField}></input>

          {/* PDC Issue Date */}
          <input
            placeholder={element.issueDate}
            type={element.issueDateType}
            onFocus={setIssueDateType(index, 'date')}
            onBlur={setIssueDateType(index, 'text')}
            // onChange={(event) => handleIssueDateChange(index, event)}
            onChange={(event) =>
              dispatchActionPDC({
                index,
                newValue: { newDate: new Date(event.target.value) },
                updateIssueDate: true,
                updateExpiryDate: false,
                updateAmount: false,
              })
            }
            className={classes.PDCInputField}></input>
          
          {/* PDC Expiry Date */}
          <input
            placeholder={element.expiryDate}
            type={element.expiryDateType}
            onFocus={setExpiryDateType(index, 'date')}
            onBlur={setExpiryDateType(index, 'text')}
            // onChange={(event) => handleExpiryDateChange(index, event)}
            onChange={(event) =>
              dispatchActionPDC({
                index,
                newValue: { newDate: new Date(event.target.value) },
                updateIssueDate: false,
                updateExpiryDate: true,
                updateAmount: false,
                updatePDCNumber: false,
              })
            }
            className={classes.PDCInputField}></input>
          
          {/* PDC Amount */}
          <input
            placeholder='Amount'
            type='text'
            value={element.amount || ''}
            onChange={(event) =>
              dispatchActionPDC({
                index,
                newValue: { newAmount: +event.target.value },
                updateIssueDate: false,
                updateExpiryDate: false,
                updateAmount: true,
                updatePDCNumber: false,
              })
            }
            className={classes.PDCInputField}></input>

          
          <button className={classes.AddButton}>
            <span
              className={classes.AddButtonContent}
              onClick={() => {
                if (index === pdc.length - 1) {
                  dispatch(addNewPDCData());
                } else {
                  dispatch(removePDC({ index }));
                }
              }}>
              {index === pdc.length - 1 ? '+' : '-'}
            </span>
          </button>
          {errors['pdc']?.[index]?.error && (
            <label className={classes.FormComponent_LabelInput__ErrorLabel}>
              {errors['pdc']?.[index]?.message}
            </label>
          )}
        </div>
      ))}
    </div>
  );
}
