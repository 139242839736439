import React from 'react';
import 'react-slidedown/lib/slidedown.css';
import classes from '../InputBoxGroup.module.scss';
import { icons, IconGeneric } from '../../icons/Icons';
import Tooltip from '../../../../shared/ui-components/ToolTip/ToolTip';

export default function InputText(props) {
  const {
    inputHeaderName,
    isRequired,
    showHint,
    showHelpHyperLink,
    hyperlink,
    value,
    showError,
    errorProps,
    onChangeHandler,
    customDisabler,
    hintMessage,
  } = props;
  let isTouched = false;
  let isInputError = false;
  let errorMsg = false;
  if (errorProps) {
    const key = errorProps.key;
    isTouched = errorProps.errors.touched;

    if (errorProps.errors.errors[key]) {
      isInputError = errorProps.errors.errors[key].error;
      errorMsg = errorProps.errors.errors[key].msg;
    }
  }

  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');

  const queryString = value || searchQuery;
  const changeHandler = (value) => {
    if (onChangeHandler) {
      return onChangeHandler(value);
    } else return setSearchQuery(value);
  };
  const infoText = (
    <p
      className={
        !open
          ? classes.InputBoxGroup__InstructionText
          : [
              classes.InputBoxGroup__InstructionText,
              classes['InputBoxGroup__InstructionText--active'],
            ].join(' ')
      }>
      {inputHeaderName || 'Description'}
      {isRequired && (
        <span className={classes.InputBoxGroup__InstructionText___required}>
          *
        </span>
      )}
      {showHint && (
        <span className={classes.InputBoxGroup__InstructionText___hinting}>
          <IconGeneric iconName={icons.HELP_ICON} />
        </span>
      )}
      {showHelpHyperLink && (
        <span
          className={classes.InputBoxGroup__InstructionText___hinting_href}
          onClick={() =>
            window.open(hyperlink || 'https://www.google.com', '_blank')
          }>
          Reference
        </span>
      )}
    </p>
  );
  return (
    <div
      className={
        !open
          ? classes.InputBoxGroup
          : [classes.InputBoxGroup, classes['InputBoxGroup--active']].join(' ')
      }>
      {showHint ? (
        <Tooltip
          toolTipMessage={hintMessage || 'This is for hinting'}
          placement='bottom-start'>
          {infoText}
        </Tooltip>
      ) : (
        infoText
      )}
      <div className={classes.InputBoxGroup__SimpleRow}>
        <div
          className={
            !open
              ? classes.InputBoxGroup__InputElement
              : [
                  classes.InputBoxGroup__InputElement,
                  classes['InputBoxGroup__InputElement--active'],
                ].join(' ')
          }>
          <input
            className={
              customDisabler
                ? classes['InputBoxGroup__InputElement--input-box_disabled']
                : classes['InputBoxGroup__InputElement--input-box']
            }
            onChange={(e) => changeHandler(e.target.value)}
            disabled={customDisabler || false}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            value={queryString}></input>
        </div>
        {showError && isTouched && isInputError && (
          <p className={classes['InputBoxGroup__InputElement--input-error']}>
            {errorMsg || 'Error shows up here'}
          </p>
        )}
      </div>
    </div>
  );
}
