import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { deepmetrics_uri } from '../../../../../../../constants';
import { errorLogger } from '../../../../../../shared/utils/errorLogger/errorLogger';

const initialState = {
  paused: false,
  accountSelected: false,
  
  account_id: null,
  accountDetails: {},
  
  subAccount_id: null,
  subAccountDetails: {},
  
  ro_id: null,
  roDetails: {},
};

// Fetch Account Details
export const fetchAccountDetails = createAsyncThunk(
  'metadata/ConnectAccountSlice/fetchAccountDetails',
  async account_id => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/account/${account_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Fetch SubAccount Details
export const fetchSubAccountDetails = createAsyncThunk(
  'metadata/ConnectAccountSlice/fetchSubAccountDetails',
  async sub_account_id => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/sub-account/${sub_account_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const response = await axios(request_configuration)
    return response.data
  }
)

// Fetch RO Details
export const fetchRODetails = createAsyncThunk(
  'metadata/ConnectAccountSlice/fetchRODetails',
  async ro_id => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/ro/${ro_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

export const chainFetchSubAccountDetails = createAsyncThunk(
  'metadata/ConnectedAccountSlice/chainFetchSubAccountDetails',
  async sub_account_id => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const sub_account_request_configuration = {
      url     : `${deepmetrics_uri}/sub-account/${sub_account_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const sub_account_response = await axios(sub_account_request_configuration)
    const subAccountDetails = sub_account_response.data;
    const account_id = subAccountDetails.account_id;
    const account_request_configuration = {
      url     : `${deepmetrics_uri}/account/${account_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const account_response = await axios(account_request_configuration)
    const accountDetails = account_response.data;
    
    return {
      account_id: account_id,
      accountDetails: accountDetails,
      subAccount_id: subAccountDetails.id,
      subAccountDetails: subAccountDetails,
    }
  }
)

export const chainFetchRODetails = createAsyncThunk(
  'metadata/ConnectedAccountSlice/chainFetchRODetails',
  async ro_id => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const ro_request_configuration = {
      url     : `${deepmetrics_uri}/ro/${ro_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const ro_response = await axios(ro_request_configuration)
    const roDetails = ro_response.data;
    const sub_account_id = roDetails.sub_account_id
    const sub_account_request_configuration = {
      url     : `${deepmetrics_uri}/sub-account/${sub_account_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const sub_account_response = await axios(sub_account_request_configuration)
    const subAccountDetails = sub_account_response.data;
    const account_id = subAccountDetails.account_id;
    const account_request_configuration = {
      url     : `${deepmetrics_uri}/account/${account_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const account_response = await axios(account_request_configuration)
    const accountDetails = account_response.data;
    
    return {
      account_id: account_id,
      accountDetails: accountDetails,
      subAccount_id: sub_account_id,
      subAccountDetails: subAccountDetails,
      ro_id: roDetails.id,
      roDetails: roDetails,
    }
  }
)

export const ConnectAccountSlice = createSlice({
  name: 'metadata/ConnectAccountSlice',
  initialState,
  reducers: {
    updateAccountId:      (state, action) => { state.account_id = action.payload },
    updateSubAccountId:   (state, action) => { state.subAccount_id = action.payload },
    updateROId:           (state, action) => { state.ro_id = action.payload },
    resetState:           () => initialState,
  },
  extraReducers: builder => {

    // Handling FetchAccountDetails
    builder
      .addCase(fetchAccountDetails.pending, state => { state.paused = true })
      .addCase(fetchAccountDetails.fulfilled, (state, action) => {
        const fetchedAccount = action.payload;
        state.accountDetails = fetchedAccount;
        state.paused = false;
      })
      .addCase(fetchAccountDetails.rejected, (state, action) => {
        errorLogger(action, 'Unable to Fetch Required Account')
        state.paused = false;
      })

    // Handling FetchSubAccountDetails
    builder
      .addCase(fetchSubAccountDetails.pending, state => { state.paused = true })
      .addCase(fetchSubAccountDetails.fulfilled, (state, action) => {
        const fetchedSubAccount = action.payload;
        state.subAccountDetails = fetchedSubAccount;
        state.paused = false;
      })
      .addCase(fetchSubAccountDetails.rejected, (state, action) => {
        errorLogger(action, 'Unable to Fetch Required Sub-Account')
        state.paused = false;
      })

    // Handling FetchRODetails
    builder
      .addCase(fetchRODetails.pending, state => { state.paused = true })
      .addCase(fetchRODetails.fulfilled, (state, action) => {
        const fetchedRO = action.payload;
        state.roDetails = fetchedRO;
        state.paused = false;
      })
      .addCase(fetchRODetails.rejected, (state, action) => {
        errorLogger(action, 'Unable to Fetch Required RO')
        state.paused = false;
      })

    // Handling ChainFetchSubAccontDetails
    builder 
      .addCase(chainFetchSubAccountDetails.pending, state => { state.paused = true })
      .addCase(chainFetchSubAccountDetails.fulfilled, (state, action) => {
        const fetchedSubAccountDetails = action.payload;
        state.account_id = fetchedSubAccountDetails.account_id;
        state.accountDetails = fetchedSubAccountDetails.accountDetails;
        state.subAccount_id = fetchedSubAccountDetails.subAccount_id;
        state.subAccountDetails = fetchedSubAccountDetails.subAccountDetails;
        state.paused = false;
      })
      .addCase(chainFetchSubAccountDetails.rejected, (state, action) => {
        errorLogger(action, 'Error Fetching Chained Sub Account Details')
        state.paused = false;
      })

    // Handling ChainFetchRODetails
    builder
      .addCase(chainFetchRODetails.pending, state => { state.paused = true })
      .addCase(chainFetchRODetails.fulfilled, (state, action) => {
        const fetchedRODetails = action.payload;
        state.account_id = fetchedRODetails.account_id;
        state.accountDetails = fetchedRODetails.accountDetails;
        state.subAccount_id = fetchedRODetails.subAccount_id;
        state.subAccountDetails = fetchedRODetails.subAccountDetails;
        state.ro_id = fetchedRODetails.ro_id;
        state.roDetails = fetchedRODetails.roDetails;
        state.paused = false;
      })
      .addCase(chainFetchRODetails.rejected, (state, action) => {
        errorLogger(action, 'Error Fetching Chained Ro Details')
        state.paused = false;
      })

  },
});
export const {
  updateAccountId,
  updateSubAccountId,
  updateROId,
  resetState,
} = ConnectAccountSlice.actions;
export const connectedAccount = (state) => state.connectedAccount;
export default ConnectAccountSlice.reducer;
