import React from 'react';
import Select from 'react-select';
import nClasses from './SubLinks.module.scss';
import {
  icons,
  IconGeneric,
} from '../../../../../../../../../../shared/ui-components/icons/Icons';

export default function SubLinks(props) {
  
  const { classes } = props;
  const subLinks = props.subLinks;
  const setSubLinks = props.setSubLinks;
  const generateInitialSubLink = props.setInitialSubLink;

  // Dashboard Name Settings
  const setDashboardName = (subLinkId, dashboardName) => {
    const requiredSubLink = subLinks.filter(sublink => sublink.id === subLinkId)[0]
    const modifiedSubLink = { ...requiredSubLink, name: dashboardName }
    const newSubLinks = [ ...subLinks.filter(sublink => sublink.id !== subLinkId), modifiedSubLink ]
    setSubLinks(newSubLinks)
  }
  const dashboardNameChangeHandler = subLinkId => event => {
    event.preventDefault();
    const dashboardName = event.target.value;
    setDashboardName(subLinkId, dashboardName)
  }
  
  // Dashboard Link Settings
  const setDashboardLink = (subLinkId, dashboardLink) => {
    const requiredSubLink = subLinks.filter(sublink => sublink.id === subLinkId)[0]
    const modifiedSubLink = { ...requiredSubLink, link: dashboardLink }
    const newSubLinks = [ ...subLinks.filter(sublink => sublink.id !== subLinkId), modifiedSubLink ]
    setSubLinks(newSubLinks)
  }
  const dashboardLinkChangeHandler = subLinkId => event => {
    event.preventDefault();
    const dashboardLink = event.target.value;
    setDashboardLink(subLinkId, dashboardLink)
  } 

  // Dashboard Option Select
  const dashboardTypeChangeHandler = subLinkId => ( dashboardType, actionMeta ) => {
    dashboardType = dashboardType.value;
    const requiredSubLink = subLinks.filter(sublink => sublink.id === subLinkId)[0]
    const modifiedSubLink = { ...requiredSubLink, type: dashboardType }
    const newSubLinks = [ ...subLinks.filter(sublink => sublink.id !== subLinkId), modifiedSubLink ]
    setSubLinks(newSubLinks)
  }

  return (
    <div className={nClasses.SubLinks}>
      
      {/* Important Links Heading */}
      <div className={classes.FormComponent_LabelInput}>
        <label
          className={[
            classes.FormComponent_LabelInput__Label,
            nClasses.fitContentClass,
          ].join(' ')}>
          Important Links
        </label>
        {subLinks.length === 0 && (
          <IconGeneric
            iconName={icons.ADD_ICON}
            iconProps={{
              className: nClasses.IconButtonClass,
              onClick: () => { setSubLinks([ generateInitialSubLink() ]) }
            }}
          />
        )}
      </div>
      
      {/* SubLink Dashbaords */}
      {subLinks.map(sublink => (
        
        <div key={sublink.id} className={nClasses.LinkRow}>
          
          {/* Remove Dashboard Sublink Button */}
          <IconGeneric
            iconName={icons.REMOVE_ICON_OUTLINE}
            iconProps={{
              className: nClasses.IconButtonSmall,
              onClick: () => {
                const current = subLinks.filter(sublinkElement => sublinkElement.id !== sublink.id)
                setSubLinks(current);
              },
            }}
          />

          {/* Input Dashboard Name */}
          <input
            value={sublink.name}
            className = {classes.FormComponent_LabelInput__InputTextField}
            placeholder = {'Dashboard Name'}
            onChange = {dashboardNameChangeHandler(sublink.id)}
          />

          {/* Input Dasboard Link */}
          <input
            value={sublink.link}
            className = {classes.FormComponent_LabelInput__InputTextField}
            placeholder = {'Link'}
            onChange = {dashboardLinkChangeHandler(sublink.id)}
          />

          {/* Select Dashboard Type (Internal/External) */}
          <Select
            className={classes.FormComponent_LabelInput__ReactSelectLabel}
            options={[
              { label: 'Internal', value: 'Internal' },
              { label: 'External', value: 'External' },
            ]}
            onChange={dashboardTypeChangeHandler(sublink.id)}
            value={{ label: sublink.type, value: sublink.type }}
          />

          {/* Add Dashboard Sublink Button */}
          <IconGeneric
            iconName={icons.ADD_ICON}
            iconProps={{
              className: nClasses.IconButtonSmall,
              onClick: () => {
                const newSubLinks = [ ...subLinks, generateInitialSubLink() ]
                setSubLinks(newSubLinks);
              },
            }}
          />
        
        </div>
      ))}
    </div>
  );
}
