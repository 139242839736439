import React from 'react'
import { connect } from "react-redux"
import { CreativeMappingTableActions } from '../../../CreativeMappingReducer/Actions';
import classes from './FilterElement.module.scss'

function FilterElement({ filter, deleteFilter }) {

    return (
        <div 
            id={filter.id} 
            className = {classes.FilterElement}
        >
            <span>
                <b>{filter.name}</b> 
                {` ${filter.operator} `} 
                <b>{filter.value}</b>
                <button
                    className = {classes.DeleteFilterButton}
                    onClick = { event => { event.preventDefault(); deleteFilter(filter.id); } }
                >
                    &#x2715;
                </button>
            </span>

            {/* { showCreateFilterBox && <CreateFilterWindow filterObject={filter}/> } */}
        </div> 
    )
}

const mapStateToProps = state => {}
const mapDispatchToProps = dispatch => {
    return {
        deleteFilter: id => { dispatch(CreativeMappingTableActions.deleteFilter(id)) } 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterElement)
