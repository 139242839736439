import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: 'view',
  viewSubTab: {
    activeTab: 'view_campaigns',
  },
  status: 'idle',
};
export const topNavBar = createSlice({
  name: 'topNavBar',
  initialState,
  reducers: {
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateActiveViewSubTab: (state, action) => {
      state.viewSubTab.activeTab = action.payload;
    },
  },
});

export const { updateActiveTab, updateActiveViewSubTab } = topNavBar.actions;
export const activeTab = (state) => state.topNavBar.activeTab;
export const activeViewTab = (state) => state.topNavBar.viewSubTab.activeTab;

export default topNavBar.reducer;
