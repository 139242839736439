import React from 'react';
import classes from './DeepView.module.scss';
import Popup from 'reactjs-popup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Select from 'react-select';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { FaFilter } from 'react-icons/fa';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import {
  deepDiveFilters,
  updateSpecificFilterBox,
  updateKeyValueSelections,
} from '../../slices/activeAdvertiserSlice';
import { useSelector, useDispatch } from 'react-redux';
export default function DeepView() {
  const filters = useSelector(deepDiveFilters);
  const dispatch = useDispatch();

  return (
    <div className={classes.Filter}>
      <Popup
        trigger={
          <div className={classes.FilterOperator}>
            <div className={classes.FilterBox}>
              <div className={classes.FilterTrigger}>
                <p className={classes.Paragraph}>Filter</p>
              </div>
              <div className={classes.Symbol}>
                <FaFilter />
              </div>
            </div>
          </div>
        }
        position={'bottom left'}>
        <div className={classes.FilterViewBox}>
          <div className={classes.FilterViewBox__Elements}>
            {filters.dynamicData.map((element, index) => {
              return (
                <div
                  key={'dropdown_selector__' + index}
                  className={classes.FilterViewBox__Elements__GroupedObjects}>
                  <Select
                    placeholder={'Dimension'}
                    options={filters.dimensions}
                    value={element.dimension}
                    onChange={(selected) => {
                      dispatch(
                        updateSpecificFilterBox({
                          index: index,
                          payload: selected,
                          keyValue: 'dimension',
                        })
                      );
                      dispatch(
                        updateKeyValueSelections({
                          index: index,
                          fromkey: selected.value,
                          toKey: 'value_options',
                        })
                      );
                    }}
                  />
                  <Select
                    placeholder={'Filter Type'}
                    options={filters.filters}
                    value={element.filter}
                    onChange={(selected) => {
                      dispatch(
                        updateSpecificFilterBox({
                          index: index,
                          payload: selected,
                          keyValue: 'filter',
                        })
                      );
                    }}
                  />
                  <Select
                    placeholder={'Value'}
                    options={element.value_options}
                    value={element.value}
                    onChange={(selected) => {
                      dispatch(
                        updateSpecificFilterBox({
                          index: index,
                          payload: selected,
                          keyValue: 'value',
                        })
                      );
                    }}
                  />
                  <HighlightOffIcon className={classes.ActionButtons} />
                </div>
              );
            })}
          </div>
          <div className={classes.FilterViewBox__AddNew}>
            <AddCircleOutlineIcon className={classes.ActionButtons} />
          </div>
          <button
            className={[
              classes.FilterViewBox__ApplyButton,
              classes.clickButton,
            ].join(' ')}>
            Apply
          </button>
        </div>
      </Popup>

      <div className={classes.FilterDisplay}>
        {dummyFilterElements.map((element, index) => (
          <p
            key={'dummyFilter__' + index}
            className={classes.FilterDisplayTypes}>
            {element}{' '}
            <span className={classes.FilterDisplayIcon}>
              <ClearSharpIcon />
            </span>
          </p>
        ))}
      </div>
    </div>
  );
}

const dummyFilterElements = [
  'Business IO Contains "OYO"',
  'Campaign Includes "Halodoc_BrandBlast_HighBid_OrderPlaced_NewPD_1854u_Mobile_iOS_27Jul21"',
  'Line Item Type includes "Youtube and Partners"',
];
