import React from 'react';
import NavBars from '../../../../sharedResources/uiComponents/NavBars';
import NavBar from '../../../../../shared/ui-components/nav-bar/navbar';
export default function TopNavBarComponent({
  buttons,
  active,
  dispatch,
  updateViewType,
}) {
  return (
    <>
      <NavBars />
      <NavBar
        buttonsArray={buttons}
        activeButton={active}
        buttonClickHandler={(key) => {
          dispatch(updateViewType(key));
        }}
      />
    </>
  );
}
