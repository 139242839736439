import { StrategyMappingTableActionTypes } from "./Types";

const initialState = {
    
    data: [],
    loading: false,
    error: "",

    filters: [],
    pageNumber: 1,
    pageSize: 0,

}

const StrategyMappingReducer = ( state = initialState, action ) => {

    switch(action.type) {

        case StrategyMappingTableActionTypes.SET_PAGED_DATA: return { 
            ...state, 
            data: action.payload, 
            error: "", 
            pageSize: action.payload.length,
            loading: false
        };
        case StrategyMappingTableActionTypes.PAGED_DATA_REQUEST: return { 
            ...state, 
            loading: true, 
            error: "" 
        };
        case StrategyMappingTableActionTypes.PAGED_DATA_FAILURE: return { 
            ...state, 
            loading: false, 
            error: action.payload 
        };
        
        case StrategyMappingTableActionTypes.ADD_DATA_FILTER: return { 
            ...state, 
            filters: [ ...state.filters, action.payload ], 
            pageNumber: 1 
        };
        case StrategyMappingTableActionTypes.DELETE_DATA_FILTER: return { 
            ...state, 
            filters: [ ...state.filters ].filter(filterObj => filterObj.id !== action.payload), 
            pageNumber: 1  
        };

        case StrategyMappingTableActionTypes.SET_DATA_SIZE: return { 
            ...state, 
            pageSize: action.payload 
        };

        case StrategyMappingTableActionTypes.NAVIGATE_NEXT_PAGE: return state.pageSize === 100 ? { ...state, pageNumber: state.pageNumber + 1 } : state;
        case StrategyMappingTableActionTypes.NAVIGATE_PREV_PAGE: return state.pageNumber !== 1 ? { ...state, pageNumber: state.pageNumber - 1 } : state;

        default: return state;

    }

}

export default StrategyMappingReducer