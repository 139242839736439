import React from 'react';

export function DateSelector(props) {
  const [dummyValue, setDummyValue] = React.useState(new Date());

  const {
    containerClassName,
    labelClassName,
    label,
    inputClassName,
    inputValue,
    onInputChange,
    inputDateOneProps,
    reduxIdentifier,
  } = props;

  const updateInputField = React.useCallback(
    (event) => {
      const newValue = event.target.value;
      if (onInputChange) onInputChange(newValue, reduxIdentifier);
      else setDummyValue(newValue);
    },
    [onInputChange, reduxIdentifier]
  );

  return (
    <div className={containerClassName}>
      <label className={labelClassName}>{label ?? 'Dates'}</label>
      <input
        {...inputDateOneProps}
        type={'date'}
        className={inputClassName}
        value={inputValue ?? dummyValue}
        onChange={updateInputField}
      />
    </div>
  );
}
