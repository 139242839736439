const currencyFormatter = (params) => {
  const valueFormatted = parseInt(params.value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  switch (params.currencyType) {
    case 'USD':
      return `$ ${valueFormatted}`;
    case 'EUR':
      return `€ ${valueFormatted}`;
    default:
      return `₹ ${valueFormatted}`;
  }
};

const percentageFormatter = (params) => {
  const valueFormatted = Number(params.value * 100).toLocaleString();
  return `${valueFormatted} %`;
};

export const deepDiveSchema = [
  //https://material-ui.com/components/data-grid/columns/
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    editable: false,
    type: 'number',
    flex: 1,
  },

  {
    field: 'campaign_status',
    headerName: 'Status',
    type: 'string',
    flex: 0.1,
    hide: false,
    renderCell: (params) => {
      const component =
        params.value === 'Active' ? (
          <strong>
            <div
              style={{
                height: '1rem',
                width: '1rem',
                backgroundColor: 'green',
                borderRadius: '50%',
              }}></div>
          </strong>
        ) : (
          <></>
        );
      return component;
    },
  },
  {
    field: 'li_status',
    headerName: 'Status',
    type: 'string',
    hide: true,
    flex: 0.1,
    renderCell: (params) => {
      const component =
        params.value === 'Active' ? (
          <strong>
            <div
              style={{
                height: '1rem',
                width: '1rem',
                backgroundColor: 'green',
                borderRadius: '50%',
              }}></div>
          </strong>
        ) : (
          <></>
        );
      return component;
    },
  },
  {
    field: 'io_status',
    headerName: 'Status',
    type: 'string',
    hide: true,
    flex: 0.1,
    renderCell: (params) => {
      const component =
        params.value === 'Active' ? (
          <strong>
            <div
              style={{
                height: '1rem',
                width: '1rem',
                backgroundColor: 'green',
                borderRadius: '50%',
              }}></div>
          </strong>
        ) : (
          <></>
        );
      return component;
    },
  },

  {
    field: 'campaign_name',
    headerName: 'Campaign',
    hide: false,
    editable: false,
    type: 'string',
    flex: 1.15,
  },
  {
    field: 'li_name',
    headerName: 'Line Item',
    hide: true,
    editable: false,
    type: 'string',
    flex: 1.15,
  },
  {
    field: 'io_name',
    headerName: 'Insertion Order',
    hide: true,
    editable: false,
    type: 'string',
    flex: 1.15,
  },
  {
    field: 'campaign_id',
    headerName: 'Campaign ID',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
  },
  {
    field: 'li_id',
    headerName: 'Line Item ID',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
  },
  {
    field: 'io_id',
    headerName: 'Insertion Order ID',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
  },
  {
    field: 'campaign_imps',
    headerName: 'Impressions',
    hide: false,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: (params) =>
      params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
  {
    field: 'io_imps',
    headerName: 'Impressions',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: (params) =>
      params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
  {
    field: 'li_imps',
    headerName: 'Impressions',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: (params) =>
      params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
  {
    field: 'campaign_clicks',
    headerName: 'Clicks',
    hide: false,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: (params) =>
      params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
  {
    field: 'io_clicks',
    headerName: 'Clicks',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: (params) =>
      params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
  {
    field: 'li_clicks',
    headerName: 'Clicks',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: (params) =>
      params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
  {
    field: 'campaign_ctr',
    headerName: 'CTR',
    hide: false,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: percentageFormatter,
  },
  {
    field: 'io_ctr',
    headerName: 'CTR',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: percentageFormatter,
  },
  {
    field: 'li_ctr',
    headerName: 'CTR',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: percentageFormatter,
  },

  {
    field: 'campaign_conversions',
    headerName: 'Conversions',
    hide: false,
    editable: false,
    type: 'number',
    flex: 0.85,
  },
  {
    field: 'io_conversions',
    headerName: 'Conversions',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
  },
  {
    field: 'li_conversions',
    headerName: 'Conversions',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
  },
  {
    field: 'campaign_spend',
    headerName: 'Spend',
    hide: false,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: currencyFormatter,
  },
  {
    field: 'io_spend',
    headerName: 'Spend',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: currencyFormatter,
  },
  {
    field: 'li_spend',
    headerName: 'Spend',
    hide: true,
    editable: false,
    type: 'number',
    flex: 0.85,
    valueFormatter: currencyFormatter,
  },
];

// export const orderDisplay = [
//   'campaign_status',
//   'io_status',
//   'li_status',

//   'business_io',
//   'start_date',
//   'end_date',
//   'flight_dates',
//   'budget',
//   'spend',
//   'nrr',
//   'kpi_1_goalType',
//   'kpi_1_goal',
//   'kpi_1',
//   'kpi1_metric',
//   'kpi1',
//   'kpi_score',
//   'margin',
// ];

/*
'string' (default)
'number'
'date'
'dateTime'
'boolean'
'singleSelect'
*/
