import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classes from './RO.module.scss';

import DataTable from '../../../slices/ui/DataTable/DataTable';
import MetaData from '../../../slices/ui/schemaComponents/SchemaComponents';
import Spinner from '../../../../shared/ui-components/spinner/spinner';
import DateRangePicker from '../../../../shared/ui-components/DateRangePicker/DateRangePicker';
import ROForm from './Form';
import Icon, {
  icons,
  IconGeneric,
} from '../../../../shared/ui-components/icons/Icons';

import {
  getROs,
  getFilteredROs,
  roSliceData,
  setEditMode,
  getRO,
  deleteRO,
  // eraseExistingROs,
} from './functionalUnit/slices/ROS';
import {
  connectedAccount,
  resetState,
  chainFetchRODetails,
  chainFetchSubAccountDetails,
} from '../Accounts/functionalUnit/slices/ConnectedAccountSlice';
import {
  formatDate,
  DateFormatTypes,
} from '../../../../shared/frontendConstants/converters';

import { addDays } from 'date-fns';
import { getSubROs } from './functionalUnit/slices/subROs';

// Edit Components
const EditComponent = ({ row, classes }) => {

  const dispatch = useDispatch()
  const handleEditClick = (sub_account_id, ro_id) => event => {
    event.preventDefault();

    // Chain Fetch RO
    // RO_ID >> RO >> Sub_Account_ID >> Sub_Account >> Account_ID >> Account
    dispatch(chainFetchRODetails(ro_id));
    dispatch(getRO(ro_id));
    dispatch(getSubROs(ro_id));

    // Set RO Screen in Edit Mode
    dispatch(setEditMode(true))

  }

  return (
    <div 
      className={classes.PencilIcon}
      onClick={handleEditClick(row.sub_account_id, row.id)}
    >  
      {Icon(icons.EDIT_ICON)}
    </div>
  );
};

// Delete Component
const DeleteComponent = ({ row, classes }) => {

  const dispatch = useDispatch()
  const deleteROClickHandler = roId => {

    const message = "Are you sure you want to Delete this RO ?"
    if (!window.confirm(message)) return;

    dispatch(deleteRO(roId));
    dispatch(getROs());
  }

  return (
    <div
      className={classes.PencilIcon}
      onClick={ event => { deleteROClickHandler(row.id) } }
    >
      {Icon(icons.DELETE_ICON)}
    </div>
  );
}

const ROSchema = [
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.25,
    renderHeader: () => <div></div>,
    renderCell: (params) => {
      const active = params.row.status === 'Active';
      return (
        <MetaData
          active={active}
          params={params}
          row={params.row}
          editComponentRequired
          EditComponent={EditComponent}
          DeleteComponentRequired
          DeleteComponent={DeleteComponent}
        />
      );
    },
  },
  { field: 'ro_number', headerName: 'RO Number', flex: 0.25, hide: true },
  { field: 'ro_name', headerName: 'RO', flex: 1, hide: false },
  {
    field: 'start_date',
    headerName: 'Start Date',
    flex: 1,
    hide: false,
    valueFormatter: (params) => {
      return formatDate(params.row.start_date, DateFormatTypes.DAY_MONTH);
    },
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    flex: 1,
    hide: false,
    valueFormatter: (params) => {
      return formatDate(params.row.end_date, DateFormatTypes.DAY_MONTH);
    },
  },
  { field: 'billing_cycle', headerName: 'Billing Cycle', flex: 1, hide: false },
  { field: 'currency', headerName: 'Currency', flex: 1, hide: false },
];

export default function RO(props) {
  
  const { dateRangeHandler, dateValue } = props;
  const dispatch = useDispatch();
  const roSlice = useSelector(roSliceData);
  const location = useLocation();
  const [showCalendar, setShowCalendar] = React.useState(false);

  if (location.state === null) setTimeout(() => {dispatch(resetState())}, 0)
  
  const connectedAccountSlice = useSelector(connectedAccount);
  const { account_id, subAccount_id } = connectedAccountSlice;
  const { existingROs, paused, inEditMode } = roSlice;
  
  const [dateRange, setDateRange] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  React.useEffect(() => {
    if (inEditMode) return;
    if (subAccount_id === undefined)  { dispatch(getROs()); return; }
    if (subAccount_id === null)       { dispatch(getROs()); return; }
    dispatch(getFilteredROs(subAccount_id))
  }, [dispatch, subAccount_id, inEditMode]);

  const isValidAccountId = account_id !== null && account_id !== undefined
  const isValidSubAccountId = subAccount_id !== null && subAccount_id !== undefined
  const showROCreateNew = !inEditMode && isValidAccountId && isValidSubAccountId

  const handleCreateNewROClick = event => {
    event.preventDefault();

    // Chain Fetch Sub-Account
    // -- Sub-Account-ID >> Sub-Account >> Account_ID >> Account
    dispatch(chainFetchSubAccountDetails(subAccount_id))

    // Set RO Screen in Edit Mode
    dispatch(setEditMode(true))

  }
    
  return (
    <React.Fragment>
      {paused ? <Spinner /> : 
        <React.Fragment>
          <div
            className={[
              showROCreateNew ? classes.TopNavBar : classes.TopNavBarEnd,
              classes.ScrollableComponent,
            ].join(' ')}>
            
            {/* Create New Button */}
            {showROCreateNew && (
              <button
                onClick={handleCreateNewROClick}
                className={classes.DVButtonGreen}>
                Create New
              </button>
            )}

            {/* Date Range Picker */}
            <div className={classes.DateRangePicker}>
              <IconGeneric
                iconName={showCalendar ? icons.CROSS_ICON : icons.CALENDAR_ICON}
                iconProps={{
                  onClick: () => setShowCalendar((prev) => !prev),
                  className: showCalendar
                    ? classes.CalendarButton_Checked
                    : classes.CalendarButton,
                }}
              />
              {showCalendar && (
                <DateRangePicker
                  className={classes.DateRangePickerCalendar}
                  onDateChangeHandler={(item) =>
                    (dateRangeHandler &&
                      dateRangeHandler({
                        value: [item.selection],
                        hideHandler: setShowCalendar,
                      })) ||
                    setDateRange([item.selection])
                  }
                  dateRange={dateValue || dateRange}
                />
              )}
            </div>
          
          {/* Main Component */}
          {/* Ro Form or RO Data-Table */}
          </div>
          {inEditMode ? <ROForm /> :
            <DataTable
              data={existingROs}
              checkboxSelection={false}
              schema={ROSchema}
            />
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
}
