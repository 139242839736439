import React from 'react';
import classes from './FormulaVerifier.module.scss';
import Spinner from '../../../../shared/ui-components/spinner/spinner';
import {
  IconGeneric,
  icons,
} from '../../../../shared/ui-components/icons/Icons';

export default function FormulaVerifier(props) {
  const {
    closeClickHandler,
    userInputDatapoints,
    onChangeHandler,
    buttonDisabled,
    isLoading,
    onVerifyFormulaRequest,
    showOutput,
    outputResults,
    isOutputValid,
  } = props;
  const outputKeys = Object.keys(outputResults || {});
  return (
    <div className={classes.PopupWindow}>
      {isLoading && <Spinner />}
      <div className={classes.OperationalSpace}>
        <h4>Evaluate Formula</h4>
        <div
          className={[classes.ScrollBar, classes.InputElementContainer].join(
            ' '
          )}>
          {Object.keys(userInputDatapoints).map((datapointHeader) => {
            const result = Object.keys(
              userInputDatapoints[datapointHeader]
            ).map((element) => {
              const inputFieldName = `${datapointHeader}['${element}']`;
              return (
                <div key={inputFieldName} className={classes.FieldSetBox}>
                  <p className={classes.FieldSetBox_Label}>{inputFieldName}</p>
                  <input
                    placeholder='Input...'
                    className={classes.InputTextBox}
                    onChange={(event) =>
                      onChangeHandler({
                        primaryKey: datapointHeader,
                        secondaryKey: element,
                        value: isNaN(+event.target.value)
                          ? event.target.value
                          : +event.target.value,
                      })
                    }
                    value={userInputDatapoints[datapointHeader][
                      element
                    ].toString()}
                  />
                </div>
              );
            });

            return result;
          })}
          {showOutput &&
            outputKeys.map((ele) => {
              const element = outputResults[ele];
              return (
                <div key={element} className={classes.FieldSetBox}>
                  <p
                    className={[
                      element.valid
                        ? classes.FieldSetBox_Label_green
                        : classes.FieldSetBox_Label_red,
                      classes.FieldSetBox_Label,
                    ].join(' ')}>
                    Output:
                  </p>
                  <div
                    className={[
                      classes.InputTextBox,
                      classes.ToolTipComponent,
                    ].join(' ')}>
                    {element.output}
                  </div>
                </div>
              );
            })}
        </div>
        <br />
        <div className={classes.RowButton}>
          <button
            onClick={() => {
              onVerifyFormulaRequest();
            }}
            disabled={!buttonDisabled}
            className={
              !buttonDisabled
                ? classes.clickButton__disabled
                : classes.clickButton
            }>
            Evaluate
          </button>
          <button className={classes.CancelButton} onClick={closeClickHandler}>
            Go Back
          </button>
          {showOutput && (
            <IconGeneric
              iconName={isOutputValid ? icons.CHECK_ICON : icons.CROSS_ICON}
              iconProps={{
                className: isOutputValid
                  ? classes.ValidIcon
                  : classes.InvalidIcon,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
