import ActiveViewComponent from './components/activeViewComponent/activeViewComponent';
import NotFound from '../../../notFound/NotFound';
export default function AdvertiserView(props) {
  let RenderComponent = <ActiveViewComponent {...props} />;
  if (
    props.match.params.viewType !== 'bio' &&
    props.match.params.viewType !== 'deepdive'
  )
    RenderComponent = <NotFound />;

  return RenderComponent;
}
