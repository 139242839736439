import React from 'react';
import ModalMessage from '../../../shared/ui-components/modal/ModalMessage';
import ModalConfirm from '../../../shared/ui-components/modal/ModalMessageReturn';
import classes from './ProgressBar.module.scss';
import Stages from './Stages';
import { resetFormState } from '../../slices/connectMix';
import { useDispatch } from 'react-redux';

const mandatoryFields = ['Date'];

export default function ProgressBar(props) {
  const {
    stages,
    buttonDisabled,
    updateMapping,
    modalErrors,
    created,
    stagesUpdateText,
    datastream_id,
    mappings,
    updateModalMessages,
    onCancelTrigger,
    createDataStream,
    updateLastActiveHeaders,
    isValidForSubmission,
    progressBarCustomSuccessMessage,
    modalHeaderMessage,
    stageFirstText,
  } = props;

  const dispatch = useDispatch();

  const [submissionConfirm, setSubmissionConfirm] = React.useState(false);

  const submitForm = (payload) => {
    updateModalMessages(payload);
    setSubmissionConfirm(false);
    if (payload.continue) {
      setTimeout(() => dispatch(resetFormState()), 2);
      dispatch(updateMapping());
    }
  };

  return (
    <div className={classes.ConfigNavigation}>
      {modalErrors.showModal && (
        <ModalMessage
          showModal={modalErrors.showModal}
          showMessage={modalErrors.msg}
          onCloseModal={updateModalMessages}
        />
      )}
      {modalErrors.showModal && submissionConfirm && (
        <ModalConfirm
          showModal={modalErrors.showModal}
          showMessage={modalErrors.msg}
          onCloseModal={submitForm}
          modalHeaderMessage={modalHeaderMessage}
        />
      )}
      <button className={classes.CancelButton} onClick={onCancelTrigger}>
        Cancel
      </button>
      <Stages
        stages={stages}
        isValid={isValidForSubmission}
        updateLastActiveHeaders={updateLastActiveHeaders}
      />
      <button
        className={
          !buttonDisabled
            ? [classes.DVButtonGreen, classes.ExtraPaddingButton].join(' ')
            : [classes.DVDisabledButton, classes.ExtraPaddingButton].join(' ')
        }
        disabled={buttonDisabled}
        onClick={() => {
          const stageLength = stages.iconElements.length;
          let lastActive = stages.lastActive;

          if (lastActive === stageLength - 1) {
            const keys = Object.keys(mappings);
            const keyLengthZero = keys.length === 0;
            let allMandatoryFieldsCovered = false;

            const noUndefinedformula = Object.values(mappings).reduce(
              (acc, curr) => {
                return acc && !(curr.formula === 'UNDEFINED');
              },
              true
            );
            mandatoryFields.forEach((element) => {
              allMandatoryFieldsCovered =
                allMandatoryFieldsCovered || keys.includes(element);
            });
            if (mappings['UNDEFINED']) {
              updateModalMessages({
                booleanValue: true,
                msg: 'You cannot procceed with the current configuration, as the master list field is undefined.',
              });
            } else if (keyLengthZero === 0) {
              updateModalMessages({
                booleanValue: true,
                msg: 'You must have at least one mapping configuration',
              });
            } else if (!allMandatoryFieldsCovered) {
              updateModalMessages({
                booleanValue: true,
                msg: `These fields are mandatory: ${mandatoryFields.join(
                  ', '
                )}. You must map them.`,
              });
            } else if (!noUndefinedformula) {
              updateModalMessages({
                booleanValue: true,
                msg: 'No Fields can remain undefined. You must map a formula to all of them',
              });
            } else {
              updateModalMessages({
                booleanValue: true,
                msg:
                  progressBarCustomSuccessMessage ||
                  'Basic Check is done, formulae check still remains, we will need to verify it later. For now form will be submitted. If you are sure you want to configure this mapping click Okay, otherwise click Cancel.',
              });
              setSubmissionConfirm(true);
            }
          } else {
            if (lastActive + 1 <= stageLength - 1) {
              if (lastActive + 1 === 1) {
                if (!created)
                  dispatch(
                    createDataStream({
                      datastream_id: datastream_id,
                    })
                  );
                else {
                  dispatch(updateLastActiveHeaders(lastActive + 1));
                }
              } else dispatch(updateLastActiveHeaders(lastActive + 1));
            }
          }
        }}>
        {stages.lastActive === stages.iconElements.length - 1
          ? stagesUpdateText ?? 'Update'
          : stages.lastActive === 0
          ? stageFirstText || 'Next'
          : 'Next'}
      </button>
    </div>
  );
}
