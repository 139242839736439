import React from 'react'
import { connect } from "react-redux"
import classes from './Filters.module.scss'

import FilterElement from './FilterElement'
import CreateFilterWindow from './CreateFilterWindow'


function CreativeMappingFilter({ filters }) {

    const [ showCreateFilterBox, setShowCreateFilterBox ] = React.useState(false)

    return (
        <div className={classes.Filter}>
            <div className={classes.AddFilter}>
                <div className={classes.AddFilterTitle}>
                    <div>Add Filter</div>
                    <button 
                        className = {classes.AddFilterButton}
                        onClick = {event => { event.preventDefault(); setShowCreateFilterBox(state => !state) }}
                    >
                        &#x25bc;
                    </button>
                </div>
                <div 
                    // onBlur={ event => { event.preventDefault(); setShowCreateFilterBox(false) } }
                >
                    {showCreateFilterBox && <CreateFilterWindow />}
                </div>
            </div>
            {
                filters.map((filter) => 
                    <FilterElement filter={filter}/>
                )
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        filters: state.creativeMapping.filters
    }
}

export default connect(mapStateToProps)(CreativeMappingFilter)
