import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
  createTheme as createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
  },
});
export default function ToolTip(props) {
  const { children, toolTipMessage, placement } = props; //'bottom-start'
  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <Tooltip
          title={toolTipMessage || 'Message not configured.'}
          placement={placement || 'bottom-start'}>
          {children}
        </Tooltip>
      </MuiThemeProvider>
    </React.Fragment>
  );
}
