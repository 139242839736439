import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { getJsonStreams } from './apis/api';
import { bqDataStreams, createdStream } from './apis/bqApis';
import { icons } from '../../shared/ui-components/icons/Icons';
import { verifierFunction } from './compoundFunctions/formVerifier';
import { verificationObjects } from './constants/verifications';
import { errorLogger } from '../../shared/utils/errorLogger/errorLogger';
// import {validateForm,validationTypes} from '../../shared/utils/formValidator/formValidator.js';

const stages = {
  lastActive: 0,
  iconElements: [
    { label: 'Pre Config', icon: icons.FORM_INPUT },
    { label: 'Upload', icon: icons.UPLOAD_ICON },
    { label: 'View', icon: icons.VIEW_ICON },
    { label: 'Configure', icon: icons.SETTINGS_ICON },
  ],
};
const schema = [
  {
    field: 'id',
    headerName: 'DataStream ID',
    flex: 1,
    hide: true,
  },
  {
    field: 'datastream_id',
    headerName: 'Name',
    flex: 1,
    hide: false,
  },
  {
    field: 'last_run_ts',
    headerName: 'Last Run Date',
    flex: 1,
    hide: false,
    valueFormatter: (params) =>
      new Date(params.value).toDateString() === 'Invalid Date'
        ? "Hasn't run yet"
        : new Date(params.value).toDateString(),
  },
  {
    field: 'last_data_date',
    headerName: 'Last Data Date',
    flex: 1,
    hide: false,
    valueFormatter: (params) =>
      new Date(params.value).toDateString() === 'Invalid Date'
        ? "Hasn't run yet"
        : new Date(params.value).toDateString(),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    hide: false,
  },
  {
    field: 'datastream_email',
    headerName: 'DataStream Email',
    flex: 1,
    hide: false,
  },
];

const initialState = {
  datastreams: [],
  datastream_id_list: [],
  datastream_selections: [],
  errors: { touched: false, errors: {}, isFormValid: false },
  createdataStream: {
    name: '',
    created: false,
  },
  stages,
  viewExisting: true,
  schema,
  status: 'idle',
  headers: [], //sheetName:[]
  metadataDetails: {},
  availableHeaders: [],
  toSendEmailId: '',
};

export const getDataStreams = createAsyncThunk(
  'datastreams/getDataStreams',
  async () => {
    const payload = { key: 'datastreams' };
    const response = await bqDataStreams(payload);
    return response;
  }
);

export const createDataStream = createAsyncThunk(
  'datastreams/createDataStream',
  async (payload, { getState, dispatch }) => {
    const lastActive = getState().dstream.stages.lastActive + 1;
    const dstreamDetails = getState().connectMix.datastreamConfigSettings;
    const response = await createdStream({
      operationProps: { ...payload, ...dstreamDetails },
      operationType: 'create_datastream',
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
    });
    if (!response.error) dispatch(updateLastActiveHeaders(lastActive));
    return response;
  }
);

export const updateDataStreamStatus = createAsyncThunk(
  'datastreams/updateDataStreamStatus',
  async (payload, { getState, dispatch }) => {
    const selected = getState().dstream.datastream_selections;
    const { const_payload } = payload;
    if (selected.length === 0) return {};
    const promises = [];
    selected.forEach((element) =>
      promises.push(
        createdStream({
          operationProps: {
            datastream_id: element,
            is_enabled_status: const_payload,
          },
          operationType: 'update_datastream_status',
          apiKey: process.env.REACT_APP_BACKEND_API_KEY,
        })
      )
    );
    const response = await Promise.all(promises);
    const errors = response.reduce((acc, curr) => {
      acc = acc || curr.error;
      return acc;
    }, false);
    if (!errors) {
      await dispatch(getDataStreams());
    } else {
      console.log('Caused error in disabling datastreams: ', response);
    }
    return response;
  }
);

export const dataStream = createSlice({
  name: 'dataStreams',
  initialState,
  reducers: {

    // Reset to initial state
    resetToInitialState: () => initialState,
    resetDataFetch: (state) => {
      state.createdataStream.created = false;
    },

    // Reset everything except payload
    resetEverythingExceptExisting: (state, action) => {
      const preservedKeys = action.payload;
      const stateKeys = Object.keys(state);
      stateKeys.forEach((element) => {
        if (!preservedKeys.includes(element)) {
          state[element] = initialState[element];
        }
      });
    },

    // Update view type
    updateViewType: (state, action) => {
      state.viewExisting = action.payload;
    },

    // upate datastream selections
    updateDataStreamSelections: (state, action) => {
      state.datastream_selections = action.payload;
    },

    // update datastream headers
    updateDataHeaders: (state, action) => {
      state.headers = action.payload;
      const results = action.payload.map((element) => element.name);
      state.availableHeaders = results;
    },

    // update file metadata
    updateMetaData: (state, action) => {
      state.metadataDetails.fileType = action.payload.fileType;
      state.metadataDetails.fileLoc = action.payload.fileLoc;
      state.metadataDetails.name = action.payload.name;
    },

    // update last active headers
    updateLastActiveHeaders: (state, action) => {
      state.stages.lastActive = action.payload;
    },

    // update data tab
    updateDataTab: (state, action) => {
      state.metadataDetails.name = action.payload;
    },

    // update datastream name
    updateDataStreamName: (state, action) => {
      state.createdataStream.name = action.payload;
      state.errors.touched = true;
      state.errors.errors = verifierFunction(
        verificationObjects.dstream,
        state.createdataStream
      );
      state.errors.isFormValid = Object.values(state.errors.errors).reduce(
        (acc, curr) => {
          acc = acc && !curr.error;
          return acc;
        },
        true
      );
    },
  },
  extraReducers: (builder) => {

    // Builder handling getDatastreams thunk
    builder
      .addCase(getDataStreams.pending, (state) => { state.status = 'loading'; })
      .addCase(getDataStreams.fulfilled, (state, action) => {
        const datastreamList = [];
        const { error, msg, payload } = action.payload;
        if (!error) {
          state.datastreams = payload.reduce((acc, curr) => {
            datastreamList.push(curr.datastream_id);
            acc.push({ ...curr, id: curr.datastream_id });
            return acc;
          }, []);
          state.datastream_id_list = datastreamList;
        } else errorLogger(action, `Error Caused in getDataStreams: ${msg}`);
        state.status = 'idle';
      })
      .addCase(getDataStreams.rejected, (state, action) => {
        console.log('Error happened: ', action);
        state.status = 'rejected';
      });

    // Builder handling createDatastream thunk
    builder
      .addCase(createDataStream.pending, (state) => { state.status = 'loading'; })
      .addCase(createDataStream.fulfilled, (state, action) => {
        state.createdataStream.created = !action.payload.error;
        if (action.payload.error) {
          alert('DataStream creation failed because: ' + action.payload.msg);
        } else {
          state.createdataStream.created_data = action.payload;
        }
        state.status = 'idle';
      })
      .addCase(createDataStream.rejected, (state, action) => {
        console.log('Error happened: ', action);
        state.status = 'rejected';
      });

    // Builder handling updateDatastreamStatus thunk
    builder
      .addCase(updateDataStreamStatus.pending, (state) => { state.status = 'loading'; })
      .addCase(updateDataStreamStatus.fulfilled, (state) => { state.status = 'idle'; })
      .addCase(updateDataStreamStatus.rejected, (state, action) => {
        console.log('Error happened: ', action);
        state.status = 'rejected';
      });
  },
});
export const {
  updateViewType,
  updateDataHeaders,
  updateMetaData,
  updateLastActiveHeaders,
  updateDataTab,
  updateDataStreamName,
  resetDataFetch,
  updateDataStreamSelections,
  resetToInitialState,
  resetEverythingExceptExisting,
} = dataStream.actions;
export default dataStream.reducer;
export const dataStreams = (state) => state.dstream;
