export const DateFormatTypes = {
  DAY_MONTH: 'DAY_MONTH',
  DD_MM_YYYY: 'DD_MM_YYYY',
  YYYY_MM_DD: 'YYYY_MM_DD',
};

const NUMERIC_DAYS = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const NUMERIC_MONTHS = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

const getDateWithGranularity = (date) => ({
  day: NUMERIC_DAYS[date.getDay()],
  dayNumeric: date.getDay(),
  monthNumeric: date.getMonth(),
  month: NUMERIC_MONTHS[date.getMonth()],
  dateNumeric: date.getDate(),
  year: date.getFullYear(),
});

const dayPostfix = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};
export const formatDate = (date, specifiedFormat) => {
  //Accepts YYYY-mm-dd format

  const dateValue = new Date(date);
  switch (specifiedFormat) {
    case DateFormatTypes.DAY_MONTH: {
      const { dateNumeric, month } = getDateWithGranularity(dateValue);
      const postFix = dayPostfix[dateNumeric] ?? 'th';
      return `${dateNumeric}${postFix} ${month.slice(0, 3)}`;
    }

    case DateFormatTypes.DD_MM_YYYY: {
      let { dayNumeric, monthNumeric, year } =
        getDateWithGranularity(dateValue);
      dayNumeric = dayNumeric.toString();
      monthNumeric = monthNumeric.toString();
      dayNumeric = dayNumeric.padStart(2, '0');
      monthNumeric = monthNumeric.padStart(2, '0');
      return dayNumeric + '-' + monthNumeric + '-' + year;
    }

    case DateFormatTypes.YYYY_MM_DD: {
      let { dateNumeric, monthNumeric, year } =
        getDateWithGranularity(dateValue);
      dateNumeric = dateNumeric.toString();
      monthNumeric = (monthNumeric + 1).toString();
      dateNumeric = dateNumeric.padStart(2, '0');
      monthNumeric = monthNumeric.padStart(2, '0');
      return year + '-' + monthNumeric + '-' + dateNumeric;
    }
    default:
      return dateValue.toLocaleDateString();
  }
};
