export const detectFormulaPairs = (formulaString) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /(\w+)\[['"]([a-zA-Z0-9-_/\s\.\(\)]*)['"]\]/g;
  const matches = Array.from(formulaString.matchAll(regex), (element) => [
    element[1],
    element[2],
  ]);

  const results = matches.reduce((acc, curr) => {
    if (acc[curr[0]]) {
      if (!acc[curr[0]][curr[1]]) {
        acc[curr[0]][curr[1]] = true;
      }
    } else acc[curr[0]] = { [curr[1]]: true };
    return acc;
  }, {});

  //`data["abc"]+new["fgh"]+data["3dx"]+names['defense']`

  return results;
};
