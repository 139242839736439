import { base_uri } from '../../../../../../../../shared/backend/apiEndpoints';
import { validationTypes } from '../../../../../../../../shared/utils/formValidator/formValidator';

const existingDefault = {
  existingAccounts: { data: [] },
  fetched: false,
};
export const formatYmd = (date) => date.toISOString().slice(0, 10);

const endpoints = {
  GET_EXISTING_ACCOUNTS: `${base_uri}metadata/get_existing_accounts`,
};

export const initDates = {
  from: formatYmd(new Date()),
  to: formatYmd(new Date(new Date().setDate(new Date().getDate() + 7))),
};

const initState = {
  issueDate: 'Issue Date',
  expiryDate: 'Expiry Date',
  amount: 0,
  pdcNumber: 0,
  pdcId: 0,
  issueDateType: 'text',
  expiryDateType: 'text',
};

const createNew = {
  name: '',
  director: 'Heena',
  type: 'Advertiser',
  currency: 'INR',
  engagement: 'Managed',
  credit_limit: 10000,
  billingCycle: 'Monthly',
  paymentTerm: 90,
  contractDetails: {
    contractName: '',
    effectiveDates: initDates,
    evaluationDate: formatYmd(new Date()),
  },
  billingModelSlabs: {},
};

const pdcData = {
  isRequired: false,
  pdcs: [initState],
};

const validationFields = {
  
  // Name validation (length > 0)
  name: {
    validationType: validationTypes.ARRAY_LENGTH,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'Name cannot be Blank.',
  },

  // credit limit validation (lenght > 0)
  credit_limit: {
    validationType: validationTypes.NUMBER_COMPARISIONS,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'Credit Limit must be greater than 0.',
  },

  // payment term validation (lenght > 0)
  paymentTerm: {
    validationType: validationTypes.NUMBER_COMPARISIONS,
    validationProps: {
      lengthConstraint: {
        GREATER_THAN: 0,
      },
    },
    invalidMessage: 'Payment term in days must be greater than 0.',
  },

  // pdc validation
  pdc: {
    validationType: validationTypes.NESTED_PDC_DETAILS,
  },
};

export const utils = {
  existingDefault,
  endpoints,
  createNew,
  pdcData,
  validationFields,
};
