import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import classes from './NavTab.module.scss';

export default function NavTab({ navTabs }) {
  const currLocation = useLocation().pathname;

  return Object.keys(navTabs).map((ele) => {
    const element = navTabs[ele];
    let isActiveFunction = () => element.uri === currLocation;
    if (element.isMultipleActive) {
      isActiveFunction = () => element.MultipleRoutes.includes(currLocation);
    }
    return (
      <NavLink
        key={element.id}
        to={element.uri}
        isActive={isActiveFunction}
        className={classes[element.className]}
        activeClassName={classes[element.activeClassName]}>
        {element.label}
      </NavLink>
    );
  });
}
