import React from 'react';
import classes from './Advertiser.module.scss';
import Filter from '../../../shared/ui-components/FilterBox/Filter';

export default function Advertiser() {
  return (
    <div className={classes.AdvertiserView}>
      <div className={classes.EmptyArea}></div>
      <Filter />

      <div className={classes.Table}></div>
      <div className={classes.EmptyArea}></div>
    </div>
  );
}
