export const base_uri = (process.env.NODE_ENV === 'production') ? 'https://deeplake.yoptima.com:5050' : 'http://localhost:5050'
export const deepmetrics_uri = 'https://deepm.yoptima.com'

if (process.env.NODE_ENV === 'production' && base_uri === 'http://localhost:5050')
  alert(`base_uri incorrectly configured. Trying to access endpoint: ${base_uri}`)

export const currencySymbols = {
    INR: '₹',
    USD: '$',
    EUR: '€',
};
