import React from 'react';
import DataTable from '../slices/ui/DataTable/DataTable';
import CustomDropZone from '../../shared/ui-components/DropZone/CustomDropZone';
import MetaDataConfig from '../config_shared/hoc/metadataConfig';
import ProgressBar from '../config_shared/ProgressBar/ProgressBar';
import PreConfig from '../config_shared/hoc/PreConfigDataStreamForm';
import ConfigureDataStream from './ConfigureDataStream';
import CustomToolBar from '../slices/ui/CustomToolBar/CustomToolBar';
import EditExistingDataStream from '../slices/ui/EditExistingConfiguration/EditExisting';
import {
  getDataStreams,
  dataStreams,
  updateViewType,
  updateMetaData,
  updateDataHeaders,
  updateLastActiveHeaders,
  updateDataStreamSelections,
  createDataStream,
  resetDataFetch,
  resetEverythingExceptExisting,
} from '../slices/dataStream.js';
import {
  setRequiredData,
  formulaes,
  updateTextField,
  verifyFormulaes,
  resetBackendVerification,
  resetFormulaVerification,
} from '../slices/formulaVerifierSlice.js';
import {
  existingDStreams,
  resetExistingSliceState,
} from '../slices/editExistingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { detectFormulaPairs } from '../slices/compoundFunctions/formulaVerifier/extractFormulaElements';
import Spinner from '../../shared/ui-components/spinner/spinner';
import {
  connectMixData,
  updateFormula,
  updateAlias,
  updateUserMapping,
  updateMasterListMapping,
  updateActiveSelector,
  removeSpecificKey,
  updateModalError,
  updateMapping,
  getExisting,
  resetFormState,
  resetMappingConfiguration,
  preserveOriginalMapping,
} from '../slices/connectMix';

import classes from '../Config.module.scss';

export default function DataStream() {
  const data = useSelector(dataStreams);
  const connectMix = useSelector(connectMixData);
  const isLoading = data.status === 'loading';
  const viewExisting = data.viewExisting;

  const noCmEror = connectMix.errors.isFormValid;
  const noDstreamError = data.errors.isFormValid;
  const modalErrors = connectMix.modalErrors;
  const created = data.createdataStream.created;
  const datastream_id = data.createdataStream.name;
  const isValid = connectMix.errors.isFormValid && data.errors.isFormValid;

  const formulaData = useSelector(formulaes);
  const formulaToBeSubmitted = formulaData.allGood;

  const stages = data.stages;
  const updateDstreamData = useSelector(existingDStreams);
  const subscriptionEmail =
    data.createdataStream.created_data?.cloud_response.created_email || '';

  const buttonDisabled = !(
    data.errors.touched &&
    connectMix.errors.touched &&
    noCmEror &&
    noDstreamError
  );
  const isFormVerifierLoading = formulaData.status === 'loading';

  const dispatch = useDispatch();

  //Mix Zone Functions

  const onFormulaStringChange = React.useCallback(
    (value) => {
      dispatch(
        updateFormula({
          header: connectMix.mappingConfiguration.activeMasterHeader,
          value: value,
        })
      );
    },
    [dispatch, connectMix.mappingConfiguration.activeMasterHeader]
  );

  const onAliasChange = React.useCallback(
    (value) => {
      dispatch(
        updateAlias({
          header: connectMix.mappingConfiguration.activeMasterHeader,
          value: value,
        })
      );
    },
    [dispatch, connectMix.mappingConfiguration.activeMasterHeader]
  );

  const updateActive = React.useCallback(
    (value) => {
      dispatch(updateActiveSelector(value));
    },
    [dispatch]
  );

  const unMapElement = React.useCallback(
    (value) => {
      dispatch(removeSpecificKey(value));
    },
    [dispatch]
  );

  const onOptionClickHandler = React.useCallback(
    (value) => {
      dispatch(updateMasterListMapping({ masterKey: value }));
    },
    [dispatch]
  );

  const onLeftOptionClickHandler = React.useCallback(
    (value, activeMasterHeader) => {
      dispatch(
        updateUserMapping({
          masterListHeader:
            activeMasterHeader === null ? 'UNDEFINED' : activeMasterHeader,
          formulaString: `data["${value}"]`,
          alias: value,
        })
      );
    },
    [dispatch]
  );

  const onVerifyFormulaRequest = (requiredFormula) => {
    const payload = {
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
      operationType: 'validatePandasFormula',
      operationProps: {
        data: formulaData.data,
        formulaStrings: {
          [requiredFormula]:
            connectMix.mappingConfiguration.mappings[requiredFormula],
        },
      },
    };
    dispatch(verifyFormulaes(payload));
  };

  const onFormulaVerificationRequest = (formulaString) => {
    const results = detectFormulaPairs(formulaString);
    dispatch(setRequiredData(results));
  };

  const resetPreviousFormulaKeys = () => {
    dispatch(resetBackendVerification());
  };

  const onFormulaUserInput = (payload) => {
    dispatch(updateTextField(payload));
  };

  const onCancelTrigger = () => {
    dispatch(updateViewType(true));
    dispatch(resetDataFetch());
    setTimeout(() => dispatch(resetFormState()), 0);
    setTimeout(() => dispatch(resetExistingSliceState()), 0);
    setTimeout(() => dispatch(resetFormulaVerification()), 0);
    setTimeout(
      () => dispatch(resetEverythingExceptExisting(['datastreams'])),
      0
    );
  };

  const onSuccessFileUpload = ({ fileMetaData, data }) => {
    dispatch(updateMetaData(fileMetaData));
    dispatch(updateDataHeaders(data));
    dispatch(updateLastActiveHeaders(2));
  };

  const dropdownElements = data.datastream_id_list;
  const onDropDownChange = ({ datastream_id, preserveProps }) => {
    if (preserveProps) {
      const { preserveOriginal, originalPayload } = preserveProps;
      if (preserveOriginal) preserveMapping(originalPayload);
    }
    if (datastream_id === 'Original') {
      dispatch(resetMappingConfiguration(connectMix.originalMapping));
    } else {
      dispatch(getExisting({ datastream_id, updateMappingOnly: true }));
    }
  };

  const preserveMapping = (payload) => {
    dispatch(preserveOriginalMapping(payload));
  };

  //Stages Callbacks

  const updateModalMessages = (payload) => {
    dispatch(updateModalError(payload));
  };

  React.useEffect(() => {
    dispatch(getDataStreams());
  }, [dispatch]);

  const stagedElements = [
    <PreConfig />,
    <CustomDropZone onSuccessFileUpload={onSuccessFileUpload} />,
    <MetaDataConfig />,
    <ConfigureDataStream
      data={data}
      connectData={connectMix}
      onFormulaStringChange={onFormulaStringChange}
      onAliasChange={onAliasChange}
      updateActive={updateActive}
      unMapElement={unMapElement}
      onOptionClickHandler={onOptionClickHandler}
      onLeftOptionClickHandler={onLeftOptionClickHandler}
      dropdownElements={dropdownElements}
      onDropDownChange={onDropDownChange}
      onVerifyClick={onFormulaVerificationRequest}
      verifyProps={formulaData}
      onVerifyInputChange={onFormulaUserInput}
      verifybuttonDisabled={formulaToBeSubmitted || true}
      onVerifyFormulaRequest={onVerifyFormulaRequest}
      isFormVerifierLoading={isFormVerifierLoading}
      showOutput={formulaData.verificationRequested && !isFormVerifierLoading}
      outputResults={formulaData.backendOutput}
      isOutputValid={formulaData.backendSuccess}
      resetPreviousKeys={resetPreviousFormulaKeys}
      formulaData={formulaData}
      subscriptionEmail={subscriptionEmail}
    />,
  ];

  const renderComponent = isLoading ? (
    <Spinner />
  ) : (
    <div className={classes.DataStream}>
      {viewExisting && !updateDstreamData.inEditMode && (
        <div className={classes.NewConfiguration}>
          <button
            className={classes.DVButtonGreen}
            onClick={() => {
              dispatch(updateViewType(false));
            }}>
            New DataStream
          </button>
        </div>
      )}
      {viewExisting ? (
        !updateDstreamData.inEditMode ? (
          <div className={classes.Datatable}>
            <DataTable
              data={data.datastreams}
              schema={data.schema}
              checkboxSelection={true}
              CustomToolBar={CustomToolBar}
              updateSelections={(params) => dispatch(updateDataStreamSelections(params)) }
              pageSize={25}
            />
          </div>
        ) : (
          <EditExistingDataStream />
        )
      ) : (
        <div className={classes.ConfigBox}>
          <div
            className={[
              classes.ConfigBox_StagedElements,
              classes.ScrollBar,
            ].join(' ')}>
            {stagedElements[stages.lastActive]}
          </div>

          <ProgressBar
            stages={stages}
            buttonDisabled={buttonDisabled}
            updateModalError={updateModalError}
            updateMapping={updateMapping}
            modalErrors={modalErrors}
            created={created}
            stagesUpdateText={'Submit'}
            datastream_id={datastream_id}
            mappings={connectMix.mappingConfiguration.mappings}
            updateModalMessages={updateModalMessages}
            createDataStream={createDataStream}
            resetDataFetch={resetDataFetch}
            updateLastActiveHeaders={updateLastActiveHeaders}
            updateViewType={updateViewType}
            isValidForSubmission={isValid}
            onCancelTrigger={onCancelTrigger}
            progressBarCustomSuccessMessage={
              'We recommend to validate formulas before submitting. In case if you have done it already, click "Okay" otherwise click "Cancel"'
            }
            modalHeaderMessage={'Confirm Submission'}
            stageFirstText={'Save and Next'}
          />
        </div>
      )}
    </div>
  );

  return renderComponent;
}
