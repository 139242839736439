import React from 'react'
import PageManager from '../PageManager'
import classes from './EditingTable.module.scss'

function EditingTable({
    headers,
    data,
    pageNumber,
    navigateNextPage,
    navigatePrevPage,
    getRowID,
    editableColumns = [],
    loading = false
}) {

    return (
        <>
        {
            loading && 
            <div className={classes.LoadingContainer}>
                <div className={`${classes.animated} ${classes.LoadingBar}`}></div>
            </div>
        }
        <div className={ classes.TableContainer }>
            <table>
                <thead>
                    <tr>
                        {headers.map(header => <td>
                            <div className={classes.TableHeader}>{header.replace(/_/g, " ")}</div>
                        </td>)}
                    </tr>   
                </thead>
                <tbody>
                    {data.map(row => 
                        <tr key={getRowID(row)}>
                            {Object.keys(row).map((key, index) => <td>
                                {
                                    editableColumns.includes(row[key]) ? 
                                    <input 
                                        key = {index}
                                        value={row[key]} 
                                        placeHolder={`Unknown ...`} 
                                        className={classes.TableDataInput} 
                                    /> :
                                    <div className={classes.TableData}>
                                        {key === "Date" ? "Hello" : row[key]}
                                    </div>
                                }   
                            </td>)}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        <PageManager 
            pageSize = {data.length}
            pageNumber = {pageNumber}
            navigateNextPage = {navigateNextPage}
            navigatePrevPage = {navigatePrevPage}
        />
        </>
    )
}

export default EditingTable
