import { CreativeMappingTableActionTypes } from "./Types";

const intitalState = {
    filters : []
}

const CreativeMappingReducer = ( state = intitalState, action ) => {

    switch(action.type) {

        case CreativeMappingTableActionTypes.ADD_FILTER:    return { ...state, filters: [ ...state.filters, action.payload ] }
        case CreativeMappingTableActionTypes.DELETE_FILTER: return { ...state, filters: state.filters.filter(filter => filter.id !== action.payload) }

        default: return state
    
    }

}

export default CreativeMappingReducer