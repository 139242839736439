import React from 'react';
import classes from './SubAccounts.module.scss';
import {
  loadSubAccounts,
  subAccounts,
  setCreateNew,
  getSubAccount,
  deleteSubAccount,
} from './functionalUnit/slices/subAccounts';
import { useDispatch, useSelector } from 'react-redux';
import { MetaDataRoutes } from '../../../../shared/frontendConstants/constants';
import DataTable from '../../../slices/ui/DataTable/DataTable';
import MetaData from '../../../slices/ui/schemaComponents/SchemaComponents';
import Spinner from '../../../../shared/ui-components/spinner/spinner';
import Create from './components/Create/Create';

import {
  connectedAccount,
  updateSubAccountId,
  updateAccountId,
  fetchAccountDetails,
  fetchSubAccountDetails,
  resetState,
} from '../Accounts/functionalUnit/slices/ConnectedAccountSlice';
import { useHistory, useLocation } from 'react-router-dom';
import Icon, {
  icons,
  IconGeneric,
} from '../../../../shared/ui-components/icons/Icons';
import DateRangePicker from '../../../../shared/ui-components/DateRangePicker/DateRangePicker';
import { addDays } from 'date-fns';


// View Component (Representing Eye View Button on SubAccounts Table Page)
const ViewComponent = ({ row, classes }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  
  const RedirectToROHandler = ({ subaccount_id, account_id }) => {

    // Update SubAccount in chain-state(ConnectedAccount) slice
    dispatch(updateSubAccountId(subaccount_id));
    dispatch(fetchSubAccountDetails(subaccount_id));

    // Update Account in chain-state(ConnectedAccount) slice
    dispatch(updateAccountId(account_id));
    dispatch(fetchAccountDetails(account_id));
    
    // Push New Path to history => Navigate to new page
    history.push({
      pathname: MetaDataRoutes.ros,
      state: { justPreserve: true },
    });
  
  };
  return (
    <div
      className={classes.PencilIcon}
      onClick={event => { RedirectToROHandler({subaccount_id: row.id, account_id: row.account_id }) }}>
      {Icon(icons.VIEW_ICON_EYE)}
    </div>
  );
};

// Edit Component (Reporesenting Pencil Edit Button on SubAccounts Table Page)
const EditComponent = ({ row, classes }) => {

  const dispatch = useDispatch();
  const RedirectToCreateSubAccount = ({ subaccount_id, account_id }) => {

    // Update SubAccount in chain-state(ConnectedAccount) slice
    dispatch(updateSubAccountId(subaccount_id));
    dispatch(fetchSubAccountDetails(subaccount_id));

    // Update Account in chain-state(ConnectedAccount) slice
    dispatch(updateAccountId(account_id));
    dispatch(fetchAccountDetails(account_id));

    // Fetch Sub-Account-ID in SubAccountSLice
    dispatch(getSubAccount(subaccount_id));
    dispatch(setCreateNew());

  }

  return (
  <div 
    className={classes.PencilIcon}
    onClick={event => { RedirectToCreateSubAccount({ subaccount_id: row.id, account_id: row.account_id }) }}
  > 
    {Icon(icons.EDIT_ICON)} 
  </div>)
};

// Delete Component
const DeleteComponent = ({ row, classes }) => {

  const dispatch = useDispatch()
  const deleteSubAccountClickHandler = subAccountId => {

    // Yes/No Delete dialog box
    const message = "Are you sure you want to delete this Sub-Account ?"
    if (!window.confirm(message)) return;

    dispatch(deleteSubAccount(subAccountId));
    dispatch(loadSubAccounts());
  }

  return (
    <div
      className={classes.PencilIcon}
      onClick={event => { deleteSubAccountClickHandler(row.id) }}
    >
      {Icon(icons.DELETE_ICON)}
    </div>
  )

}

const subAccountsSchema = [
  {
    field: 'sub_account_status',
    headerName: 'Status',
    flex: 0.25,
    renderHeader: () => <div></div>,
    renderCell: (params) => {
      const active = params.row.status === 'Active';
      return (
        <MetaData
          active={active}
          params={params}
          viewComponentRequired
          ViewComponent={ViewComponent}
          editComponentRequired
          EditComponent={EditComponent}
          DeleteComponentRequired
          DeleteComponent={DeleteComponent}
          row={params.row}
        />
      );
    },
  },
  { field: 'id', headerName: 'ID', flex: 0.25, hide: true },
  { field: 'account_id', headerName: 'Account ID', flex: 0.25, hide: true },
  { field: 'sub_account_name', headerName: 'Sub Account', flex: 1, hide: false },
  { field: 'sub_account_manager', headerName: 'Account Manager', flex: 1, hide: false },
  { field: 'dv3_advertisers', headerName: "DV3 Advertisers", flex: 1, hide: false },
  { field: 'dashboard_link', headerName: "Dashboard Link", flex: 1, hide: false },
  { field: 'dashboard_name', headerName: "Dashboard Name", flex: 1, hide: false },
  { field: 'dashboard_type', headerName: "Dashboard Type", flex: 1, hide: false },
];
export default function SubAccounts(props) {
  
  const { dateRangeHandler, dateValue } = props;
  
  const dispatch = useDispatch();
  const location = useLocation();
  
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [dateRange, setDateRange] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  if (location.state === null) dispatch(resetState()) 

  const subAccountSlice = useSelector(subAccounts);
  const connectedAccountSlice = useSelector(connectedAccount);

  const { account_id } = connectedAccountSlice;
  const {
    paused,
    createNew,
    existingSubAccounts,
  } = subAccountSlice;

  React.useEffect(() => {
    if (existingSubAccounts.length === 0) {
      if (account_id === null)      dispatch(loadSubAccounts())
      if (account_id === undefined) dispatch(loadSubAccounts())
    }
  }, [dispatch, account_id, existingSubAccounts]);

  const showCreateNew = !(account_id === undefined || account_id === null);

  return (
    paused ? <Spinner /> : 
    createNew ? <Create/> : 
    
    <React.Fragment>
      
      {/* Navigation Bar */}
      <div className={showCreateNew ? classes.TopNavBar : classes.TopNavBarEnd}>
        {showCreateNew && (
          <button
            className={classes.DVButtonGreen}
            onClick={() => dispatch(setCreateNew())}>
            Create New
          </button>
        )}
        <div className={classes.DateRangePicker}>
          <IconGeneric
            iconName={showCalendar ? icons.CROSS_ICON : icons.CALENDAR_ICON}
            iconProps={{
              onClick: () => setShowCalendar((prev) => !prev),
              className: showCalendar
                ? classes.CalendarButton_Checked
                : classes.CalendarButton,
            }}
          />
          {showCalendar && (
            <DateRangePicker
              className={classes.DateRangePickerCalendar}
              onDateChangeHandler={(item) =>
                (dateRangeHandler &&
                  dateRangeHandler({
                    value: [item.selection],
                    hideHandler: setShowCalendar,
                  })) ||
                setDateRange([item.selection])
              }
              dateRange={dateValue || dateRange}
            />
          )}
        </div>
      </div>

      {/* SubAccounts DataTable */}
      <DataTable
        schema={subAccountsSchema}
        checkboxSelection={false}
        data={subAccountSlice.existingSubAccounts}
      />
    </React.Fragment>
  )
}
