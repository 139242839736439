import React from 'react';
import classes from './Filter.module.scss';
import { icons } from '../icons/Icons';
import Icon from '../icons/Icons';

export default function Filter() {
  return (
    <div className={classes.Filter}>
      <p className={classes.FilterHeading}>Filter</p>
      <input className={classes.InputField}></input>
      <div className={classes.FilterIcon}>
        {Icon(icons.FILTER_ICON, {
          style: { stroke: 'black', strokeWidth: '5' },
        })}
      </div>
    </div>
  );
}
