import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { duplicateBIOs, getExistingAccounts } from './apis/api';
import { columns } from './schemas/bioSchema';
import { existingBIOViews } from '../config_shared/Constants/Constants';
import { backendKeys } from './apis/constants';

const initialState = {
  backendKeys: backendKeys,
  status: 'idle',
  bioSchema: columns,
  activeData: {
    activeNav: 'metatdata',
    data: [],
    activeSelections: [],
    selections: [],
    subviews: {
      viewType: existingBIOViews.VIEW_BIO,
    },
  },
  formData: {},
  bioAccounts: [],
  ros: [],
};

export const duplicateBusinessIO = createAsyncThunk(
  'config/duplicate_businessIOs',
  async (args, { getState }) => {
    const data = getState().configSlice;
    const selections = new Set(data.activeData.selections);
    const filteredData = data.activeData.data.filter((element) =>
      selections.has(element.id)
    );
    const response = await duplicateBIOs(filteredData);
    return response;
  }
);

export const getAccounts = createAsyncThunk('config/getAccounts', async () => {
  const response = await getExistingAccounts();
  return response;
});

export const configSlice = createSlice({
  name: 'configSlice',
  initialState,
  reducers: {
    updateRoutePath: (state, action) => {
      state.activeData.activeNav = action.payload;
    },

    cleanExistingAccounts: (state) => {
      Object.keys(state.backendKeys).forEach((element) => {
        state[state.backendKeys[element]] = [];
      });
    },

    updateSubView: (state, action) => {
      state.activeData.subviews.viewType = action.payload;
    },

    updateSelections: (state, action) => {
      state.activeData.selections = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(duplicateBusinessIO.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(duplicateBusinessIO.fulfilled, (state, action) => {
        state.activeData.data = Object.keys(action.payload).map((element) => ({
          ...action.payload[element],
          id: element,
        }));
        state.status = 'idle';
      })
      .addCase(duplicateBusinessIO.rejected, (state, action) => {
        console.log('Error occured: ', action);
        state.status = 'idle';
      });

    builder
      .addCase(getAccounts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        Object.keys(state.backendKeys).forEach((element) => {
          state[state.backendKeys[element]] = action.payload[element];
        });
        state.status = 'idle';
      })
      .addCase(getAccounts.rejected, (state, action) => {
        console.log('Error occured: ', action);
        state.status = 'idle';
      });
  },
});

export const {
  updateRoutePath,
  updateActiveRows,
  updateSelections,
  updateSubView,
  cleanExistingAccounts,
} = configSlice.actions;
export const configData = (state) => state.configSlice;

export default configSlice.reducer;
