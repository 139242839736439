import { configureStore } from '@reduxjs/toolkit';
import topNavBar from '../features/shared/slices/navBarSlice';
import advertiserFilters from '../features/view/slices/advertiserSlice';
import activeAdvertiser from '../features/view/slices/activeAdvertiserSlice';
import configSlice from '../features/config/slices/configSlice';
import dstream from '../features/config/slices/dataStream';
import connectMix from '../features/config/slices/connectMix';
import user from '../features/login-page/slices/userSlice';
import existingDStreams from '../features/config/slices/editExistingSlice';
import formulaes from '../features/config/slices/formulaVerifierSlice';
import accountsSlice from '../features/config/MetaDataSection/components/Accounts/components/Accounts/functionalUnit/slices/AccountSlice';
import subAccountsSlice from '../features/config/MetaDataSection/components/SubAccounts/functionalUnit/slices/subAccounts';
import connectedAccount from '../features/config/MetaDataSection/components/Accounts/functionalUnit/slices/ConnectedAccountSlice';
import ros from '../features/config/MetaDataSection/components/RO/functionalUnit/slices/ROS';
import subROs from '../features/config/MetaDataSection/components/RO/functionalUnit/slices/subROs';
import StrategyMappingReducer from '../features/config/Harmonize/StrategyMappingReducer/Reducer';
import CreativeMappingReducer from '../features/config/Harmonize/CreativeMappingReducer/Reducer';

export const store = configureStore({
  reducer: {
    topNavBar,
    advertiserFilters,
    activeAdvertiser,
    configSlice,
    dstream,
    connectMix,
    user,
    existingDStreams,
    formulaes,
    accountsSlice,
    subAccountsSlice,
    connectedAccount,
    ros,
    subROs,
    creativeMapping: CreativeMappingReducer,
    strategyMapping: StrategyMappingReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});
