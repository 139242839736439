import React from 'react';
import {
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';
import classes from '../DataTable/styles.module.scss';
import ToolBoxButtons from './ToolBoxButtons';
import { dataStreamActions } from '../../compoundFunctions/datastreamActions';
import { icons } from '../../../../shared/ui-components/icons/Icons';
import { useSelector } from 'react-redux';
import { dataStreams } from '../../../slices/dataStream';

const buttonProps = [
  {
    button_action_tag: dataStreamActions.ENABLE_DATA_STREAM,
    button_id: 'enable_datastream',
    button_text: 'Enable',
    button_icon: icons.CHECK_ICON,
    dispatch_function: true,
    payload_required: true,
    payload: true,
  },
  {
    button_action_tag: dataStreamActions.DISABLE_DATA_STREAM,
    button_id: 'disable_datastream',
    button_text: 'Disable',
    button_icon: icons.NOT_ALLOWED,
    dispatch_function: true,
    payload_required: true,
    payload: false,
  },
  {
    button_action_tag: dataStreamActions.EDIT_DATA_STREAM,
    button_id: 'edit_datastream',
    button_text: 'Edit',
    button_icon: icons.EDIT_ICON,
    dispatch_function: true,
  },
  {
    button_action_tag: dataStreamActions.LOG_DATA_STREAM,
    button_id: 'log_datastream',
    button_text: 'Log',
    button_icon: icons.BOOK_ICON,
  },
];
export default function CustomToolbar() {
  const data = useSelector(dataStreams);

  return (
    <GridToolbarContainer className={classes.ToolBarComponent}>
      <ToolBoxButtons
        buttonProps={buttonProps}
        disabledLengthZero={data.datastream_selections.length}
      />
      <GridToolbarExport className={classes.ToolBarExport} />
    </GridToolbarContainer>
  );
}
