import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { base_uri } from '../../../constants';
import axios from 'axios'

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  profilePic: '',
  status: 'idle',
  userSignedIn: false,
  userRole: 'Viewer',
};

export const verifyUserCredentials = createAsyncThunk(
  'userSlice/verifyUserCredentials',
  async session_token => {
    const request_configuration = {
      url     : `${base_uri}/oauth_clients`,
      method  : "GET",
      header  : { "Authorization" : `Bearer ${session_token}`},
      // data    : {}  // Change method => POST and uncomment to add post data if required for authentication
    }
    return await axios(request_configuration)
  }
)

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setEmail:       (state, action) => { state.email = action.payload },
    setFirstName:   (state, action) => { state.firstName = action.payload },
    setLastName:    (state, action) => { state.lastName = action.payload },
    setProfilePic:  (state, action) => { state.profilePic = action.payload },
    signInUser:     (state, action) => { state.userSignedIn = true },
    signOutUser:    (state, action) => { state.userSignedIn = false } ,
    storeUserToken: (state, action) => { localStorage.setItem('SESSION_TOKEN', action.payload) } 
  }, 
  extraReducers: builder => {
    builder
      .addCase(verifyUserCredentials.pending, state => { state.status = 'loading' } )
      .addCase(verifyUserCredentials.rejected, state => {
        state.status = 'idle';
        state.userSignedIn = false;
        alert(`Unable to verify User: ${state.email}`);
      })
      .addCase(verifyUserCredentials.fulfilled, state => { 
        state.userSignedIn = true; 
        state.status = 'idle'; 
      })
  }
});

export const {
  setEmail,
  setFirstName,
  setLastName,
  setProfilePic,
  signInUser,
  signOutUser,
  storeUserToken
} = userSlice.actions
export const userData = (state) => state.user;
export default userSlice.reducer;
