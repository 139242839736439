export const CREATIVE_MAPPING_COLUMNS = [
    "Creative_ID",
    "Creative",
    "Creative_Concept_1",
    "Creative_Concept_2",
    "Creative_Concept_3"
]

export const STRATEGY_MAPPING_COLUMNS = [
    "Line_Item",
    "Line_Item_ID",
    "Insertion_Order",
    "Insertion_Order_ID",
    "Campaign",
    "Campaign_ID",
    "Advertiser",
    "Advertiser_ID",
    "environment",		
    "creative_format",		
    "model",		
    "audience",		
    "channel",		
    "camapign_type",		
    "frequency",		
    "recency",		
    "income"
]

export const OPERATORS = [
    "CONTAINS",
    "NOT CONTAINS",
    "EQUALS",
    "NOT EQUALS"
]