import React from 'react'
import classes from './CreateFilter.module.scss'

import SetFilterWindow from '../SetFilterWindow';

function CreateFilter({
    setFilter,
    nameOptions = [],
    operatorOptions = []
}) {

    const [ showCreateFilterWindow, setShowCreateFilterWindow ] = React.useState(false)
    const toggleCreateFilterWindow = () => { setShowCreateFilterWindow( state => !state ) }

    return (
        <div className={ classes.CreateFilterButtonContainer }>
            <div 
                className = {classes.InstructionPrompt}
                onClick = { event => { event.preventDefault(); toggleCreateFilterWindow(); } }
            > Add Filter</div>
            <button
                className = {classes.ToggleButton}
                onClick = { event => { event.preventDefault(); toggleCreateFilterWindow() } }
            >
                { showCreateFilterWindow ? <div> &#x25B2; </div> : <div> &#x25BC; </div> }
            </button>
            { showCreateFilterWindow && 
                <SetFilterWindow 
                    setFilter = {setFilter}
                    nameOptions = {nameOptions}
                    operatorOptions = {operatorOptions}
                />
            }
        </div>
    )
}

export default CreateFilter
