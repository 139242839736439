import { CreativeMappingTableActionTypes } from "./Types";

export const CreativeMappingTableActions = {

    addFilter: filter => {
        const id = Math.floor(Math.random()*(999-100+1)+100);
        return {
            type: CreativeMappingTableActionTypes.ADD_FILTER,
            payload: { ...filter, id: id },
        }
    },

    deleteFilter: (id) => {
        return {
            type: CreativeMappingTableActionTypes.DELETE_FILTER,
            payload: id
        }
    }

}