import MetaData from '../../../../../../../slices/ui/schemaComponents/SchemaComponents';
import Icon, {
  icons,
} from '../../../../../../../../shared/ui-components/icons/Icons';
import { useDispatch } from 'react-redux';
import { loadSubAccountsSpecific, setAccountId as setSubAccountAccountId } from '../../../../../SubAccounts/functionalUnit/slices/subAccounts';
import { useHistory } from 'react-router-dom';
import { MetaDataRoutes } from '../../../../../../../../shared/frontendConstants/constants';
import { updateAccountId, fetchAccountDetails } from '../../../../functionalUnit/slices/ConnectedAccountSlice';
import { deleteAccount, fetchAccount, loadExistingAccounts, setAccountId, setEditMode, validateFormData } from '../slices/AccountSlice';

// Eye Icon in Accounts view page 
const EyeViewComponent = ({ row, classes }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RedirectToSubAccountHandler = ({ account_id }) => {
    
    dispatch(updateAccountId(account_id));
    dispatch(fetchAccountDetails(account_id));
    dispatch(loadSubAccountsSpecific(account_id));
    dispatch(setSubAccountAccountId(account_id));
 
    // Routing to Sub-Account Page
    history.push({
      pathname: MetaDataRoutes.subaccounts,
      state: { justPreserve: true },
    });
 
  };
  
  return (
    <div
      className={classes.PencilIcon}
      onClick={() => RedirectToSubAccountHandler({ account_id: row.id })}>
      {Icon(icons.VIEW_ICON_EYE)}
    </div>
  );
};

//
const EditComponent = ({ row, classes }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RedirectToAccountCreatePage = account_id => {
    
    // Handling Changes in AccountSlice
    dispatch(setAccountId(account_id));
    dispatch(fetchAccount(account_id));
    dispatch(validateFormData());
    dispatch(setEditMode(true));
    
    // Redirect to Accounts Route
    history.push({
      pathname: MetaDataRoutes.accounts,
      state: { justPreserve: true }
    })
  
  }

  return (
    <div 
      className={classes.PencilIcon}
      onClick = { event => { RedirectToAccountCreatePage(row.id) } }
    >
      {Icon(icons.EDIT_ICON)}
    </div>
  );
};

const DeleteComponent = ({row, classes}) => {

  const dispatch = useDispatch();
  const deleteAccountClickHandler = accountId => { 

    // Yes No Dialog Box
    const message = 'Are you sure you want to delete this Account ?'
    if (!window.confirm(message)) return;

    dispatch(deleteAccount(accountId));
    dispatch(loadExistingAccounts()); 
  }

  return (
    <div
      className={classes.PencilIcon}
      onClick = { event => { deleteAccountClickHandler(row.id) } }
    >
      {Icon(icons.DELETE_ICON)}
    </div>
  )

}

export const accountSchema = [
  {
    field: 'account_status',
    headerName: 'Status',
    flex: 0.45,
    hide: false,
    renderHeader: () => <div></div>,
    renderCell: (params) => {
      const active = params.row.account_status === 'Active';
      return (
        <MetaData
          active={active}
          params={params}
          viewComponentRequired
          ViewComponent={EyeViewComponent}
          editComponentRequired
          EditComponent={EditComponent}
          DeleteComponentRequired
          DeleteComponent={DeleteComponent}
          row={params.row}
          />
          );
        },  
      },
  { field: 'id', headerName: 'ID', flex: 0.25, hide: true },
  { field: 'account_name', headerName: 'Account', flex: 1.5, hide: false },
  { field: 'effective_start_date', headerName: 'Start Date', flex: 1, hide: false },
  { field: 'effective_end_date', headerName: 'End Date', flec: 1, hide: false },
  { field: 'account_type', headerName: 'Account Type', flex: 1, hide: false },
  { field: 'engagement_type', headerName: 'Engagement Type', flex: 1, hide: false },
  { field: 'account_director', headerName: 'Account Director', flex: 1, hide: false },
  { field: 'credit_limit', headerName: 'Credit Limit', flex: 1, hide: false },
  { field: 'payment_terms_in_days', headerName: 'Payment Term', flex: 1, hide: false },
  { field: 'billing_cycle', headerName: 'Billing Cycle', flex: 1, hide: false },
  { field: 'contract_no', headerName: 'Contract Number', flex: 1, hide: false },
];