import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateActiveBusinessIOIndex,
  updateViewType,
  activeAdv,
} from '../../slices/activeAdvertiserSlice';
import classes from './components.Module.scss';
import { withRouter } from 'react-router-dom';
function BusinessIOButton({ bioName, bioId, history }) {
  const dispatch = useDispatch();
  const advertiserId = useSelector(activeAdv);
  return (
    <div
      onClick={() => {
        dispatch(updateActiveBusinessIOIndex(bioId));
        dispatch(updateViewType('deepdive'));
        history.push(`/view/advertisers/${advertiserId}/deepdive`);
      }}
      className={classes.onTableButton}
      key={bioId}>
      {bioName}
    </div>
  );
}

export default withRouter(BusinessIOButton);
