import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { utils } from '../constants/constants';
import { updatedStatefromBilling } from '../functions/updateStateFromBilling';
import { defaultTabConfig } from '../functions/updateStateFromBilling';
import { validateForm } from '../../../../../../../../shared/utils/formValidator/formValidator';
import { deepmetrics_uri } from '../../../../../../../../../constants';
import axios from 'axios'
import { errorLogger } from '../../../../../../../../shared/utils/errorLogger/errorLogger';

// Initial State
const initialState = {
  account_id: '',
  status: 'idle',
  editMode: false,
  existing: utils.existingDefault,
  createNew: utils.createNew,
  pdc: utils.pdcData,
  errors: {},
  billingSlabVerification: false,
  valid: false,
};

// Load Existing Accounts
export const loadExistingAccounts = createAsyncThunk(
  'accounts/loadExistingAccounts',
  async () => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/account/`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}`},
      // data    : {}  // Change method => POST and uncomment to add post data if additional arguments required
    }
    const response = await axios(request_configuration)
    return response.data;
  }
);

// Delete Account
export const deleteAccount = createAsyncThunk(
  'accounts/deleteAccount',
  async accountId => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/account/${accountId}`,
      method  : "DELETE",
      headers : { "Authorization" : `Bearer ${session_token}` }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Save Account
export const createNewAccount = createAsyncThunk(
  'accounts/createNewAccount',
  async ( _, { getState } ) => {
    const accountState = getState().accountsSlice
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url       : `${deepmetrics_uri}/account/`,  
      method    : "POST",
      headers   : { "Authorization" : `Bearer ${session_token}`},
      data      : {
        account_name: accountState.createNew.name,
        account_type: accountState.createNew.type,
        account_status: "Active",
        account_currency: accountState.createNew.currency,
        engagement_type: accountState.createNew.engagement,
        account_director: accountState.createNew.director,
        credit_limit: accountState.createNew.credit_limit,
        payment_terms_in_days: accountState.createNew.paymentTerm,
        billing_cycle: accountState.createNew.billingCycle,
        contract_no: accountState.createNew.contractDetails.contractName,
        effective_start_date: accountState.createNew.contractDetails.effectiveDates.from,
        effective_end_date: accountState.createNew.contractDetails.effectiveDates.to,
        has_pdc: accountState.pdc.isRequired,
        pdc: accountState.pdc.isRequired ? accountState.pdc.pdcs.map(pdc => ({
          pdc_issue_date: pdc.issueDate,
          pdc_expiry_date: pdc.expiryDate,
          pdc_number: pdc.pdcNumber,
          pdc_amount: pdc.amount,
          account_id: 0
        })) : []
      }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Fetch Account
export const fetchAccount = createAsyncThunk(
  'accounts/fetchAccount',
  async (account_id, { getState } ) => {
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/account/${account_id}`,
      method  : "GET",
      headers : { "Authorization" : `Bearer ${session_token}` },
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// Edit Account
export const editAccount = createAsyncThunk(
  'accounts/editAccount',
  async (account_id, { getState }) => {
    const accountState = getState().accountsSlice;
    const session_token = localStorage.getItem('SESSION_TOKEN')
    const request_configuration = {
      url     : `${deepmetrics_uri}/account/${account_id}`,
      method  : "PUT",
      headers : { "Authorization" : `Bearer ${session_token}` },
      data    : {
        account_name: accountState.createNew.name,
        account_type: accountState.createNew.type,
        account_status: "Active",
        account_currency: accountState.createNew.currency,
        engagement_type: accountState.createNew.engagement,
        account_director: accountState.createNew.director,
        credit_limit: accountState.createNew.credit_limit,
        payment_terms_in_days: accountState.createNew.paymentTerm,
        billing_cycle: accountState.createNew.billingCycle,
        contract_no: accountState.createNew.contractDetails.contractName,
        effective_start_date: accountState.createNew.contractDetails.effectiveDates.from,
        effective_end_date: accountState.createNew.contractDetails.effectiveDates.to,
        has_pdc: accountState.pdc.isRequired,
        id: account_id,
        pdc: accountState.pdc.isRequired ? accountState.pdc.pdcs.map(pdc => ({
          pdc_issue_date: pdc.issueDate,
          pdc_expiry_date: pdc.expiryDate,
          pdc_number: pdc.pdcNumber,
          pdc_amount: pdc.amount,
          id: pdc.pdcId,
          account_id: account_id,
        })) : []
      }
    }
    const response = await axios(request_configuration)
    return response.data;
  }
)

// export const loadDataOfSelectedAccount = createAsyncThunk(
//   'accounts/loadDataOfSelectedAccount',
//   async(payload) => {}
// );

export const accountsSlice = createSlice({
  name: 'metadata_accountSlice',
  initialState,
  reducers: {

    // Reset account details
    resetAccount: () => initialState,
    
    // Set account_id
    setAccountId: (state, action) => { state.account_id = action.payload },

    // Set Edit Mode
    setEditMode: (state, action) => { state.editMode = action.payload; },

    // Update form data
    updateFormData: (state, action) => {
      state.createNew[action.payload.key] = action.payload.value;
      const { errors, allGood } = validateForm({
        values: state.createNew,
        fields: utils.validationFields,
        miscProps: {},
      });
      state.errors = errors;
      state.valid = allGood;
    },

    // Validate Form Data
    validateFormData: state => {

      // state.createNew is a Javascript Proxy object and 
      const valuesToValidate = { ...state.createNew };
      valuesToValidate.billingModelSlabs = { ...state.createNew.billingModelSlabs }
      valuesToValidate.contractDetails = { ...state.createNew.contractDetails }
      const { errors, allGood } = validateForm({
        values: valuesToValidate,
        fields: utils.validationFields,
        miscProps: {},
      });
      state.errors = errors;
      state.valid = allGood;
    },

    // Update nested contract data
    updateNestedContractData: (state, action) => {
      state.createNew.contractDetails[action.payload.key] = action.payload.value;
    },

    // Update pdc required
    updatePDCRequired: (state, action) => {
      const { value } = action.payload;
      state.pdc.isRequired = value;
    },

    // Update pdc data
    updatePDCData: (state, action) => {
      const { index, value } = action.payload;
      state.pdc.pdcs[index] = value;
      const { errors, allGood } = validateForm({
        values: state.createNew,
        fields: utils.validationFields,
        miscProps: {
          pdcData: value,
          customErrorMessage: true,
          index: index,
        },
      });
      state.errors = errors;
      state.valid = allGood;
    },

    // add new pdc data
    addNewPDCData: (state) => {
      state.pdc.pdcs.push({
        issueDate: 'Issue Date',
        expiryDate: 'Expiry Date',
        amount: 0,
        issueDateType: 'text',
        expiryDateType: 'text',
      });
    },

    // remove pdc
    removePDC: (state, action) => {
      const { index } = action.payload;
      state.pdc.pdcs.splice(index, 1);
    },

    // Update billing models
    updateBillingModels: (state, action) => {
      state.createNew.billingModelSlabs = updatedStatefromBilling(
        state.createNew.billingModelSlabs,
        action.payload
      );
    },

    // add first billing model
    addFirstBillingModel: (state) => {
      state.createNew.billingModelSlabs = {
        '': { ...defaultTabConfig },
      };
    },

    // Update slab name
    updateSlabName: (state, action) => {
      state.createNew.billingModelSlabs[action.payload.key].slabs[
        action.payload.index
      ].slabName = action.payload.value;
    },

    // Update dropdown billing model
    updateDropDownValueBillingModel: (state, action) => {
      const { modelKey, dropdownKey, slabIndex, value } = action.payload;
      state
        .createNew
        .billingModelSlabs[modelKey]
        .slabs[slabIndex][dropdownKey] = value;
    },

    // remove existing slabs
    removeExistingSlabs: (state, action) => {
      const { modelKey, slabIndex } = action.payload;
      state.createNew.billingModelSlabs[modelKey].slabs.splice(slabIndex, 1);
      if (state.createNew.billingModelSlabs[modelKey].slabs.length === 0) {
        delete state.createNew.billingModelSlabs[modelKey];
      }
    },

    // add new slab
    addNewSlab: (state, action) => {
      const { modelKey } = action.payload;
      state.createNew.billingModelSlabs[modelKey].slabs.push(
        defaultTabConfig.slabs[0]
      );
    },
  },

  extraReducers: (builder) => {
    // Load Existing Accounts
    builder
      .addCase(loadExistingAccounts.pending, (state) => { state.status = 'loading' })
      .addCase(loadExistingAccounts.fulfilled, (state, action) => {
        state.existing.existingAccounts.data = action.payload;
        state.existing.fetched = true;
        state.status = 'idle';
      })
      .addCase(loadExistingAccounts.rejected, (state, action) => {
        alert(`Could not load existing accounts`)
        console.log('Could not load existing accounts', action);
        state.status = 'idle';
      })

      // Save New Account
      builder
        .addCase(createNewAccount.pending, state => { state.status = 'loading' })
        .addCase(createNewAccount.fulfilled, state => { 
          alert(`Account Saved `)
          state.status = 'idle' 
        })
        .addCase(createNewAccount.rejected, (state, action) => {
          alert(`Error occured while saving Account`)
          console.log(`Error occured while saving Account`, action)
          state.status = 'idle'
        })

      // Delete Account
      builder
        .addCase(deleteAccount.pending, (state) => { state.status = 'loading' })
        .addCase(deleteAccount.fulfilled, state => {
          alert("Account Deleted Successfully");
          state.status = "idle";
        })
        .addCase(deleteAccount.rejected, (state, action) => {
          state.status = "idle";
          alert("Error occured while deleting Account");
          errorLogger(action, "Error Observed while deleting account")
        })

      // Edit Existing Account
      builder
        .addCase(editAccount.pending, state => { state.status = 'loading' })
        .addCase(editAccount.fulfilled, state => { 
          alert(`Account Edited`)
          state.status = 'idle' 
        })
        .addCase(editAccount.rejected, (state, action) => {
          alert(`Error occured while Editing Account`)
          console.log(`Error occured while Editing Account`, action)
          state.status = 'idle'
        })
        
      // load Account
      builder
        .addCase(fetchAccount.pending, state => { state.status = 'idle' })
        .addCase(fetchAccount.fulfilled, (state, action) => {
          const fetchedAccount = action.payload;

          // Input Received data
          state.createNew = {
            name: fetchedAccount.account_name,
            director: fetchedAccount.account_director,
            type: fetchedAccount.account_type,
            currency: fetchedAccount.account_currency,
            engagement: fetchedAccount.engagement_type,
            credit_limit: fetchedAccount.credit_limit,
            billingCycle: fetchedAccount.billing_cycle,
            paymentTerm: fetchedAccount.payment_terms_in_days,
            contractDetails: {
              contractName: fetchedAccount.contract_no,
              effectiveDates: {
                from: fetchedAccount.effective_start_date,
                to: fetchedAccount.effective_end_date
              },
              evaluationDate: fetchedAccount.effective_end_date,
            },
            billingModelSlabs: {},
          };
          state.pdc = {
            isRequired: fetchedAccount.has_pdc,
            pdcs: fetchedAccount.pdc.map(pdc => ({
              issueDate: pdc.pdc_issue_date,
              expiryDate: pdc.pdc_expiry_date,
              pdcNumber: pdc.pdc_number,
              amount: pdc.pdc_amount,
              pdcId: pdc.id,
            })),
          };

          // Validate Recieved Data
          const { errors, allGood } = validateForm({
            values: state.createNew,
            fields: utils.validationFields,
            miscProps: {},
          });
          state.errors = errors;
          state.valid = allGood;

        })
        .addCase(fetchAccount.rejected, (state, action) => {
          alert(`Error Fetching Requested Account`)
          console.log(action.payload)
          state.status = 'idle'
        })
  },
});

// Exports
export const {
  resetAccount,
  setAccountId,
  setEditMode,
  updateFormData,
  validateFormData,
  updateNestedContractData,
  updatePDCData,
  updateBillingModels,
  addFirstBillingModel,
  updateSlabName,
  updateDropDownValueBillingModel,
  removeExistingSlabs,
  addNewSlab,
  addNewPDCData,
  removePDC,
  updatePDCRequired,
} = accountsSlice.actions;

export const metaDataAccounts = (state) => state.accountsSlice;
export default accountsSlice.reducer;
