import React from 'react';
import classes from './navbar.module.scss';
import { Link } from 'react-router-dom';

export default function NavigationBar({
  buttonsArray,
  activeButton,
  buttonClickHandler,
}) {
  return (
    <div className={classes.NavBarLabels}>
      {Object.keys(buttonsArray).map((ele) => {
        const element = buttonsArray[ele];
        return (
          <Link
            key={element.id}
            className={
              ele === activeButton ? classes.ActiveMode : classes.PassiveMode
            }
            to={element.uri || '/'}
            onClick={() => buttonClickHandler(ele)}>
            {element.label}
          </Link>
        );
      })}
    </div>
  );
}
