import React from 'react';
import { withRouter } from 'react-router-dom';
import classes from './components.Module.scss';
import { FRONTEND_ROUTES } from '../../../shared/backend/frontendRoutes';
function AdvertiserPageRedirector({ getActiveId, advertiserName, history }) {
  return (
    <div
      className={classes.onTableButton}
      onClick={() => {
        const id = getActiveId();
        return history.push(`${FRONTEND_ROUTES.ADVERTISERS_PAGE}/${id}/bio`);
      }}>
      {advertiserName}
    </div>
  );
}

export default withRouter(AdvertiserPageRedirector);
