import { endpoints } from './constants';

// export const bqDataStreams = async () => {
//   const data = await fetch(endpoints.GET_BQ_DATASTREAMS);
//   return data.json();
// };

export const bqDataStreams = async (payload) => {
  const data = await fetch(endpoints.GET_BQ_DATASTREAMS, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_BACKEND_API_KEY,
    },
    body: JSON.stringify(payload),
  });
  return data.json();
};

export const datastreamHeaders = async payload => {
  const data = await fetch(endpoints.GET_DATASTREAM_HEADERS, {
    method: 'POST',
    headers:  {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_BACKEND_API_KEY,
    },
    body: JSON.stringify(payload)
  })
  return data.json()
}

export const createdStream = async (payload) => {
  const data = await fetch(endpoints.CLOUD_FUNCTION_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return data.json();
};

export const formulaOperate = async (payload) => {
  const data = await fetch(endpoints.FORMULA_VERIFIER_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return data.json();
};
