export const activeViewMap = {
  business_io: 'Name',
  status: 'Status',
  start_date: 'Start Date',
  end_date: 'End Date',
  budget: 'Budget',
  spend: 'Spend',
  nrr: 'NRR',
  kpi_1_goalType: 'Goal Type',
  kpi_1_goal: 'KPI 1 Goal',
  kpi_1: 'KPI 1 Current',
  kpi_score: 'KPIS Score',
  margin: 'Margin',
};
