import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMasterList } from './apis/api';
import { bqDataStreams, createdStream } from './apis/bqApis';
import { verifierFunction } from './compoundFunctions/formVerifier';
import { verificationObjects } from './constants/verifications';
import { existingSettings } from './constants/initStates';
import { getDataStreams, resetToInitialState } from './dataStream';
import { errorLogger } from '../../shared/utils/errorLogger/errorLogger';

const initialState = {
  status: 'idle',
  masterList: [],
  headersList: [],
  modalErrors: {
    showModal: false,
    error: false,
    msg: 'Unexpected Error Occured. :(',
  },
  errors: { touched: false, errors: {}, isFormValid: false },
  mappingConfiguration: {
    activeMasterHeader: null, //Active UI Selection
    mappings: {}, //{masterListHeader: {formula:'formula',alias: 'alias'}}
  },
  datastreamConfigSettings: existingSettings.datastream_config,
};

export const getMasterListofDimensions = createAsyncThunk(
  'connectMix/getMasterListofDimensions',
  async () => {
    const response = await getMasterList();
    return response;
  }
);

export const getExisting = createAsyncThunk(
  'datastreams/getExisting',
  async (external_payload, { getState }) => {
    const selected_dstream_id =
      external_payload.datastream_id ??
      getState().dstream.datastream_selections[0];
    const payload = {
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
      operationType: 'update_mapping',
      operationProps: {
        datastream_id: selected_dstream_id,
        methodType: 'GET',
      },
    };
    const response = await createdStream(payload);

    if (response.error) {
      console.log(`%cError happened: `, 'font-weight:bold;color:red;');
      console.log(response);
    }

    return response;
  }
);

export const updateMapping = createAsyncThunk(
  'connectMix/updateMapping',
  async (_, { getState, dispatch }) => {
    const datastream_id = getState().dstream.createdataStream.name;
    const formulas = getState().connectMix.mappingConfiguration.mappings;
    const configurations = getState().connectMix.datastreamConfigSettings;
    const payload = { datastream_id, formulas, configurations };
    const response = await createdStream({
      operationProps: payload,
      operationType: 'update_mapping',
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
    });
    if (!response.error) {
      dispatch(resetToInitialState());
      dispatch(getDataStreams());
    } else {
      console.log(`%cError happened: `, 'font-weight:bold;color:red;');
      console.log(response);
    }
    return response;
  }
);

export const getexistingNetworks = createAsyncThunk(
  'connectMix/getexistingNetworks',
  async () => {
    const response = await bqDataStreams({ key: 'accounts' });
    return response;
  }
);

export const connectMix = createSlice({
  name: 'connectMix',
  initialState,
  reducers: {
    updateDataHeaderList: (state, action) => {
      state.headersList = action.payload.map((element, id) => ({
        id: id,
        value: element,
      }));
    },
    updateModalError: (state, action) => {
      state.modalErrors.showModal = action.payload.booleanValue;
      state.modalErrors.msg =
        action.payload.msg || 'Unexpected Error Occured. :( [Reset]';
    },

    resetInitState: () => initialState,
    resetFormState: (state) => {
      state.datastreamConfigSettings = existingSettings.datastream_config;
    },
    updateFormData: (state, action) => {
      state.datastreamConfigSettings[action.payload.key] = action.payload.value;
      state.errors.touched = true;
      state.errors.errors = verifierFunction(
        verificationObjects.connectMix,
        state.datastreamConfigSettings
      );

      state.errors.isFormValid = Object.values(state.errors.errors).reduce(
        (acc, curr) => {
          acc = acc && !curr.error;
          return acc;
        },
        true
      );
    },

    updateUserMapping: (state, action) => {
      state.mappingConfiguration.mappings[action.payload.masterListHeader] = {
        alias: action.payload.masterListHeader,
        formula: action.payload.formulaString,
      };
      state.mappingConfiguration.activeMasterHeader =
        action.payload.masterListHeader;
    },

    resetMappingConfiguration: (state, action) => {
      if (action.payload) {
        state.mappingConfiguration.mappings = action.payload;
      } else state.mappingConfiguration.mappings = {};
    },
    preserveOriginalMapping: (state, action) => {
      state.originalMapping = action.payload;
    },

    updateMasterListMapping: (state, action) => {
      const keyToLookFor = 'UNDEFINED';
      const isUndefinedPresent = Object.keys(
        state.mappingConfiguration.mappings
      ).includes(keyToLookFor);

      if (isUndefinedPresent) {
        const newData = state.mappingConfiguration.mappings;
        const newObject = newData[keyToLookFor];
        delete newData[keyToLookFor];
        newData[action.payload.masterKey] = newObject;
        newData[action.payload.masterKey].alias = action.payload.masterKey;
      } else {
        const data = state.mappingConfiguration.mappings;
        //delete data[action.payload.masterKey];
        state.mappingConfiguration.mappings = {
          [action.payload.masterKey]: {
            formula: 'UNDEFINED',
            alias: 'UNDEFINED',
          },
          ...data,
        };
      }
      state.mappingConfiguration.activeMasterHeader = action.payload.masterKey;
    },

    updateActiveSelector: (state, action) => {
      state.mappingConfiguration.activeMasterHeader = action.payload;
    },
    updateFormula: (state, action) => {
      state.mappingConfiguration.mappings[action.payload.header].formula =
        action.payload.value;
    },

    updateAlias: (state, action) => {
      state.mappingConfiguration.mappings[action.payload.header].alias =
        action.payload.value;
    },

    removeSpecificKey: (state, action) => {
      const data = state.mappingConfiguration.mappings;
      delete data[action.payload];
      state.mappingConfiguration.mappings = data;
      const keys = Object.keys(data);
      state.mappingConfiguration.activeMasterHeader =
        keys.length === 0 ? null : keys[0];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExisting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getExisting.fulfilled, (state, action) => {
        if (action.payload.error) {
          alert(
            `Operation Failed: ${action.payload.msg}, Description: ${action.payload.payload.description}`
          );
          state.status = 'idle';
        } else {
          if (!action.meta.arg?.const_payload?.updateMappingOnly) {
            //state.datastream_id = action.payload.payload.datastream_id;
            state.datastreamConfigSettings =
              action.payload.payload.datastream_config;
          }
          state.mappingConfiguration.mappings =
            action.payload.payload.formula_config;

          state.status = 'idle';
        }
      })
      .addCase(getExisting.rejected, (state, action) => {
        console.log('Error happened: ', action);
        state.status = 'rejected';
      });
    builder
      .addCase(getMasterListofDimensions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMasterListofDimensions.fulfilled, (state, action) => {
        state.masterList = action.payload.map((element, id) => ({
          id: id,
          value: element,
        }));
        state.status = 'idle';
      })
      .addCase(getMasterListofDimensions.rejected, (state, action) => {
        console.log('Error happened: ', action);
        state.status = 'rejected';
      });

    builder
      .addCase(getexistingNetworks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getexistingNetworks.fulfilled, (state, action) => {
        const { error, msg, payload } = action.payload;
        if (!error) {
          state.datastreamConfigSettings.existingNetworks = payload;
        } else errorLogger(action, `Error Caused in getDataStreams: ${msg}`);
        state.status = 'idle';
      })
      .addCase(getexistingNetworks.rejected, (state, action) => {
        console.log('Error Happened: ', action);
        state.status = 'rejected';
      });

    builder
      .addCase(updateMapping.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMapping.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(updateMapping.rejected, (state, action) => {
        console.log('Error Happened: ', action);
        state.status = 'rejected';
      });
  },
});
export const {
  updateDataHeaderList,
  updateUserMapping,
  updateMasterListMapping,
  updateActiveSelector,
  removeSpecificKey,
  updateFormula,
  updateAlias,
  resetInitState,
  updateFormData,
  updateModalError,
  resetFormState,
  resetMappingConfiguration,
  preserveOriginalMapping,
} = connectMix.actions;
export default connectMix.reducer;
export const connectMixData = (state) => state.connectMix;
