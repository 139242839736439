import React from 'react';
import classes from './LabelSelect.module.scss';
import Select from 'react-select';

const customStyles = {
  control: (base) => ({
    ...base,
    background: 'transparent',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};

export default function ReactSelectWithLabel(props) {
  const { label, options, value, onChangeHandler } = props;
  return (
    <div className={classes.RowComponent}>
      <p className={classes.RowLabel}>{label}</p>
      <Select
        options={options}
        className={classes.MinSelectClass}
        value={value}
        onChange={onChangeHandler}
        styles={customStyles}
      />
    </div>
  );
}
