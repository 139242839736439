import React from 'react';
import classes from './DataStream.module.scss';
import InputBoxText from '../../../shared/ui-components/InputBoxGroup/InputText/InputText';
import InputSpecificForm from './InputSpecificForms/InputSpecificForms';
import FrequencyBox from './FrequencySpecificForm/Frequency';
import { getexistingNetworks } from '../../slices/connectMix';
import { useDispatch } from 'react-redux';

import LabelSelect from '../../../shared/ui-components/LabelSelect/LabelSelect';
import LabelSelectCreate from '../../../shared/ui-components/LabelSelect/LabelSelectAndCreate';
import Spinner from '../../../shared/ui-components/spinner/spinner';
import { availableOptions, optionsMap } from './constants/InputConstants';

const hideFrequencyBoxFor = [optionsMap['Gmail Push']];

export default function DataStreamForm(props) {
  const {
    data,
    existingData,
    streamName,
    created,
    errors_dstream,
    updateDataStreamName,
    updateFormData,
  } = props;
  const dispatch = useDispatch();
  const formData = data.datastreamConfigSettings;

  const errors_connectMix = data.errors;
  // const errors_dstream = dstreamData.errors;

  let clientSourceIndex = availableOptions.findIndex(
    (element) => element.value === (formData.client_source || '')
  );
  if (clientSourceIndex === -1) clientSourceIndex = 0;

  const existingNetworks = formData.existingNetworks
    ? data.datastreamConfigSettings.existingNetworks.map((element) => ({
        value: element.network_id,
        label: element.network_name,
      }))
    : [];
  let selectedNetworkIndex = existingNetworks.findIndex(
    (element) => element.value === formData.network_group
  );
  let selectedNetwork = {
    label: formData.network_group,
    value: formData.network_group,
  };
  if (selectedNetworkIndex !== -1)
    selectedNetwork = existingNetworks[selectedNetworkIndex];

  React.useEffect(() => {
    dispatch(getexistingNetworks());
  }, [dispatch]);
  const isLoading = data.status === 'loading';

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <div className={classes.DataStreamFormLayout}>
        <InputBoxText
          inputHeaderName={'DataStream Name'}
          isRequired
          showHint
          value={streamName}
          showError
          customDisabler={created || existingData.inEditMode}
          errorProps={{ errors: errors_dstream, key: 'name' }}
          onChangeHandler={(value) => dispatch(updateDataStreamName(value))}
        />
        <InputBoxText
          inputHeaderName={'Notification Email'}
          isRequired
          showHint
          showError
          errorProps={{ errors: errors_connectMix, key: 'datastream_email' }}
          value={formData.datastream_email}
          onChangeHandler={(value) =>
            dispatch(updateFormData({ key: 'datastream_email', value: value }))
          }
        />
        <InputBoxText
          inputHeaderName={'Attribute'}
          isRequired
          showHint
          showError
          errorProps={{
            errors: errors_connectMix,
            key: 'datastream_attribute_1',
          }}
          value={formData.datastream_attribute_1}
          onChangeHandler={(value) =>
            dispatch(
              updateFormData({ key: 'datastream_attribute_1', value: value })
            )
          }
        />

        <LabelSelect
          label={'Datastream Source'}
          options={availableOptions}
          value={availableOptions[clientSourceIndex]}
          onChangeHandler={(value) => {
            dispatch(
              updateFormData({ key: 'client_source', value: value.value })
            );
          }}
        />

        <InputSpecificForm
          inputType={formData.client_source}
          errors={errors_connectMix}
          data={data}
          updateFormData={updateFormData}
        />

        {/* {          <InputBoxText
            inputHeaderName={'Workbook Sheet Name'}
            isRequired
            showHint
            value={formData.workbook_sheet_name}
            showError
            errorProps={{
              errors: errors_connectMix,
              key: 'workbook_sheet_name',
            }}
            onChangeHandler={(value) =>
              dispatch(
                updateFormData({ key: 'workbook_sheet_name', value: value })
              )
            }
          />} */}

        <LabelSelectCreate
          label={'Datastream Group'}
          options={existingNetworks}
          value={[selectedNetwork]}
          onChangeHandler={(value) => {
            dispatch(
              updateFormData({ key: 'network_group', value: value.label })
            );
          }}
        />
        {hideFrequencyBoxFor.includes(formData.client_source) ? null : (
          <FrequencyBox />
        )}
      </div>
    </React.Fragment>
  );
}
