import React from 'react';
import classes from './Header.module.scss';
import TopNavBars from '../TopNavBars/TopNavBars';
import { navBarButtons } from '../../../frontendConstants/constants';

export default function Header() {
  return (
    <header className={[classes.header, classes.navBarTabs].join(' ')}>
      <img
        className={classes.Logo}
        src={process.env.PUBLIC_URL + '/deepvu.png'}
        alt={'yoptima_logo'}
      />
      <TopNavBars classes={classes} buttonsArray={navBarButtons} />
    </header>
  );
}
