import React from 'react';
import DataTable from '../../../../../../shared/ui-components/rowGenerator/UIcomponents/DataTable';
import { businessIOConfig as bioschema } from '../../../../../constants/BusinessIOSchema';
import { deepDiveSchema as deepDiveschema } from '../../../../../constants/DeepDiveSchema';

import {
  businessIOData,
  activeViewType,
  deepDiveData,
} from '../../../../../slices/activeAdvertiserSlice';
import { useSelector } from 'react-redux';
export default function Table() {
  const businessIOs = useSelector(businessIOData);
  const deepDive = useSelector(deepDiveData);

  const activeView = useSelector(activeViewType);
  let rows, schema;
  if (activeView === 'bio') {
    rows = businessIOs;
    schema = bioschema;
  } else if (activeView === 'deepdive') {
    rows = deepDive;
    schema = deepDiveschema;
  }
  return <DataTable rowsData={rows} tableSchema={schema} />;
}
