import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formulaOperate } from './apis/bqApis';

const initKeyState = {
  data: {},
  env: {},
};

const initialState = {
  status: 'idle',
  keys: ['data', 'env'],
  data: initKeyState,
  allGood: false,
  msg: 'No Inputs have yet been verified',
  backendSuccess: false,
  backendMsg: 'Verification not yet started',
  verificationRequested: false,
  backendOutput: {},
};

export const verifyFormulaes = createAsyncThunk(
  'formulaVerification/verifyFormulaes',
  async (payload) => {
    const response = await formulaOperate(payload);
    return response;
  }
);

export const formulaVerifier = createSlice({
  name: 'formulaVerifier',
  initialState,
  reducers: {
    setRequiredData: (state, action) => {
      // let result = { ...initKeyState };
      Object.keys(initKeyState).forEach((element) => {
        state.data[element] = initKeyState[element];
      });
      Object.keys(action.payload).forEach((element) => {
        //const prevState = state.data[element] || {};
        //state.data[element] = { ...prevState, ...action.payload[element] };
        state.data[element] = action.payload[element];
      });
    },
    updateTextField: (state, action) => {
      state.data[action.payload.primaryKey][action.payload.secondaryKey] =
        action.payload.value;
      let decision = true;
      Object.keys(state.data).forEach((segment) => {
        Object.values(segment).forEach((element) => {
          decision = decision && element !== true;
        });
      });
      state.allGood = decision;
    },
    resetKeys: (state) => {
      state.data = initialState;
    },
    resetBackendVerification: (state) => {
      state.verificationRequested = false;
      state.backendOutput = {};
    },

    resetFormulaVerification: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyFormulaes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(verifyFormulaes.fulfilled, (state, action) => {
        state.backendOutput = action.payload.payload;
        state.backendSuccess = !action.payload.error;
        state.verificationRequested = true;
        state.backendMsg = action.payload.error
          ? 'Invalid Formulae(s)! Verification Failed.'
          : 'Formulaes Validated successfully';
        state.status = 'idle';
      })
      .addCase(verifyFormulaes.rejected, (state, action) => {
        console.log('Action rejected = ', action);
        state.status = 'idle';
      });
  },
});
export const {
  setRequiredData,
  updateTextField,
  resetBackendVerification,
  resetFormulaVerification,
} = formulaVerifier.actions;
export default formulaVerifier.reducer;
export const formulaes = (state) => state.formulaes;
