import React from 'react';

import classes from '../DataTable/styles.module.scss';
import { IconGeneric } from '../../../../shared/ui-components/icons/Icons';
import { dataStreamOps } from '../../compoundFunctions/datastreamActions';
import { useDispatch } from 'react-redux';

export default function ToolBoxButtons(props) {
  const { buttonProps, disabledLengthZero } = props;
  const dispatch = useDispatch();
  const notDefinedCallback = React.useCallback((value) => { alert('Feature is not supported for: ', value); }, []);
  
  return buttonProps.map((element) => (
    <div
      key={element.button_id}
      className={
        disabledLengthZero === 0
          ? [classes.RowButton_MUI, classes.RowButton_MUI__disabled].join(' ')
          : classes.RowButton_MUI
      }
      disabled={disabledLengthZero === 0}
      onClick={() => {
        const const_payload = element.payload_required ? element.payload : {};
        const payload = {
          button_tag: element.button_action_tag,
          const_payload: const_payload,
        };
        if (disabledLengthZero > 0) {
          dataStreamOps[element.button_action_tag]
            ? element.dispatch_function
              ? dispatch(dataStreamOps[element.button_action_tag](payload))
              : dataStreamOps[element.button_action_tag](payload)
            : notDefinedCallback(element.button_id);
        }
      }}>
      <IconGeneric
        iconName={element.button_icon}
        iconProps={{ className: classes.RowButton_MUI__icon }}
      />
      <p className={classes.ButtonDisplay}>{element.button_text}</p>
    </div>
  ));
}
