import React from 'react';
import classes from './ConfigHeaders.module.scss';
import { icons, IconGeneric } from '../../../shared/ui-components/icons/Icons';

export default function ConfigHeaders({
  configDataHeader,
  iconName,
  iconProps,
  headers,
  enableMappingView,
  onOptionClickHandler,
}) {
  iconProps = iconProps || {};
  const [searchParam, setSearchParam] = React.useState('');
  return (
    <div className={classes.ConnectMix__ConfigData}>
      <div className={classes['ConnectMix__ConfigData--header']}>
        <div className={classes['ConnectMix__ConfigData--header--icon']}>
          <IconGeneric
            iconName={iconName || icons.FINGER_PRINT_ICON}
            iconProps={{
              size: 22,
              ...iconProps,
            }}
          />
        </div>
        <p>
          {configDataHeader}{' '}
          {enableMappingView ? <span>0/{headers.length} mapped</span> : null}
        </p>
      </div>
      <div className={classes.ConnectMix__ConfigData___FiltersRow}>
        {/* { <button
          className={classes['ConnectMix__ConfigData___FiltersRow--inActive']}>
          Mapped
        </button>
        <button
          className={classes['ConnectMix__ConfigData___FiltersRow--inActive']}>
          Unmapped
        </button>} */}
      </div>
      <div className={classes.ConnectMix__ConfigData___SearchBox}>
        <input
          className={classes['ConnectMix__ConfigData___SearchBox--input']}
          placeholder={'Search or quickly Add Formula...'}
          value={searchParam}
          onChange={(event) => setSearchParam(event.target.value)}
        />
        <IconGeneric
          iconName={icons.SEARCH_ICON}
          iconProps={{
            className: classes['ConnectMix__ConfigData___SearchBox--icon'],
          }}
        />
      </div>
      {headers === undefined ? (
        <p>No Headers to Configure</p>
      ) : (
        <div
          className={[classes.MasterDimensionList, classes.ScrollBar].join(
            ' '
          )}>
          {headers
            .filter((ele) => {
              const lookup = searchParam.replace(/ |,/g, '');
              return (
                lookup === '' ||
                ele.value.toLowerCase().includes(searchParam.toLowerCase())
              );
            })
            .map((element) => (
              <p
                key={element.id}
                className={classes.ActiveHeaders}
                onClick={
                  onOptionClickHandler
                    ? () => onOptionClickHandler(element.value)
                    : () => console.log('config not done')
                }>
                {element.value}
              </p>
            ))}
        </div>
      )}
    </div>
  );
}
