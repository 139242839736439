import React from 'react';
import classes from './DateRangeSelector.module.scss';
import {
  formatDate,
  DateFormatTypes,
} from '../../../../frontendConstants/converters';

export const initDates = {
  from: new Date(),
  to: new Date(new Date().setDate(new Date().getDate() + 7)),
};

export default function DateRangeSelector(props) {
  const [dummyValue, setDummyValue] = React.useState(initDates);

  const {
    containerClassName,
    labelClassName,
    label,
    inputClassName,
    inputValue,
    onInputChange,
    inputDateOneProps,
    inputDateTwoProps,
    labelTo,
    label2ClassName,
    reduxIdentifier,
    showError,
    errorMessage,
    errorMessageClass,
  } = props;

  const updateInputField = React.useCallback(
    (event, key) => {
      const newValue = { ...(inputValue || dummyValue) };
      newValue[key] = new Date(event.target.value);
      if (onInputChange) onInputChange(newValue, reduxIdentifier);
      else setDummyValue(newValue);
    },
    [dummyValue, inputValue, onInputChange, reduxIdentifier]
  );

  const fromDate = formatDate(
    inputValue?.from ?? dummyValue.from,
    DateFormatTypes.YYYY_MM_DD
  );

  const toDate = formatDate(
    inputValue?.to ?? dummyValue.to,
    DateFormatTypes.YYYY_MM_DD
  );

  return (
    <div className={containerClassName}>
      <label className={labelClassName}>{label ?? 'Dates'}</label>
      <div className={classes.RowElements}>
        <input
          {...inputDateOneProps}
          type={'date'}
          className={inputClassName}
          value={fromDate}
          onChange={(event) => updateInputField(event, 'from')}
        />
        <label className={label2ClassName}>{labelTo ?? ' to '}</label>
        <input
          {...inputDateTwoProps}
          type={'date'}
          className={inputClassName}
          value={toDate}
          onChange={(event) => updateInputField(event, 'to')}
        />
        {showError && (
          <label className={errorMessageClass ?? classes.ErrorMessage}>
            {errorMessage ?? 'Invalid Date Selection'}
          </label>
        )}
      </div>
    </div>
  );
}
