// import { current } from '@reduxjs/toolkit';

export const validationTypes = {
  REGEX_VALIDATE: 'REGEX_VALIDATE',
  ARRAY_LENGTH: 'ARRAY_LENGTH',
  DATE_RANGE: 'DATE_RANGE',
  NUMBER_COMPARISIONS: 'NUMBER_COMPARISIONS',
  VALUE_ATTRIBUTE: 'VALUE_ATTRIBUTE',

  //specifics
  CAMPAIGN_SPECIFIC_USE_CASE: 'CAMPAIGN_SPECIFIC_USE_CASE',
  NESTED_PDC_DETAILS: 'NESTED_PDC_DETAILS',
};

export const validateForm = ({ values, fields, miscProps }) => {

  let customErrorMessage = false;
  if (miscProps) customErrorMessage = miscProps.customErrorMessage;

  let allGood = true;
  const errors = {};
  Object.keys(fields).forEach((element) => {
    const { validationType, validationProps, invalidMessage } = fields[element];
    let valid = true;
    
    switch (validationType) {
      case validationTypes.REGEX_VALIDATE: {
        const { regexString } = validationProps;
        const regex = new RegExp(regexString);
        valid = regex.test(values[element]);
        break;
      }

      case validationTypes.ARRAY_LENGTH: {
        const { lengthConstraint } = validationProps;
        if (lengthConstraint['GREATER_THAN'] !== undefined) {
          valid = valid && values[element].length > lengthConstraint['GREATER_THAN'];
        }
        break;
      }

      case validationTypes.NUMBER_COMPARISIONS: {
        const { lengthConstraint } = validationProps;
        if (lengthConstraint['GREATER_THAN'] !== undefined) {
          valid = valid && values[element] > lengthConstraint['GREATER_THAN'];
        }
        break;
      }

      case validationTypes.VALUE_ATTRIBUTE: {
        valid =
          valid &&
          (values[element]?.[0]?.value ?? values[element].value !== undefined);
        break;
      }

      case validationTypes.DATE_RANGE: {
        let { from, to, extremes } = values[element];
        from = new Date(from).getTime();
        if (extremes === undefined) {
          extremes = { ...miscProps.extremes };
        }
        let fromExtreme = new Date(extremes.from).getTime();
        to = new Date(to).getTime();
        let toExtreme = new Date(extremes.to).getTime();

        valid = valid && from >= fromExtreme && to <= toExtreme;

        break;
      }

      //specifics

      case validationTypes.CAMPAIGN_SPECIFIC_USE_CASE: {
        const { dependent } = validationProps;
        const campaign = values[dependent];
        if (campaign.length > 0 && campaign[0].value !== 0) {
          break;
        } else {
          valid =
            valid &&
            (values[element]?.[0]?.value ??
              values[element].value !== undefined);
        }
        break;
      }

      case validationTypes.NESTED_PDC_DETAILS: {
        const pdcData = miscProps.pdcData;
        const index = miscProps.index;
        if (pdcData === undefined) {
          break;
        }

        const issueDate = new Date(pdcData.issueDate);
        const expiryDate = new Date(pdcData.expiryDate);
        const amount = pdcData.amount;

        if (issueDate > expiryDate) {
          errors[element] = {
            error: true,
            message: "Issue Date can't be higher than Expiry Date.",
          };
          valid = false;
        }
        if (amount < 0) {
          if (errors[element] === undefined) {
            errors[element] = new Array(20);
          }
          errors[element][index] = {
            error: true,
            message: 'Amount must be greater than zero.',
          };
          valid = false;
        }
        break;
      }

      default: {
      }
    }
    allGood = allGood && valid; //campaign length
    /*
    allGood = true;
    valid = true;
    
    */
    if (!valid) {
      if (!customErrorMessage)
        errors[element] = { error: true, message: invalidMessage };
    }
  });
  return { errors, allGood };
};
