import React from 'react';
import NavBarButtons from '../nav-bar/verticalNavBar';
import { useSelector, useDispatch } from 'react-redux';

export default function NavBars(props) {
  const { activeViewTab, updateActiveViewSubTab, navBarButtons } = props;
  const viewActive = useSelector(activeViewTab);
  const dispatch = useDispatch();

  return (
    <>
      <NavBarButtons
        buttonsArray={navBarButtons}
        activeButton={viewActive}
        buttonClickHandler={(buttonKey) =>
          dispatch(updateActiveViewSubTab(buttonKey))
        }
      />
    </>
  );
}
