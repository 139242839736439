import React from 'react';
import 'react-slidedown/lib/slidedown.css';
import classes from '../InputBoxGroup.module.scss';
import { icons, IconGeneric } from '../../icons/Icons';

export default function InputBoxGroup(props) {
  const { iconName, iconProps, value, onChangeHandler } = props;

  const [selected, setSelected] = React.useState(false);

  const enable = value || selected;
  const changeHandler = () => {
    if (onChangeHandler) {
      return onChangeHandler(!enable);
    } else return setSelected(!enable);
  };
  return (
    <div>
      <IconGeneric
        iconName={iconName || icons.ATTACH_ICON}
        iconProps={{
          ...iconProps,
          className: enable
            ? classes.iconActiveClass
            : classes.iconPassiveClass,
          onClick: changeHandler,
        }}
      />
    </div>
  );
}
