import React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DateRangeSelector from '../../../../../../shared/ui-components/ComponentGenerator/components/DateRangeSelector/DateRangeSelectorBeta';
import classes from './SubRoRepresentation.module.scss';
import {
  CurrencyUnits,
  RevenueCombinations,
} from '../../../../../../shared/frontendConstants/constants';
import { subRoSlice } from '../../functionalUnit/slices/subROs';
import { roSliceData } from '../../functionalUnit/slices/ROS';
import { connectedAccount } from '../../../Accounts/functionalUnit/slices/ConnectedAccountSlice';
import { base_uri } from '../../../../../../../constants';

export default function SubRoRepresentation(props) {
  const {
    formSpecificEntries,
    subRoUpdateRequestHandler,
  } = props;
  const roSlice = useSelector(roSliceData);
  const subROGlobal = useSelector(subRoSlice);
  const chainState = useSelector(connectedAccount)
  const { ro_dates, ro_currency } = roSlice.roDetails;
  const { sub_ro_ids } = subROGlobal;
  const {
    sub_ro_campaigns_selected,
    sub_ro_ios_selected,
    sub_ro_pixels_selected,
    kpi_1_metric_selected,
    attribution_model_selected,
    kpi_window_selected,
    deepMetrics_selected,
    sub_ro_revenue_selected,
    sub_ro_revenue_model_selected,
    sub_ro_name,
    errors,
    sub_ro_dates,
    sub_ro_budget,
    sub_ro_revenue_price,
    sub_ro_kpi_goal,
    // valid,
    sub_ro_ids_selected,
    revenue_deepMetrics_selected,
  } = formSpecificEntries;

  const [ ioConfigSelected, setIoConfigSelected ] = React.useState(false)

  // Condition to show DeepM Metric
  const revenueSourceIsDV3MetricBased = (sub_ro_revenue_selected === 'DV3 Metric Based');
  const revenueSourceisClient = ( sub_ro_revenue_selected === 'Client' );
  const showRevenueDeepMMetric = revenueSourceIsDV3MetricBased || revenueSourceisClient

  // Condition to show pixel selection
  const revenueModelIsCPA = sub_ro_revenue_model_selected.toLowerCase().includes('cpa');
  const showPixelSelection = revenueSourceIsDV3MetricBased && revenueModelIsCPA

  const [ campaigns, setCampaigns ] = React.useState([]);
  const [ insertionOrders, setInsertionOrders ] = React.useState([]);
  const [ pixels, setPixels ] = React.useState([]);
  const [ kpiMetrics, setKpiMetrics ] = React.useState([]);
  const [ attributionModels, setAttributionModels ] = React.useState([]);
  const [ KPIWindow, setKPIWindow ] = React.useState([]);
  const [ deepMetric, setDeepMetric ] = React.useState([]);

  const fetchData = async () => {
    
    // When account detail is not fetched advertiserString will be undefined
    // handling for that case rather than solving the underlying problem
    const advertiserString = chainState.subAccountDetails.dv3_advertisers;
    if (advertiserString === undefined) return;
    if (advertiserString === null) return;
    const advertiserIds = advertiserString.split(",").map(advertiserId => +advertiserId.trim());

    // Get Session Token
    const session_token = localStorage.getItem('SESSION_TOKEN')
    
    // Make request to fetch Insertion Order Ids
    const io_request_configuration = {
      url       : `${base_uri}/list/insertionOrders`,
      method    : "POST",
      headers   : { "Authorization" : `Bearer ${session_token}` },
      data      : { advertiserFilter: advertiserIds || [] }   
    }
    const io_response = await axios(io_request_configuration)
    const ios = io_response.data;
    setInsertionOrders(ios);

    // Make request to fetch Campaign Ids
    const campaign_request_configuration = {
      url       : `${base_uri}/list/campaigns`,
      method    : "POST",
      headers   : { "Authorization" : `Bearer ${session_token}` },
      data      : { advertiserFilter: advertiserIds || [] }   
    }
    const campaign_response = await axios(campaign_request_configuration)
    const campaigns = campaign_response.data;
    setCampaigns(campaigns); 

    // Make request to fetch Pixels
    const pixel_request_configuration = {
      url       : `${base_uri}/list/pixels`,
      method    : "POST",
      headers   : { "Authorizations" : `Bearer ${session_token}` },
      data      : { advertiserFilter: advertiserIds || [] }
    }
    const pixel_response = await axios(pixel_request_configuration)
    const pixels = pixel_response.data;
    setPixels(pixels);
    
    // Make request to fetch kpi metrics
    const kpi_metrics_request_configuration = {
      url       : `${base_uri}/list/kpiMetrics`,
      method    : "GET",
      headers   : { "Authorizations" : `Bearer ${session_token}` },
    }
    const kpi_metrics_response = await axios(kpi_metrics_request_configuration)
    const kpi_metrics = kpi_metrics_response.data;
    setKpiMetrics(kpi_metrics);

    // Make request to fetch attribution models
    const attirbution_model_request_configuration = {
      url       : `${base_uri}/list/attributionModels`,
      method    : "GET",
      headers   : { "Authorization" : `Bearer ${session_token}` }
    }
    const attribution_model_response = await axios(attirbution_model_request_configuration)
    const attribution_models = attribution_model_response.data;
    setAttributionModels(attribution_models)

    // Make request to fetch KPI Window
    const kpi_window_request_configuration = {
      url       : `${base_uri}/list/kpiWindow`,
      method    : "GET",
      headers   : { "Authorization" : `Bearer ${session_token}` }
    }
    const kpi_window_response = await axios(kpi_window_request_configuration)
    const kpi_windows = kpi_window_response.data;
    setKPIWindow(kpi_windows)

    // Make request to fetch Deep Metric
    const deepmetric_request_configuration = {
      url       : `${base_uri}/list/deepMetric`,
      method    : "GET",
      headers   : { "Authorization" : `Bearer ${session_token}` }
    }
    const deepmetric_response = await axios(deepmetric_request_configuration)
    const deepmetrics = deepmetric_response.data;
    setDeepMetric(deepmetrics)

  }
  React.useEffect(() => { fetchData() }, [])

  // Handle React-Select MultiSelect for Campaign-Selection
  const handleCampaignMultiSelect = (value, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':     break;
      case 'clear':         value = []; break;
      default: break;
    }
    const newCampaigns = value.map(campaign => campaign.value)
    subRoUpdateRequestHandler({
      key: 'sub_ro_campaigns_selected',
      value: newCampaigns,
    });
  }

  // Handle React-Select MultiSelect for Insertion-Order-Selection
  const handleInsertionOrderMultiSelect = (value, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':     break;
      case 'clear':         value = []; break;
      default: break;
    }
    const newIos = value.map(io => io.value)
    subRoUpdateRequestHandler({
      key: 'sub_ro_ios_selected',
      value: newIos,
    })
  }

  // Handle React-Select MultiSelect for Pixel-Selection
  const handlePixelMultiSelect = (value, actionMeta) => {
    switch(actionMeta.action) {
      case 'remove-value':
      case 'pop-value':     break;
      case 'clear':         value = []; break;
      default: break;
    }
    const newPixels = value.map(pixel => pixel.value)
    subRoUpdateRequestHandler({
      key: 'sub_ro_pixels_selected',
      value: newPixels,
    });
  }

  // Handle React-Select  MultiSelsct for Client-Sub-RO-ID select
  const handleClientSubRoIdMultiSelect = (value, actionMeta) => {
    switch(actionMeta.action) {
      case 'remove-value':
      case 'pop-value':     break;
      case 'clear':         value = []; break;
      default: break;
    }
    const newClientSubRoIds = value.map(client_sub_ro_id => client_sub_ro_id.value)
    subRoUpdateRequestHandler({
      key: 'sub_ro_ids_selected',
      value: newClientSubRoIds
    })
  }

  // Handle React-Select Single-Select for KPI Metric
  const handleKPIMetricSelect = selectedOption => {
    const kpiMetric = selectedOption.value;
    subRoUpdateRequestHandler({
      key: 'kpi_1_metric_selected',
      value: kpiMetric,
    })
  } 

  // Handle React-Select Single-Select for Attribution Models
  const handleAttibutionModelSelect = selectedOption => {
    const attributionModel = selectedOption.value;
    subRoUpdateRequestHandler({
      key: 'attribution_model_selected',
      value: attributionModel,
    });
  }

  // Handle React-Select Single-Select for KPI Window
  const handleKPIWindowSelect = selectedOption => {
    const kpiWindow = selectedOption.value;
    subRoUpdateRequestHandler({
      key: 'kpi_window_selected',
      value: kpiWindow,
    })
  }

  // Handle React-Select Single-Select for DeepMetric
  const handleDeepMetricSelect = selectedOption => {
    const deepMetric = selectedOption.value
    subRoUpdateRequestHandler({
      key: 'deepMetrics_selected',
      value: deepMetric,
    })
  }

  // Handle React-Select Single-Select for Revenue Calculation
  const handleRevenueDeepMetricSelect = selectedOption => {
    const deepMetric = selectedOption.value
    subRoUpdateRequestHandler({
      key: 'revenue_deepMetrics_selected',
      value: deepMetric,
    })
  }

  return (
    <div className={classes.SubROForm}>
      
      {/* RO Name */}
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>Name:</label>
        <input
          className={[classes.FormComponent_LabelInput__InputTextField].join(' ')}
          value={sub_ro_name}
          onChange={(event) =>
            subRoUpdateRequestHandler({
              value: event.target.value,
              key: 'sub_ro_name',
            })
          }
        />
        {/* Error Statement in Name if any */}
        {errors['sub_ro_name']?.error && 
          <label className={classes.FormComponent_LabelInput__ErrorLabel}>
            {errors['sub_ro_name']?.message}
          </label>
        }
    </div>
    
    {/* Flight Dates */}
    <DateRangeSelector
      containerClassName={classes.FormComponent_LabelInput}
      labelClassName={classes.FormComponent_LabelInput__Label}
      label={'Flight Dates'}
      inputClassName={classes.FormComponent_LabelInput__InputTextField}
      labelTo={' To '}
      label2ClassName={classes.FormComponent_LabelInput__Label2}
      inputValue={sub_ro_dates}
      reduxIdentifier={'sub_ro_dates'}
      onInputChange={(value, changeKey) =>
        subRoUpdateRequestHandler({
          value: {
            ...value,
            extremes: ro_dates,
          },
          key: changeKey,
        })
      }
      showError={errors['sub_ro_dates']?.error}
      errorMessage={errors['sub_ro_dates']?.message}
      errorMessageClass={classes.FormComponent_LabelInput__ErrorLabel}
    />

    {/* Budget */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        Budget:
      </label>
      <div className={classes.FluidContainer_15rem}>
      {/* Budget Currency */}
        <div className={ classes.FormComponent_LabelInput__CurrencyRepresentation }>
          {CurrencyUnits[ro_currency.value] ?? '$'}
        </div>

        {/* Budget Value */}
        <input
          className={[
            classes.FormComponent_LabelInput__InputTextFieldUnsized,
            classes.FormComponent_LabelInput__InputTextFieldUnsized_autoFill,
          ].join(' ')}
          value={sub_ro_budget}
          onChange={(event) =>
            subRoUpdateRequestHandler({
              value: event.target.value,
              key: 'sub_ro_budget',
            })
          }
        />

        {/* Error Statement in budget if any */}
        {errors['sub_ro_budget']?.error && (
          <label className={classes.FormComponent_LabelInput__ErrorLabel}>
            {errors['sub_ro_budget']?.message}
          </label>
        )}
      </div>
    </div>

    {/* Revenue Source */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        Revenue Source:
      </label>
      <Select
        className={classes.FormComponent_LabelInput__ReactSelectLabel}
        options={[
          { label: 'DV3', value: 'DV3' },
          { label: 'DV3 Metric Based', value: 'DV3 Metric Based' },
          { label: 'CM', value: 'CM' },
          { label: 'Client', value: 'Client' },
        ]}
        value={{ label: sub_ro_revenue_selected, value: sub_ro_revenue_selected }}
        onChange={(selectedOption) =>
          subRoUpdateRequestHandler({
            value: selectedOption.value,
            key: 'sub_ro_revenue_selected',
          })
        }
      />
    </div>

    {/* Revenue Model */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        Revenue Model:
      </label>
      <Select
        className={classes.FormComponent_LabelInput__ReactSelectLabel}
        options={RevenueCombinations[sub_ro_revenue_selected]}
        value={{ label:sub_ro_revenue_model_selected, value: sub_ro_revenue_model_selected }}
        onChange={selectedOption =>
          subRoUpdateRequestHandler({
            value: selectedOption.value,
            key: 'sub_ro_revenue_model_selected',
          })
        }
      />
      {/* Errors in Revenue Model if any */}
      {errors['sub_ro_revenue_model_selected']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['sub_ro_revenue_model_selected']?.message}
        </label>
      )}
    </div>

    {/* Revenue DeepM Metric */}
    {showRevenueDeepMMetric && 
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        Revenue DeepM Metric:
      </label>
      <Select
        className={classes.FormComponent_LabelInput__ReactSelectLabel}
        options={deepMetric.map(deepMetric => ({ label: deepMetric.Entity_Name, value: deepMetric.Entity_Name }) )}
        value={{ label: revenue_deepMetrics_selected, value: revenue_deepMetrics_selected }}
        onChange={handleRevenueDeepMetricSelect}
      />

      {/* Errors in KPI 1 DeepMetric if any */}
      {errors['deepMetrics_selected']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['deepMetrics_selected']?.message}
        </label>
      )}
    </div>
    }

    {/* Pixel Selection */}
    {showPixelSelection && 
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          Pixel ID:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={pixels.map(pixel => ({ 
            label: `${pixel.Floodlight_Activity_Name} (${pixel.Floodlight_Activity_ID})`, 
            value: pixel.Floodlight_Activity_ID
          }))}
          value={sub_ro_pixels_selected.map(pixel => ({ label: pixel, value: pixel }))}
          isMulti
          onChange={handlePixelMultiSelect}
        />

        {/* Errors in Pixel Selection */}
        {errors['sub_ro_pixels_selected']?.error && (
          <label className={classes.FormComponent_LabelInput__ErrorLabel}>
            {errors['sub_ro_pixels_selected']?.message}
          </label>
        )}
      </div>
    }

    {/* Revenue Price */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        Revenue Price:
      </label>
      <div className={classes.FluidContainer_15rem}>
        <div className={ classes.FormComponent_LabelInput__CurrencyRepresentation }>
          {CurrencyUnits[ro_currency.value] ?? '$'}
        </div>
        <input
          className={[
            classes.FormComponent_LabelInput__InputTextFieldUnsized,
            classes.FormComponent_LabelInput__InputTextFieldUnsized_autoFill,
          ].join(' ')}
          value={sub_ro_revenue_price}
          onChange={(event) =>
            subRoUpdateRequestHandler({
              value: event.target.value,
              key: 'sub_ro_revenue_price',
            })
          }
        />
      </div>

      {/* Errors in Revenue Price if any */}
      {errors['sub_ro_revenue_price']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['sub_ro_revenue_price']?.message}
        </label>
      )}
    </div>

    {/* DV3 Entity Selector (Campaign/Insertion_Order) */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        DV3 Entity: 
      </label>
      <input
        type={'radio'}
        name={'dv3_entity'}
        value={false}
        onChange={event => setIoConfigSelected(false)}
        checked={!ioConfigSelected}
      />
      <label className={classes.RadioButtonLabel}>Campaign</label>
      <input
        type={'radio'}
        name={'dv3_entity'}
        value={true}
        onChange={event => setIoConfigSelected(true)}
        checked={ioConfigSelected}
      />
      <label className={classes.RadioButtonLabel}>Insertion Order</label>
    </div>

    {/* DV3 Entity: Campaign */}
    {!ioConfigSelected &&
      <div className={classes.FormComponent_LabelInput}>
        <label className={classes.FormComponent_LabelInput__Label}>
          DV3 Campaign:
        </label>
        <Select
          className={classes.FormComponent_LabelInput__ReactSelectLabel}
          options={campaigns.map(campaign => ({
             label: `${campaign.Campaign} (${campaign.Campaign_ID})`, 
             value: campaign.Campaign_ID, 
          }))}
          onChange={handleCampaignMultiSelect}
          value={sub_ro_campaigns_selected.map(campaign => ({ label: campaign, value: campaign }))}
          isMulti
          isClearable={false}
        />
        
        {/* Errors in DV3 Entity: Campaign if any */}
        {errors['sub_ro_campaigns_selected']?.error && (
          <label className={classes.FormComponent_LabelInput__ErrorLabel}>
            {errors['sub_ro_campaigns_selected']?.message}
          </label>
        )}
      </div>
    }

    {/* DV3 Entity: Insertion Order */}
    {ioConfigSelected && 
      <React.Fragment>
        <label>
          Please take approvals before to go ahead with this option, if you
          configure this one, associated person would receive the notification
        </label>

        <div className={classes.FormComponent_LabelInput}>
          <label className={classes.FormComponent_LabelInput__Label}>
            DV3 IOs:
          </label>
          <Select
            className={classes.FormComponent_LabelInput__ReactSelectLabel}
            options={insertionOrders.map(insertion_order => ({
              label: `${insertion_order.Insertion_Order} (${insertion_order.Insertion_Order_ID})`,
              value: insertion_order.Insertion_Order_ID
            }))}
            onChange={handleInsertionOrderMultiSelect}
            value={sub_ro_ios_selected.map(io => ({ label: io, value: io }))}
            isMulti
          />

          {/* Errors in DV3 Entity: Insertion Order  */}
          {errors['sub_ro_ios_selected']?.error && (
            <label className={classes.FormComponent_LabelInput__ErrorLabel}>
              {errors['sub_ro_ios_selected']?.message}
            </label>
          )}
        </div>
      </React.Fragment>
    }

    {/* Client Sub-RO-ID */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        Client Sub RO ID:
      </label>
      <Select
        className={classes.FormComponent_LabelInput__ReactSelectLabel}
        options={sub_ro_ids.map(sub_ro_id => ({ label: sub_ro_id, value: sub_ro_id }))}
        isMulti
        value={sub_ro_ids_selected}
        onChange={handleClientSubRoIdMultiSelect}
      />

      {/* Errors in Client Sub-RO-ID if any */}
      {errors['sub_ro_ids_selected']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['sub_ro_ids_selected']?.message}
        </label>
      )}
    </div>

    {/* KPI 1 Metrics  */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        KPI 1 Metric:
      </label>
      <Select
        className={classes.FormComponent_LabelInput__ReactSelectLabel}
        options={kpiMetrics.map(metric => ({
          label: metric,
          value: metric,
        }))}
        value={{ label: kpi_1_metric_selected, value: kpi_1_metric_selected }}
        onChange={handleKPIMetricSelect}
      />

      {/* Errors in KPI 1 Metric if any */}
      {errors['kpi_1_metric_selected']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['kpi_1_metric_selected']?.message}
        </label>
      )}
    </div>

    {/* KPI 1 DeepMetric */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        KPI 1 Deep Metric:
      </label>
      <Select
        className={classes.FormComponent_LabelInput__ReactSelectLabel}
        options={deepMetric.map(deepMetric => ({ label: deepMetric.Entity_Name, value: deepMetric.Entity_Name }) )}
        value={{ label: deepMetrics_selected, value: deepMetrics_selected }}
        onChange={handleDeepMetricSelect}
      />

      {/* Errors in KPI 1 DeepMetric if any */}
      {errors['deepMetrics_selected']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['deepMetrics_selected']?.message}
        </label>
      )}
    </div>

    {/* KPI Goal */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        KPI Goal:
      </label>
      <input
        className={[classes.FormComponent_LabelInput__InputTextField].join(' ')}
        value={sub_ro_kpi_goal}
        onChange={(event) =>
          subRoUpdateRequestHandler({
            value: event.target.value,
            key: 'sub_ro_kpi_goal',
          })
        }
      />
      {/* Errors in KPI Goal if any */}
      {errors['sub_ro_kpi_goal']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['sub_ro_kpi_goal']?.message}
        </label>
      )}
    </div>

    {/* Attribution Model */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        Atribution Model (Client):
      </label>
      <Select
        className={classes.FormComponent_LabelInput__ReactSelectLabel}
        options={attributionModels.map(model => ({ label: model, value: model }) )}
        value={{ label: attribution_model_selected, value: attribution_model_selected} }
        onChange={handleAttibutionModelSelect}
      />

      {/* Errors in Attribution Model if any */}
      {errors['attribution_model_selected']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['attribution_model_selected']?.message}
        </label>
      )}
    </div>

    {/* KPI Window */}
    <div className={classes.FormComponent_LabelInput}>
      <label className={classes.FormComponent_LabelInput__Label}>
        KPI Window:
      </label>
      <Select
        className={classes.FormComponent_LabelInput__ReactSelectLabel}
        options={KPIWindow.map(window => ({ label: window, value: window }) )}
        value={{ label: kpi_window_selected, value: kpi_window_selected}}
        onChange={handleKPIWindowSelect}
      />

      {/* Errors in KPI Window if any */}
      {errors['kpi_window_selected']?.error && (
        <label className={classes.FormComponent_LabelInput__ErrorLabel}>
          {errors['kpi_window_selected']?.message}
        </label>
      )}
    </div>

  </div>
  
  );
}
