import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  activeIndex,
  isBusinessIOClicked,
  businessIOData,
  updateActiveBusinessIOIndex,
} from '../../../../slices/activeAdvertiserSlice';
import { activeViewMap } from '../../constants/keyDisplayMap';

export default function BusinessIOView({ classes }) {
  const dispatch = useDispatch();
  const businessIOs = useSelector(businessIOData);
  const activeBIOid = useSelector(activeIndex);
  const isClicked = useSelector(isBusinessIOClicked);
  return (
    <div>
      <h2 className={classes.Heading}>Currently Active Business IOs</h2>
      <div className={classes.ActiveBusinessIOList}>
        {businessIOs.map(
          (element) =>
            element.status === 'Active' && (
              <div key={'active_bios_' + element.id}>
                <p
                  onClick={() => {
                    dispatch(updateActiveBusinessIOIndex(element.id));
                  }}
                  className={[classes.clickButton, classes.BusinessIOName].join(
                    ' '
                  )}>
                  {element.business_io}
                </p>
                {isClicked && activeBIOid === element.id && (
                  <div className={classes.BusinessIODetails}>
                    {Object.keys(element).map((ele) => {
                      return (
                        activeViewMap[ele] && (
                          <p
                            className={classes.BIODisplayPointers}
                            key={'active_bios_' + element.id + ele}>
                            {activeViewMap[ele]} : {element[ele]}
                          </p>
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
}
