import React from 'react';
// import classes from './ComponentGenerator.module.scss';
import { InputTextField, DateRangeSelector, DateSelector } from './components';

export const componentTypes = {
  INPUT_TEXTFIELD: 'INPUT_TEXTFIELD',
  INPUT_DATERANGE: 'INPUT_DATERANGE',
  INPUT_DATE: 'INPUT_DATE',
};

export default function ComponentGenerator(props) {
  const { onInputChange, inputValue } = props;
  const passProps = {
    ...props.props,
    onInputChange: onInputChange,
    inputValue: inputValue,
  };
  switch (props.elementType) {
    case componentTypes.INPUT_TEXTFIELD:
      return <InputTextField {...passProps} />;
    case componentTypes.INPUT_DATERANGE:
      return <DateRangeSelector {...passProps} />;
    case componentTypes.INPUT_DATE:
      return <DateSelector {...passProps} />;
    default:
      return <p>Unconfigured component</p>;
  }
}
